import {
  SaveOutlined, HolderOutlined, DeleteOutlined, PlusOutlined
} from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button, Card, Col, message, Row, Spin, Switch, Typography
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {
  Controller, FormProvider, useFieldArray, useForm
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import Input from 'common/components/Input';
import ManagementInfo from 'common/components/ManagementInfo';
import SelectFile from 'common/components/SelectFile';
import { createOnboardingService, getOnboardingByIdService, updateOnboardingService } from 'common/services/extends/onboarding';
import { CreateOnboardingParams, UpdateOnboardingParams } from 'common/services/extends/onboarding/types';
import { ROUTE_PATHS } from 'common/utils/constant';
import { formatDateTime } from 'common/utils/functions';
import { onboardingFormSchema } from 'common/utils/schemas';

type OnboardingFormData = {
  active: boolean;
  displayOrder: number;
  translations: {
    [locale: string]: {
      functionalData: {
        title: string;
        thumbnail: string;
        description: {
          value: string
        }[];
      }
    };
  };
};

const OnboardingDetail: React.FC<ActiveRoles> = ({
  roleIndex,
  roleCreate,
  roleUpdate
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const { languageOptions, defaultWebsiteLanguage } = useAppSelector((state) => state.system);
  const idParam = Number(searchParams.get('id'));
  const localeParam = searchParams.get('locale') || defaultWebsiteLanguage || '';
  const [currentLang, setCurrentLang] = useState<string>(localeParam);

  const DEFAULT_VALUS = {
    active: true,
    displayOrder: 0,
    translations: {
      [currentLang]: {
        functionalData: {
          title: '',
          description: [{ value: '' }],
          thumbnail: '',
        },
      },
    },
  };

  const method = useForm<OnboardingFormData>({
    defaultValues: DEFAULT_VALUS,
    resolver: yupResolver(onboardingFormSchema),
  });

  const {
    fields, append, move, remove
  } = useFieldArray({
    control: method.control,
    name: `translations.${currentLang}.functionalData.description`,
  });

  const queryOnBoardingDataById = ['getOnBoardingDataById', idParam];

  const { data: onboardingData, isLoading: onboardingDataLoading } = useQuery(
    queryOnBoardingDataById,
    () => getOnboardingByIdService(idParam),
    {
      enabled: roleIndex && !!idParam
    }
  );

  const { mutate: createMutate, isLoading: createLoading } = useMutation(
    ['createOnboardingMutate'],
    async (params: CreateOnboardingParams) => createOnboardingService(params),
    {
      onSuccess: () => {
        navigate(ROUTE_PATHS.ON_BOARDING_MANAGEMENT);
      },
      onError: (errors: ErrorResponse[]) => {
        if (errors.length > 0) {
          errors.forEach((error: ErrorResponse) => {
            method.setError(error.field as keyof OnboardingFormData, { message: error.message || '' });
          });
        } else {
          message.error(t('message.createError'));
        }
      }
    }
  );

  const { mutate: updateMutate, isLoading: updateLoading } = useMutation(
    ['updateOnboardingMutate'],
    async (params: UpdateOnboardingParams) => updateOnboardingService(params),
    {
      onSuccess: () => {
        message.success(t('message.updateSuccess'));
        queryClient.invalidateQueries(queryOnBoardingDataById);
      },
      onError: (errors: ErrorResponse[]) => {
        if (errors.length > 0) {
          if (errors[0].code === 'active') {
            message.error(errors[0].message);
          } else {
            errors.forEach((error: ErrorResponse) => {
              method.setError(error.field as keyof OnboardingFormData, { message: error.message || '' });
            });
          }
        } else {
          message.error(t('message.updateError'));
        }
      },
    }
  );

  const onSubmit = async () => {
    const isValid = await method.trigger();
    if (!isValid) {
      return;
    }

    const formData = method.getValues();

    const { translations } = formData;
    const formattedTranslations = Object.entries(translations).reduce(
      (acc, [lang, data]) => ({
        ...acc,
        [lang]: {
          functionalData: {
            title: data.functionalData.title,
            thumbnail: data.functionalData.thumbnail,
            description: data.functionalData.description?.map((desc) => desc.value),
          }
        },
      }),
      {}
    );

    if (idParam) {
      updateMutate({ id: idParam, ...formData, translations: formattedTranslations });
    } else {
      createMutate({ ...formData, translations: formattedTranslations });
    }
  };

  const handleChangeLang = (lang: LanguageCodeTypes) => {
    setCurrentLang(lang);

    if (idParam) {
      setSearchParams(
        {
          id: String(idParam),
          locale: lang,
        },
        { replace: true }
      );
    } else {
      setSearchParams(
        {
          locale: lang,
        },
        { replace: true }
      );
    }
  };

  useEffect(() => {
    if (onboardingData) {
      const transformedTranslations = Object.entries(onboardingData.translations).reduce(
        (acc, [lang, data]) => ({
          ...acc,
          [lang]: {
            functionalData: {
              title: data.title,
              thumbnail: data.thumbnail,
              description: data.description?.map((desc) => ({
                value: desc
              })) || [{ value: '' }]
            }
          },
        }),
        {}
      );

      method.reset({
        active: onboardingData.onBoardingData.active,
        displayOrder: onboardingData.onBoardingData.displayOrder,
        translations: transformedTranslations,
      });
    } else {
      method.reset(DEFAULT_VALUS);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLang, method, onboardingData]);

  return (
    <FormProvider<OnboardingFormData> {...method}>
      <HeaderPage
        fixed
        title={idParam ? t('onboarding.edit') : t('system.create')}
        rightHeader={(
          <Button
            type="primary"
            loading={onboardingDataLoading || createLoading || updateLoading}
            onClick={method.handleSubmit(onSubmit)}
            disabled={(idParam && !roleUpdate) || (!idParam && !roleCreate)}
          >
            <SaveOutlined />
            {t('system.save')}
          </Button>
        )}
      />
      <Spin spinning={onboardingDataLoading}>
        <div className="t-mainlayout_wrapper">
          <Row gutter={16}>
            <Col xxl={18} xl={16} lg={16}>
              <Card>
                <div>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Typography.Text strong>
                        {t('system.title')}
                        {' '}
                      </Typography.Text>
                      <Typography.Text strong type="danger">
                        *
                      </Typography.Text>
                      <Controller
                        name={`translations.${currentLang}.functionalData.title`}
                        defaultValue=""
                        render={({ field: { value, onChange }, fieldState: { error } }) => (
                          <Input
                            className="u-mt-8"
                            name={`translations.${currentLang}.functionalData.title`}
                            placeholder={`${t('system.input')} ${t('system.title')}`}
                            value={value}
                            onChange={onChange}
                            error={error?.message}
                            size="large"
                          />
                        )}
                      />
                    </Col>
                    <Col span={12}>
                      <Typography.Text strong>
                        {t('newsDetail.displayOrder')}
                        {' '}
                      </Typography.Text>
                      <Typography.Text strong type="danger">
                        *
                      </Typography.Text>
                      <Controller
                        name="displayOrder"
                        defaultValue={0}
                        render={({ field: { value, onChange }, fieldState: { error } }) => (
                          <Input
                            type="number"
                            className="u-mt-8"
                            name="displayOrder"
                            placeholder={`${t('system.input')} ${t('newsDetail.displayOrder')}`}
                            value={value}
                            onChange={onChange}
                            error={error?.message}
                            size="large"
                          />
                        )}
                      />
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12} className="u-mt-8">
                      <Typography.Text strong>
                        {t('onboarding.active')}
                        {' '}
                      </Typography.Text>
                      <Controller
                        name="active"
                        defaultValue={false}
                        render={({ field: { value, onChange }, fieldState: { error } }) => (
                          <div className="u-mt-8">
                            <Switch checked={value} onChange={onChange} />
                            {error && <span className="a-input_errorMessage">{error.message}</span>}
                          </div>
                        )}
                      />
                    </Col>
                  </Row>
                  <Row className="u-mt-8">
                    <Col span={24} className="u-mb-12">
                      <Controller
                        name={`translations.${currentLang}.functionalData.thumbnail`}
                        render={({
                        field: { value, onChange },
                        fieldState
                      }) => (
                        <>
                          <SelectFile
                            value={value}
                            name={`translations.${currentLang}.functionalData.thumbnail`}
                            handleSelect={onChange}
                            handleDelete={() => onChange(undefined)}
                            title={(
                              <Typography.Text strong className="u-mb-8">
                                {t('newsDetail.thumbnail')}
                                {' '}
                              </Typography.Text>
                            )}
                          />
                          {fieldState.error && (
                            <span
                              className="a-input_errorMessage"
                            >
                              {fieldState.error.message}
                            </span>
                          )}
                        </>
                      )}
                      />
                    </Col>
                  </Row>
                  <Row className="u-mt-8">
                    <Col span={24}>
                      <Typography.Text strong>
                        {t('product.description')}
                        {' '}
                      </Typography.Text>
                      <Typography.Text strong type="danger">
                        *
                      </Typography.Text>
                      <DragDropContext
                        onDragEnd={(result) => {
                          if (!result.destination) return;
                          const destIdx = result.destination.index;
                          move(result.source.index, destIdx);
                        }}
                      >
                        <Droppable droppableId="banner">
                          {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                              {fields.map((ele, index) => (
                                <Draggable key={ele.id} draggableId={ele.id} index={index}>
                                  {(providedDrag) => (
                                    <div
                                      ref={providedDrag.innerRef}
                                      {...providedDrag.draggableProps}
                                      {...providedDrag.dragHandleProps}
                                      style={{
                                        ...providedDrag.draggableProps.style,
                                        paddingTop: 8,
                                        paddingBottom: 8,
                                      }}
                                    >
                                      <div style={{ display: 'flex', width: '100%' }}>
                                        <div className="p-banner_dragHeader_holder">
                                          <HolderOutlined />
                                        </div>
                                        <div style={{ flex: 1 }}>
                                          <Controller
                                            name={`translations.${currentLang}.functionalData.description[${index}].value`}
                                            render={
                                              ({ field: { value, onChange }, fieldState }) => (
                                                <div className="u-mt-8" style={{ flex: 1 }}>
                                                  <TextArea value={value || ''} onChange={onChange} />
                                                  {fieldState.error && (
                                                    <span className="a-input_errorMessage">
                                                      {fieldState.error.message}
                                                    </span>
                                                  )}
                                                </div>
                                              )
                                            }
                                          />
                                        </div>
                                        <Button
                                          type="text"
                                          onClick={() => {
                                            remove(index);
                                          }}
                                          icon={<DeleteOutlined />}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                      <Button
                        type="primary"
                        className="btn-center u-mt-24"
                        onClick={() => append({
                          value: '',
                        })}
                      >
                        <PlusOutlined />
                        {t('system.addNew')}
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
            <Col xxl={6} xl={8} lg={8}>
              <ManagementInfo
                createdDate={formatDateTime(onboardingData?.onBoardingData.createdAt) || ''}
                createdBy={onboardingData?.creator?.name || ''}
                lastUpdated={formatDateTime(onboardingData?.onBoardingData.updatedAt) || ''}
                lastUpdatedBy={onboardingData?.updater?.name || ''}
                languageList={languageOptions}
                currentLang={currentLang}
                handleChangeLang={(value) => value && handleChangeLang(value as LanguageCodeTypes)}
              />
            </Col>
          </Row>
        </div>
      </Spin>
    </FormProvider>
  );
};

export default OnboardingDetail;
