import {
  CreatePromotionParams,
  PromotionDataTypes,
  RevokePromotionParam,
  UpdatePromotionParams
} from './types';

import axiosInstance from 'common/services/common/instance';

export const getAllPromotionService = async (params?: BaseFilterParams)
: Promise<APIPaginationResponse<PromotionDataTypes[]>> => {
  const res = await axiosInstance.get('promotions', { params });
  return res.data;
};

export const getPromotionByIdService = async (
  id: string,
): Promise<PromotionDataTypes> => {
  const res = await axiosInstance.get(`promotions/${id}`);
  return res.data.data;
};

export const createPromotionService = async (
  params: CreatePromotionParams,
): Promise<void> => {
  await axiosInstance.post('promotions', params);
};

export const updatePromotionService = async (
  params: UpdatePromotionParams,
): Promise<void> => {
  await axiosInstance.put(`promotions/${params.id}`, params);
};

export const revokePromotionCodeService = async (params: RevokePromotionParam): Promise<void> => {
  const { id, ...rest } = params;
  await axiosInstance.post(`promotions/${id}/revoke-code`, rest);
};

export const deletePromotionService = async (ids: number[]): Promise<void> => {
  await axiosInstance.delete('promotions', { data: { ids } });
};
export const changePromotionStatusService = async (id: number, status: number) : Promise<void> => {
  await axiosInstance.put(`promotions/${id}/status`, { status });
};
