import { EyeOutlined } from '@ant-design/icons';
import {
  Button,
  Select,
  Space,
  Typography
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import DetailModal, { DetailModalProps } from './DetailModal';

import { useAppSelector } from 'app/store';
import { actionTypeDummy, modelTypeDummy } from 'common/assets/dummyData/system';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import {
  FilterDataProps, FilterValueProps, mappingFilterFields,
  mappingFilterToQuery, mappingParamsFilter, mappingQueryParamsFilter
} from 'common/components/PageTable/filter';
import useDidMount from 'common/hooks/useDidMount';
import { getAllFeatureHistoriesService, getSelectedUsersService } from 'common/services/extends/featureHistories';
import { formatDateTime } from 'common/utils/functions';

export interface FeatureHistoriesData {
  id: number,
  model: string,
  modelDataName: string,
  modelDataLevel: number,
  modelDataRequiredLogin: boolean,
  userIdName: string,
  userIdEmail: string,
  modelId: number,
  action: string,
  createdAt: string,
}

const FeatureHistoriesManagement: React.FC<ActiveRoles> = () => {
  /* Hooks */
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => {
    const paramsObj = { ...Object.fromEntries(searchParams.entries()) };
    if (searchParams.has('page')) delete paramsObj.page;
    return paramsObj;
  }, [searchParams]);

  const pageParam = searchParams.get('page');

  /* Selectors */
  const { system: { defaultPageSize, advancedFilter } } = useAppSelector((state) => state);

  /* State */
  const [currentPage, setCurrentPage] = useState(Number(pageParam));
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [keyword, setKeyword] = useState('');
  const [selectedFilterList, setSelectedFilterList] = useState<
    FilterValueProps[]>(mappingQueryParamsFilter(params));
  const [selectedAction, setSelectedAction] = useState<string | undefined>(undefined);
  const [selectedModel, setSelectedModel] = useState<string | undefined>(undefined);
  const [selectedUser, setSelectedUser] = useState<string | undefined>(undefined);

  const [detailModal, setDetailModal] = useState<{
    isOpen: boolean;
    detailData: DetailModalProps;
  }>({
    isOpen: false,
    detailData: {
      id: -1,
      modelId: -1,
      model: '',
      action: '',
      modelDataLevel: -1,
      modelDataRequiredLogin: false,
      modelDataName: '',
      userIdName: '',
      userIdEmail: '',
      createdAt: '',
    }
  });

  /* Variables */
  const returnParamsFilter = useMemo(
    () => mappingParamsFilter(selectedFilterList),
    [selectedFilterList]
  );

  const filterFields: FilterDataProps[] = useMemo(
    () => mappingFilterFields('featureHistory', advancedFilter),
    [advancedFilter]
  );

  /* Queries */
  const { data: featureHistoriesData, isLoading } = useQuery(
    ['getAllFeatureHistoriesService', keyword, currentPage, currentView, selectedFilterList, selectedAction, selectedModel, selectedUser],
    () => getAllFeatureHistoriesService({
      keyword,
      page: currentPage,
      limit: currentView,
      action: selectedAction,
      model: selectedModel,
      userId: selectedUser,
      ...returnParamsFilter,
    }),
    {
      enabled: !!currentPage,
    }
  );

  const { data: selectedUsersData, isLoading: loadingUser } = useQuery(
    ['getSelectedUsersService'],
    () => getSelectedUsersService(),
    {
      enabled: !!currentPage,
    }
  );

  const tableData: FeatureHistoriesData[] = useMemo(() => (
    featureHistoriesData?.data.map((val) => ({
      id: val?.featureHistoryData?.id,
      model: val?.featureHistoryData?.model || '',
      modelDataName: val?.featureHistoryData?.modelData?.name || '',
      modelDataLevel: val?.featureHistoryData?.modelData?.level,
      modelDataRequiredLogin: val?.featureHistoryData?.modelData?.requiredLogin,
      userIdName: val?.featureHistoryData?.userId?.name || '',
      userIdEmail: val?.featureHistoryData?.userId?.email || '',
      action: val?.featureHistoryData?.action || '',
      modelId: val?.featureHistoryData?.modelId,
      createdAt: val?.featureHistoryData?.createdAt || '',
    })) || []), [featureHistoriesData]);

  const selectedUserFomartted: OptionType[] = useMemo(() => (
    selectedUsersData?.data.map((val) => ({
      label: val?.userData?.name || '',
      value: val?.userData?.id,
    })) || []), [selectedUsersData?.data]);

  const columns: ColumnsType<FeatureHistoriesData> = [
    {
      title: 'ID',
      key: 'id',
      width: 55,
      align: 'center',
      fixed: 'left',
      render: (_name: string, data: FeatureHistoriesData) => (
        <Typography.Text>
          {data.id || ''}
        </Typography.Text>
      ),
    },
    {
      title: t('featureHistories.model'),
      key: 'model',
      render: (_name: string, data: FeatureHistoriesData) => (
        <Typography.Text>
          {data.model || ''}
        </Typography.Text>
      ),
    },
    {
      title: t('featureHistories.modelDataName'),
      key: 'modelDataName',
      render: (_name: string, data: FeatureHistoriesData) => (
        <Typography.Text>
          {data.modelDataName || ''}
        </Typography.Text>
      ),
    },
    {
      title: t('featureHistories.userIdName'),
      key: 'userIdName',
      render: (_name: string, data: FeatureHistoriesData) => (
        <Typography.Text>
          {data.userIdName || ''}
        </Typography.Text>
      ),
    },
    {
      title: t('featureHistories.action'),
      key: 'action',
      render: (_name: string, data: FeatureHistoriesData) => (
        <Typography.Text>
          {data.action || ''}
        </Typography.Text>
      ),
    },
    {
      title: t('system.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_name: string, data: FeatureHistoriesData) => (
        <Typography.Text>
          {formatDateTime(data.createdAt || '')}
        </Typography.Text>
      ),
    },
    {
      title: t('system.action'),
      key: 'actions',
      width: 100,
      align: 'center',
      render: (_name: string, _data: FeatureHistoriesData) => (
        <Space>
          <Button
            icon={<EyeOutlined />}
            onClick={() => {
              setDetailModal({
                isOpen: true,
                detailData: { ..._data }
              });
            }}
          />
        </Space>
      ),
    },
  ];

  // Change page view
  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ page: page.toString() }, { replace: true });
  };

  // Change page size
  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  const handleDeleteFilter = (key: string, index?: number) => {
    const tempList = selectedFilterList.slice();
    setSelectedFilterList(tempList.filter((item) => !(item.key === key && item.index === index)));
  };

  // Add filter
  const handleFilter = (data: FilterValueProps) => {
    const typeFilter = String(data.filter).split('.')[1];
    if ((typeFilter === 'isNull' || typeFilter === 'isNotNull') && selectedFilterList.find((item) => item.key === data.key)) {
      return;
    }
    const counter = selectedFilterList.filter(
      (item) => item.field === data.field && item.filter === data.filter
    ).length;
    setSelectedFilterList([...selectedFilterList, { ...data, index: counter }]);
  };

  /* Effects */
  useEffect(() => {
    setCurrentPage(pageParam ? Number(pageParam) : 1);
    setSearchParams({
      ...mappingFilterToQuery(selectedFilterList),
      page: pageParam || '1'
    }, { replace: true });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword, setSearchParams, selectedFilterList]);

  useDidMount(() => {
    if (pageParam) return setCurrentPage(Number(pageParam));
    return setCurrentPage(Number(pageParam) || 1);
  });

  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.featureHistories')}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          isLoading={isLoading || loadingUser}
          handleSearch={setKeyword}
          tableProps={{
            initShowColumns: ['id', 'model', 'modelDataName', 'userIdName', 'action', 'createdAt', 'actions'],
            columns,
            pageData: tableData || [],
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: featureHistoriesData?.meta.total ?? 1,
            noBaseCol: true,
            noDeleteLanguage: true,
            filterFields,
          }}
          filtersDataTable={{
            handleFilter,
            selectedFilterList,
            handleDeleteFilter
          }}
          leftCustomForm={(
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Select
                style={{ width: '100%' }}
                placeholder="Lọc theo action type"
                allowClear
                value={selectedAction}
                onChange={(val) => setSelectedAction(val)}
              >
                {
                  actionTypeDummy.map((item, index) => (
                    <Select.Option value={item.value} key={`option-${index.toString()}`}>
                      {t(item.label)}
                    </Select.Option>
                  ))
                }
              </Select>
              <div style={{ marginLeft: '8px' }}>
                <Select
                  style={{ width: '100%' }}
                  placeholder="Lọc theo model"
                  allowClear
                  value={selectedModel}
                  onChange={(val) => setSelectedModel(val)}
                >
                  {
                    modelTypeDummy.map((item, index) => (
                      <Select.Option value={item.value} key={`option-${index.toString()}`}>
                        {t(item.label)}
                      </Select.Option>
                    ))
                  }
                </Select>
              </div>
              <div style={{ marginLeft: '8px' }}>
                <Select
                  style={{ width: '100%' }}
                  placeholder="Lọc theo user"
                  allowClear
                  value={selectedUser}
                  onChange={(val) => setSelectedUser(val)}
                >
                  {
                    selectedUserFomartted.map((item, index) => (
                      <Select.Option value={item.value} key={`option-${index.toString()}`}>
                        {t(item.label)}
                      </Select.Option>
                    ))
                  }
                </Select>
              </div>
            </div>
          )}
        />
      </div>
      <DetailModal
        isOpen={detailModal.isOpen}
        detailData={detailModal.detailData}
        handleClose={() => {
          setDetailModal({
            isOpen: false,
            detailData: {
              id: -1,
              modelId: -1,
              model: '',
              action: '',
              modelDataLevel: -1,
              modelDataRequiredLogin: false,
              modelDataName: '',
              userIdName: '',
              userIdEmail: '',
              createdAt: '',
            }
          });
        }}
      />
    </>
  );
};

export default FeatureHistoriesManagement;
