import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined
} from '@ant-design/icons';
import {
  Button, message, Modal, Space, Switch, Tooltip, Typography
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import {
 FilterValueProps, mappingFilterToQuery, mappingParamsFilter, mappingQueryParamsFilter
} from 'common/components/PageTable/filter';
import useDidMount from 'common/hooks/useDidMount';
import useNavigateParams from 'common/hooks/useNavigateParams';
import {
  changePromotionStatusService,
  deletePromotionService,
  getAllPromotionService
} from 'common/services/extends/promotion';
import { ROUTE_PATHS } from 'common/utils/constant';
import { formatDateTime } from 'common/utils/functions';

export interface PromotionColumnData {
  id: number,
  campaign: string,
  status: number,
  userType: number,
  productType: number,
  codeType: number,
  quantity: number,
  startDate: string,
  endDate: string,
  createdAt: string,
  updatedAt: string,
}

const PromotionManagement: React.FC<ActiveRoles> = ({
  roleCreate, roleUpdate, roleDelete
}) => {
  /* Hook */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navigateParams = useNavigateParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const params = useMemo(() => {
    const paramsObj = { ...Object.fromEntries(searchParams.entries()) };
    if (searchParams.has('page')) delete paramsObj.page;
    return paramsObj;
  }, [searchParams]);

  const pageParam = searchParams.get('page');

  /* Selectors */
  const { defaultPageSize } = useAppSelector((state) => state.system);

  /* States */
  const [currentPage, setCurrentPage] = useState(Number(pageParam));
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [keyword, setKeyword] = useState('');
  const [selectedFilterList, setSelectedFilterList] = useState<
    FilterValueProps[]>(mappingQueryParamsFilter(params));

  const queryKey = ['promotionManagement-list', currentPage, keyword, currentView];

  const returnParamsFilter = useMemo(
    () => mappingParamsFilter(selectedFilterList),
    [selectedFilterList]
  );

  /* React-query */
  const {
    isLoading: listLoading,
    data: listData,
  } = useQuery(
    queryKey,
    () => getAllPromotionService({
      page: currentPage,
      keyword,
      limit: currentView,
      ...returnParamsFilter
    }),
    {
      enabled: !!currentPage,
    }
  );

  const { mutate: deleteMutate, isLoading: deleteLoading } = useMutation(
    ['deletePromotionMutate'],
    async (ids: number[]) => deletePromotionService(ids),
    {
      onSuccess: () => {
        message.success(t('message.deleteSuccess'));
        queryClient.invalidateQueries(queryKey);
      },
      onError: (errors: ErrorResponse[]) => {
        if (errors.length > 0) {
          errors.forEach((error: ErrorResponse) => {
            message.error(error?.message || '');
          });
        } else {
          message.error(t('message.deleteError'));
        }
      },
    }
  );

  const { mutate: changeStatusPromotionMutate, isLoading: changeStatusLoading } = useMutation(
    ['changeStatusPromotionMutate'],
    async (data:
      {
        id: number,
        status: number
      }) => changePromotionStatusService(data.id, data.status),
    {
      onSuccess: () => {
        message.success(t('message.updateSuccess'));
        queryClient.invalidateQueries(queryKey);
      },
      onError: (errors: ErrorResponse[]) => {
        if (errors.length > 0) {
          errors.forEach((error: ErrorResponse) => {
            message.error(error?.message || '');
          });
        } else {
          message.error(t('message.updateError'));
        }
      }
    }
  );

  /* Functions */
  const handleSearch = (val: string) => {
    setKeyword(val);
  };

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ page: page.toString() }, { replace: true });
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  const handleDeleteFilter = (key: string, index?: number) => {
    const tempList = selectedFilterList.slice();
    setSelectedFilterList(tempList.filter((item) => !(item.key === key && item.index === index)));
  };

  const handleFilter = (data: FilterValueProps) => {
    const typeFilter = String(data.filter).split('.')[1];
    if ((typeFilter === 'isNull' || typeFilter === 'isNotNull') && selectedFilterList.find((item) => item.key === data.key)) {
      return;
    }
    const counter = selectedFilterList.filter(
      (item) => item.field === data.field && item.filter === data.filter
    ).length;
    setSelectedFilterList([...selectedFilterList, { ...data, index: counter }]);
  };

  const handleUserType = (type: number) => {
    switch (type) {
      case 1:
        return 'New';
      case 2:
        return 'Current';
      case 3:
        return 'Cancel/Expired';
      default:
        return '';
    }
  };

  /* Datas */
  const columns: ColumnsType<PromotionColumnData> = useMemo(() => ([
    // --- ID
    {
      title: 'ID',
      key: 'id',
      width: 55,
      align: 'center',
      render: (_name: string, data: PromotionColumnData) => (
        <Typography.Text>
          {data.id}
        </Typography.Text>
      ),
    },
    // --- Tên
    {
      title: t('redeem.campaign'),
      dataIndex: 'campaign',
      key: 'campaign',
      render: (_name: string, data: PromotionColumnData) => (
        <Typography.Text>
          {data.campaign}
        </Typography.Text>
      ),
    },
    {
      title: t('promotion.userType'),
      dataIndex: 'userType',
      key: 'userType',
      render: (_name: string, data: PromotionColumnData) => (
        <Typography.Text>
          {handleUserType(data.userType)}
        </Typography.Text>
      )
    },
    {
      title: t('product.productType'),
      dataIndex: 'productType',
      key: 'productType',
      render: (_name: string, data: PromotionColumnData) => (
        <Typography.Text>{data.productType === 1 ? 'OneTime' : 'Subscription'}</Typography.Text>
      ),
    },
    {
      title: t('promotion.codeType'),
      dataIndex: 'codeType',
      key: 'codeType',
      render: (_name: string, data: PromotionColumnData) => (
        <Typography.Text>{data.codeType === 1 ? 'OneTime' : 'Multiple Time'}</Typography.Text>
      ),
    },
    {
      title: t('redeem.quantityCode'),
      dataIndex: 'quantity',
      key: 'quantity',
      width: 100,
      render: (_name: string, data: PromotionColumnData) => (
        <Typography.Text>
          {data.quantity}
        </Typography.Text>
      ),
    },
    {
      title: t('redeem.active'),
      key: 'status',
      align: 'center',
      render: (_name: string, data: PromotionColumnData) => (
        <Switch
          disabled={changeStatusLoading}
          checked={data.status === 1}
          onChange={() => changeStatusPromotionMutate({
            id: data.id,
            status: data.status === 1 ? 0 : 1
          })}
        />
      ),
    },
    {
      title: t('redeem.startDate'),
      dataIndex: 'startDate',
      key: 'startDate',
      width: 150,
      render: (_name: string, data: PromotionColumnData) => (
        <Typography.Text>
          {formatDateTime(data.startDate)}
        </Typography.Text>
      )
    },
    {
      title: t('redeem.endDate'),
      dataIndex: 'endDate',
      key: 'endDate',
      width: 150,
      render: (_name: string, data: PromotionColumnData) => (
        <Typography.Text>
          {formatDateTime(data.endDate || '')}
        </Typography.Text>
      ),
    },
    // --- Tạo lúc
    {
      title: t('system.createdAt'),
      dataIndex: 'createdAt',
      width: 250,
      key: 'createdAt',
      render: (_name: string, data: PromotionColumnData) => (
        <Typography.Text>
          {formatDateTime(data.createdAt || '')}
        </Typography.Text>
      ),
    },
    // --- Cập nhật
    {
      title: t('system.updatedAt'),
      dataIndex: 'updatedAt',
      width: 250,
      key: 'updatedAt',
      render: (_name: string, data: PromotionColumnData) => (
        <Typography.Text>
          {formatDateTime(data.updatedAt || '')}
        </Typography.Text>
      ),
    },
    // --- Thao tác
    {
      title: t('system.action'),
      key: 'action',
      width: 100,
      align: 'center',
      render: (_name: string, _data: PromotionColumnData) => (
        <Space>
          <Tooltip title="Edit">
            <Button
              disabled={!roleUpdate}
              icon={<EditOutlined />}
              onClick={() => navigateParams(ROUTE_PATHS.PROMOTION_DETAIL, `id=${_data.id}`)}
            />
          </Tooltip>
          <Tooltip title="Delete">
            <Button
              disabled={!roleDelete || deleteLoading}
              loading={deleteLoading}
              icon={<DeleteOutlined />}
              onClick={() => {
                Modal.confirm({
                  className: 't-pagetable_deleteRecordModal',
                  autoFocusButton: 'cancel',
                  okText: t('system.ok'),
                  cancelText: t('system.cancel'),
                  cancelButtonProps: {
                    type: 'primary',
                  },
                  okButtonProps: {
                    type: 'default',
                  },
                  title: t('message.confirmDeleteRecord'),
                  onOk: () => {
                    deleteMutate([_data.id]);
                  },
                });
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ]), [t, roleUpdate, roleDelete]);

  const tableData: PromotionColumnData[] = useMemo(() => (
    listData?.data.map((item) => ({
      id: item.promotionData.id,
      campaign: item.promotionData.campaign || '',
      status: item.promotionData.status,
      startDate: item.promotionData.startDate || '',
      endDate: item.promotionData.endDate || '',
      userType: item.promotionData.userType,
      productType: item.promotionData.productType,
      quantity: item.promotionData.quantity,
      codeType: item.promotionData.codeType,
      createdAt: item.promotionData.createdAt || '',
      updatedAt: item.promotionData.updatedAt || '',
    }))
  ) || [], [listData]);

  /* Effects */
  useEffect(() => {
    setCurrentPage(pageParam ? Number(pageParam) : 1);
    setSearchParams({
      ...mappingFilterToQuery(selectedFilterList),
      page: pageParam || '1'
    }, { replace: true });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword, setSearchParams, selectedFilterList]);

  useDidMount(() => {
    if (pageParam) return setCurrentPage(Number(pageParam));
    return setCurrentPage(Number(pageParam) || 1);
  });

  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.promotion')}
        rightHeader={(
          <Space>
            <Button
              disabled={!roleCreate}
              type="primary"
              onClick={() => navigate(ROUTE_PATHS.PROMOTION_DETAIL)}
            >
              <PlusOutlined />
              {t('system.create')}
            </Button>
          </Space>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          isLoading={listLoading || deleteLoading || changeStatusLoading}
          handleSearch={handleSearch}
          noCheckbox
          tableProps={{
            initShowColumns: ['id', 'campaign', 'userType', 'status', 'productType', 'codeType', 'startDate', 'endDate', 'quantity', 'action'],
            columns,
            pageData: tableData,
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: listData?.meta.total || 1,
            noBaseCol: true,
            noDeleteLanguage: true,
          }}
          filtersDataTable={{
            handleFilter,
            selectedFilterList,
            handleDeleteFilter
          }}
        />
      </div>
    </>
  );
};

export default PromotionManagement;
