import { EyeOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Row,
  Space,
  Spin,
  Tooltip,
  Typography
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import useDidMount from 'common/hooks/useDidMount';
import { subscriptionsByIdService, subscriptionTransactionHistoriesService } from 'common/services/extends/orders';
import { TransactionHistoryDataTypes } from 'common/services/extends/orders/types';
import {
  billingCycleOptions,
  paymentPlatformOptions,
  paymentStatusOptions,
  subscriptionStatusOptions
} from 'common/utils/constant';
import { formatDateTime } from 'common/utils/functions';
import roles, { getPermission } from 'configs/roles';
import UsageHistoryDetailModal from 'extends/UsageHistoriesManagement/usageHistoryDetailModal';

const viewColumns = {
  transactionId: 'transactionId',
  paymentDate: 'paymentDate',
  amount: 'amount',
  paymentStatus: 'paymentStatus',
  action: 'action',
};

const SubscriptionTransactionManagement: React.FC<ActiveRoles> = ({ roleIndex, roleOther }) => {
  /* Hooks */
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const idParams = searchParams.get('id');
  const paymentMethod = searchParams.get('paymentMethod') || '';

  const pageParam = searchParams.get('page');

  /* Selectors */
  const { system: { defaultPageSize } } = useAppSelector((state) => state);
  const rolesUser = useAppSelector((state) => state.auth.roles);

  /* State */
  const [currentPage, setCurrentPage] = useState(Number(pageParam));
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [keyword, setKeyword] = useState('');
  const [activeHistory, setActiveHistory] = useState<number>(-1);

  /* Queries */
  const { data: subscriptionDetail, isLoading: isLoadingDetail } = useQuery(
    ['getSubscriptionById', idParams, paymentMethod],
    () => {
      if (idParams) {
        return subscriptionsByIdService(idParams, {
          ...(paymentMethod && { paymentMethod })
        });
      }
      return null;
    },
    {
      enabled: !!(idParams && paymentMethod && roleIndex),
    }
  );

  const { data: transactionHistory, isLoading } = useQuery(
    ['getAllSubscriptionTransactionHistories', keyword, currentPage, currentView, idParams, paymentMethod],
    () => {
      if (idParams) {
        return subscriptionTransactionHistoriesService(idParams, {
          keyword,
          page: currentPage,
          limit: currentView,
          ...(paymentMethod && { paymentMethod })
        });
      }
      return null;
    },
    {
      enabled: !!(currentPage && idParams && roleOther.includes(roles.SUBSCRIPTION_HISTORY)),
    }
  );

  const tableData: TransactionHistoryDataTypes[] = useMemo(
    () => transactionHistory?.data || [],
    [transactionHistory]
  );

  const columns: ColumnsType<TransactionHistoryDataTypes> = [
    {
      title: t('subscription.transactionId'),
      key: viewColumns.transactionId,
      render: (_name: string, data: TransactionHistoryDataTypes) => (
        <Typography.Text>
          {data.subscriptionData.studentProductId}
        </Typography.Text>
      ),
    },
    {
      title: t('subscription.paymentDate'),
      key: viewColumns.paymentDate,
      render: (_name: string, data: TransactionHistoryDataTypes) => (
        <Typography.Text>
          {formatDateTime(data.subscriptionData.createdAt || '')}
        </Typography.Text>
      ),
    },
    {
      title: t('subscription.amount'),
      key: viewColumns.amount,
      render: (_name: string, data: TransactionHistoryDataTypes) => (
        <Typography.Text>
          {data.subscriptionData.amount ? data.subscriptionData.amount.toLocaleString() : ''}
        </Typography.Text>
      ),
    },
    {
      title: t('subscription.paymentStatus'),
      key: viewColumns.paymentStatus,
      render: (_name: string, data: TransactionHistoryDataTypes) => (
        <Typography.Text>
          {t(paymentStatusOptions.find((item) => item.value === data.subscriptionData.paymentStatus)?.label || '')}
        </Typography.Text>
      ),
    },
    {
      title: t('system.updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (_name: string, data: TransactionHistoryDataTypes) => (
        <Typography.Text>
          {formatDateTime(data.subscriptionData.updatedAt || '')}
        </Typography.Text>
      ),
    },
    {
      title: t('system.action'),
      key: 'action',
      width: 100,
      align: 'center',
      render: (_name: string, data: TransactionHistoryDataTypes) => (
        <Space>
          <Tooltip title="View Usage History">
            <Button
              disabled={!getPermission(rolesUser, roles.USAGE_HISTORIES_INDEX)
                || !data.subscriptionData.transactionId}
              icon={<EyeOutlined />}
              onClick={() => setActiveHistory(data.subscriptionData.studentProductId)}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  // Change page view
  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ page: page.toString() }, { replace: true });
  };

  // Change page size
  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  /* Effects */
  useEffect(() => {
    setCurrentPage(pageParam ? Number(pageParam) : 1);
    setSearchParams({
      page: pageParam || '1',
      id: idParams?.toString() || '',
      paymentMethod: paymentMethod || ''
    }, { replace: true });
  }, [keyword, setSearchParams, idParams, paymentMethod]);

  useDidMount(() => {
    if (pageParam) return setCurrentPage(Number(pageParam));
    return setCurrentPage(Number(pageParam) || 1);
  });

  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title={t('subscription.detailSubscription')}
      />
      <Spin spinning={isLoadingDetail}>
        <div className="t-mainlayout_wrapper">
          <Row gutter={24}>
            <Col>
              <Space direction="vertical" size={12} style={{ width: '100%' }}>
                <Card type="inner">
                  {/* Tiêu đề  */}
                  <Row gutter={16}>
                    <Col span={12}>
                      <div className="p-editPageTemplate_input u-mb-12">
                        <Typography.Text strong>
                          {t('subscription.planName')}
                          {': '}
                        </Typography.Text>
                        <Typography.Text>
                          {subscriptionDetail?.subscriptionData?.productName}
                        </Typography.Text>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="p-editPageTemplate_input u-mb-12">
                        <Typography.Text strong>
                          {t('subscription.planDescription')}
                          {': '}
                        </Typography.Text>
                        <ul>
                          {
                            subscriptionDetail?.subscriptionData
                              ?.productDescription?.map((item) => (
                                <li key={item}>{item}</li>
                              ))
                          }
                        </ul>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="p-editPageTemplate_input u-mb-12">
                        <Typography.Text strong>
                          {t('subscription.startDate')}
                          {': '}
                        </Typography.Text>
                        <Typography.Text>
                          {formatDateTime(subscriptionDetail?.subscriptionData?.startDate)}
                        </Typography.Text>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="p-editPageTemplate_input u-mb-12">
                        <Typography.Text strong>
                          {t('subscription.endDate')}
                          {': '}
                        </Typography.Text>
                        <Typography.Text>
                          {formatDateTime(subscriptionDetail?.subscriptionData?.endDate)}
                        </Typography.Text>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="p-editPageTemplate_input u-mb-12">
                        <Typography.Text strong>
                          {t('subscription.paymentFrequency')}
                          {': '}
                        </Typography.Text>
                        <Typography.Text>
                          {t(billingCycleOptions.find((item) => item.value === subscriptionDetail?.subscriptionData?.billingCycle)?.label || '')}
                        </Typography.Text>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="p-editPageTemplate_input u-mb-12">
                        <Typography.Text strong>
                          {t('subscription.paymentPlatform')}
                          {': '}
                        </Typography.Text>
                        <Typography.Text>
                          {t(paymentPlatformOptions.find((item) => item.value === subscriptionDetail?.subscriptionData?.paymentPlatform)?.label || '')}
                        </Typography.Text>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="p-editPageTemplate_input u-mb-12">
                        <Typography.Text strong>
                          {t('subscription.autoRenewalStatus')}
                          {': '}
                        </Typography.Text>
                        <Typography.Text>
                          {
                            subscriptionDetail?.subscriptionData?.subscriptionStatus === 1 ? t('system.no') : t('system.yes')
                          }
                        </Typography.Text>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="p-editPageTemplate_input u-mb-12">
                        <Typography.Text strong>
                          {t('subscription.subscriptionStatus')}
                          {': '}
                        </Typography.Text>
                        <Typography.Text>
                          {t(subscriptionStatusOptions.find((item) => item.value === subscriptionDetail?.subscriptionData?.subscriptionStatus)?.label || '')}
                        </Typography.Text>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Space>
            </Col>
          </Row>
        </div>
      </Spin>
      { roleOther.includes(roles.SUBSCRIPTION_HISTORY) && (
        <div className="t-mainlayout_wrapper p-subscriptionDetail_transaction">
          <Typography.Title level={4}>{t('subscription.subscriptionTransaction')}</Typography.Title>
          <PageTable
            isLoading={isLoading}
            handleSearch={setKeyword}
            tableProps={{
              initShowColumns: Object.values(viewColumns),
              columns,
              pageData: tableData || [],
              currentPage,
              pageSize: currentView,
              handleSetCurrentPage,
              handleSetCurrentView,
              total: transactionHistory?.meta.total ?? 1,
              noBaseCol: true,
              noDeleteLanguage: true,
            }}
          />
        </div>
      )}
      <UsageHistoryDetailModal
        id={activeHistory}
        onOk={() => setActiveHistory(-1)}
        onCancel={() => setActiveHistory(-1)}
      />
    </>
  );
};

export default SubscriptionTransactionManagement;
