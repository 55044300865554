import {
  EyeOutlined,
  EyeTwoTone
} from '@ant-design/icons';
import {
  Button, Space,
  Typography
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import FunctionalDetailModal from './functionalDetailModal';
import UsageHistoryDetailModal from './usageHistoryDetailModal';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import {
  FilterValueProps,
  mappingFilterToQuery,
  mappingParamsFilter,
  mappingQueryParamsFilter
} from 'common/components/PageTable/filter';
import useDidMount from 'common/hooks/useDidMount';
import { getUsageHistoriesService } from 'common/services/usageHistories';
import { TFunctionalData, TUsageHistoryData } from 'common/services/usageHistories/types';
import { formatDateTime } from 'common/utils/functions';

type TColumn = TUsageHistoryData['usageHistoryData'];
const UsageHistoriesManagement: React.FC<ActiveRoles> = ({
  roleIndex
}) => {
  /* Hook */
  const { t } = useTranslation();

  /* Selectors */
  const { defaultPageSize, defaultWebsiteLanguage } = useAppSelector((state) => state.system);
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => {
    const paramsObj = { ...Object.fromEntries(searchParams.entries()) };
    if (searchParams.has('page')) delete paramsObj.page;
    return paramsObj;
  }, [searchParams]);

  const pageParam = searchParams.get('page');

  /* States */
  const [currentPage, setCurrentPage] = useState(Number(pageParam));
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [keyword, setKeyword] = useState('');
  const [activeFunctional, setActiveFunctional] = useState<TFunctionalData[]>([]);
  const [activeHistory, setActiveHistory] = useState<number>(-1);
  const [selectedFilterList] = useState<FilterValueProps[]>(mappingQueryParamsFilter(params));

  const returnParamsFilter = useMemo(
    () => mappingParamsFilter(selectedFilterList),
    [selectedFilterList]
  );

  /* React-query */
  const {
    isFetching: listLoading,
    data: listData,
  } = useQuery(
    ['usage-histories-list', currentPage, keyword, currentView],
    () => getUsageHistoriesService(
    {
      page: currentPage,
      keyword,
      limit: currentView,
      ...returnParamsFilter
    }
    ),
    {
      enabled: !!currentPage,
    }
  );

  /* Functions */
  const handleSearch = (val: string) => {
    setKeyword(val);
  };

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ page: page.toString() }, { replace: true });
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  /* Datas */
  const columns: ColumnsType<TColumn> = useMemo(() => ([
    // --- ID
    {
      title: 'ID',
      key: 'id',
      width: 55,
      align: 'center',
      render: (_name: string, data: TColumn) => (
        <Typography.Text>
          {data.id}
        </Typography.Text>
      ),
    },
    // --- Tên
    {
      title: t('usageHistories.productName'),
      dataIndex: 'productName',
      key: 'productName',
      render: (_name: string, data: TColumn) => (
        <Typography.Text>
          {defaultWebsiteLanguage === 'vi'
            ? data.product?.nameVI
            : data.product?.nameEN}
        </Typography.Text>
      ),
    },
    // --- Tên
    {
      title: t('usageHistories.studentName'),
      dataIndex: 'studentName',
      key: 'studentName',
      render: (_name: string, data: TColumn) => (
        <Typography.Text>
          {data.student?.name || ''}
        </Typography.Text>
      ),
    },
    // --- userLevel
    {
      title: t('usageHistories.userLevel'),
      dataIndex: 'userLevel',
      key: 'userLevel',
      align: 'center',
      render: (_name: string, data: TColumn) => (
        <Typography.Text>
          {data.userLevel}
        </Typography.Text>
      )
    },
    // --- functional
    {
      title: t('usageHistories.functional'),
      dataIndex: 'functional',
      key: 'functional',
      render: (_name: string, data: TColumn) => (
        data.functionals?.[0] ? (
          <div className="p-solutionSearch_wrapper">
            <div className="p-solutionSearch_result">
              <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                <Space>
                  <Typography.Text strong>
                    {t('usageHistories.functionalType')}
                    :
                  </Typography.Text>
                  <Typography.Text>
                    {data.functionals?.[0]?.functionalType}
                  </Typography.Text>
                </Space>
                <Space>
                  <Typography.Text strong>
                    {t('usageHistories.webOrWeekLimit')}
                    :
                  </Typography.Text>
                  <Typography.Text>
                    {data.functionals?.[0]?.webOrWeekLimit}
                  </Typography.Text>
                </Space>
                <Space>
                  <Typography.Text strong>
                    {t('usageHistories.remainWebOrWeekLimit')}
                    :
                  </Typography.Text>
                  <Typography.Text>
                    {data.functionals?.[0]?.remainWebOrWeekLimit}
                  </Typography.Text>
                </Space>
                <Space>
                  <Typography.Text strong>
                    {t('usageHistories.appOrMonthLimit')}
                    :
                  </Typography.Text>
                  <Typography.Text>
                    {data.functionals?.[0]?.appOrMonthLimit}
                  </Typography.Text>
                </Space>
                <Space>
                  <Typography.Text strong>
                    {t('usageHistories.remainAppOrMonthLimit')}
                    :
                  </Typography.Text>
                  <Typography.Text>
                    {data.functionals?.[0]?.remainAppOrMonthLimit}
                  </Typography.Text>
                </Space>
              </div>
            </div>
            <EyeTwoTone onClick={() => setActiveFunctional(data.functionals)} style={{ fontSize: '20px' }} className="p-solutionSearch_icon" />
          </div>
        ) : null)
    },
    // --- Tạo lúc
    {
      title: t('usageHistories.startAt'),
      dataIndex: 'startAt',
      key: 'startAt',
      width: 250,
      render: (_name: string, data: TColumn) => (
        <Typography.Text>
          {formatDateTime(data.startAt)}
        </Typography.Text>
      ),
    },
    // --- Cập nhật
    {
      title: t('usageHistories.endAt'),
      dataIndex: 'endAt',
      width: 250,
      key: 'endAt',
      render: (_name: string, data: TColumn) => (
        <Typography.Text>
          {formatDateTime(data.endAt)}
        </Typography.Text>
      ),
    },
    // --- Thao tác
    {
      title: t('system.action'),
      key: 'action',
      width: 100,
      align: 'center',
      render: (_name: string, _data: TColumn) => (
        <Space>
          <Button
            disabled={!roleIndex}
            icon={<EyeOutlined />}
            onClick={() => setActiveHistory(_data.id)}
          />
        </Space>
      ),
    },
  ]), [t, roleIndex, defaultWebsiteLanguage]);

  const tableData: TColumn[] = useMemo(() => (
    listData?.data.map((item) => ({
      ...item.usageHistoryData
    })) || []), [listData]);

  /* Effects */
  useEffect(() => {
    setCurrentPage(pageParam ? Number(pageParam) : 1);
    setSearchParams({
      ...mappingFilterToQuery(selectedFilterList),
      page: pageParam || '1'
    }, { replace: true });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword, setSearchParams, selectedFilterList]);

  useDidMount(() => {
    if (pageParam) return setCurrentPage(Number(pageParam));
    return setCurrentPage(Number(pageParam) || 1);
  });

  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.usageHistories')}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          isLoading={listLoading}
          handleSearch={handleSearch}
          noCheckbox
          tableProps={{
            initShowColumns: ['id', 'productName', 'studentName', 'userLevel', 'functional', 'startAt', 'endAt', 'action'],
            columns,
            pageData: tableData,
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: listData?.meta.total || 1,
            noBaseCol: true,
            noDeleteLanguage: true,
          }}
        />
      </div>
      <FunctionalDetailModal
        open={activeFunctional.length > 0}
        onOk={() => setActiveFunctional([])}
        onCancel={() => setActiveFunctional([])}
        data={activeFunctional}
      />
      <UsageHistoryDetailModal
        id={activeHistory}
        onOk={() => setActiveHistory(-1)}
        onCancel={() => setActiveHistory(-1)}
      />
    </>
  );
};

export default UsageHistoriesManagement;
