import {
  CreateRedeemParams,
  RedeemDataTypes,
  RedeemDetailTypes,
  RevokeRedeemParam,
  UpdateRedeemParams
} from './types';

import axiosInstance from 'common/services/common/instance';

export const getAllRedeemService = async (
  params?: BaseFilterParams,
): Promise<APIPaginationResponse<RedeemDataTypes[]>> => {
  const res = await axiosInstance.get('redeems', { params });
  return res.data;
};

export const getRedeemService = async (
  id: string,
): Promise<RedeemDetailTypes> => {
  const res = await axiosInstance.get(`redeems/${id}`);
  return res.data.data;
};

export const createRedeemService = async (
  params: CreateRedeemParams,
): Promise<void> => {
  await axiosInstance.post('redeems', params);
};

export const updateRedeemService = async (
  params: UpdateRedeemParams,
): Promise<void> => {
  await axiosInstance.put(`redeems/${params.id}`, params);
};

export const checkCodeExistRedeemService = async (params: { code: string }): Promise<void> => {
  await axiosInstance.post('redeems/check-code-exist', params);
};

export const revokeRedeemCodeService = async (params: RevokeRedeemParam): Promise<void> => {
  const { id, ...rest } = params;
  await axiosInstance.post(`redeems/${id}/revoke-code`, rest);
};

export const deleteRedeemService = async (ids: number[]): Promise<void> => {
  await axiosInstance.delete('redeems', { data: { ids } });
};
export const changeRedeemStatusService = async (id: number, status: number) : Promise<void> => {
  await axiosInstance.put(`redeems/${id}/status`, { status });
};
