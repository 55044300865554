import {
  AiExerciesDataRequest,
  AiExerciesDataTypes,
  AiExerciesDetailDataTypes
} from './types';

import axiosInstance from 'common/services/common/instance';

export const getAllAiExerciesService = async (
  params?: BaseFilterParams,
): Promise<APIPaginationResponse<AiExerciesDataTypes[]>> => {
  const res = await axiosInstance.get('ai-exercises', { params });
  return res.data;
};

export const getAiExerciesService = async (params: AiExerciesDataRequest)
: Promise<APIPaginationResponse<AiExerciesDetailDataTypes[]>> => {
  const { id, ...rest } = params;
  const res = await axiosInstance.get(`ai-exercises/${id}`, { params: rest });
  return res.data;
};
