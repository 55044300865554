import { SaveOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button, Card, Col, DatePicker, message,
  Modal,
  Row,
  Space,
  Spin,
  Tooltip,
  Typography
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import React, { useMemo, useState } from 'react';
import {
  Controller, FormProvider,
  useForm
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import { DropdownElement } from 'common/components/DropdownType';
import HeaderPage from 'common/components/HeaderPage';
import Input from 'common/components/Input';
import ManagementInfo from 'common/components/ManagementInfo';
import PageTable from 'common/components/PageTable';
import {
  createPromotionService,
  getPromotionByIdService,
  revokePromotionCodeService,
  updatePromotionService
} from 'common/services/extends/promotion';
import {
  CreatePromotionParams,
  RevokePromotionParam,
  UpdatePromotionParams
} from 'common/services/extends/promotion/types';
import {
  codeTypeOptions,
  dateDataOptions,
  discountTypeOptions,
  durationTypeOptions,
  monthDataOptions,
  productDurations,
  productDurationsExtend,
  productTypeOptions,
  promotionTypeOptions,
  ROUTE_PATHS,
  userTypeOptions
} from 'common/utils/constant';
import { formatDateTime } from 'common/utils/functions';
import { promotionFormSchema } from 'common/utils/schemas';
import { ColumnsTypes } from 'extends/Redeem/detail';

type PromotionFormTypes = {
  campaign: string
  userType: number
  productType: number
  promotionType: number
  discountType?: number
  discountAmount?: number
  discountVNDAmount?: number
  freeDuration?: number
  durationType?: number
  startDate: string,
  endDate: string,
  quantity: number,
  codeType: number,
  code?: string,
  productDuration: number
};

const DEFAULT_VALUES: PromotionFormTypes = {
  campaign: '',
  userType: 1,
  productType: 1,
  promotionType: 1,
  startDate: '',
  endDate: '',
  quantity: 1,
  codeType: 1,
  productDuration: 7,
};

const PromotionDetail: React.FC<ActiveRoles> = ({
  roleUpdate,
  roleCreate
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { defaultWebsiteLanguage, defaultPageSize } = useAppSelector((state) => state.system);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentView, setCurrentView] = useState(defaultPageSize);

  const queryClient = useQueryClient();

  const localeParams = searchParams.get('locale') || defaultWebsiteLanguage || '';

  const idParams = searchParams.get('id');
  const [currentLang] = useState<string>(localeParams);

  const method = useForm<PromotionFormTypes>({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(promotionFormSchema),
    mode: 'onChange'
  });

  const queryPromotionById = ['getPromotionDataById', idParams];

  const { data: promotionData, isLoading: promotionDataLoading } = useQuery(
    queryPromotionById,
    () => {
      if (idParams) {
        return getPromotionByIdService(idParams);
      }
      return undefined;
    },
    {
      onSuccess: (data) => {
        const proData = data?.promotionData;
        if (proData) {
          method.reset({
            campaign: proData.campaign || '',
            userType: proData.userType,
            productType: proData.productType,
            promotionType: proData.promotionType,
            discountType: proData.discountType,
            discountAmount: proData.discountAmount,
            discountVNDAmount: proData.discountVNDAmount,
            freeDuration: proData.freeDuration,
            durationType: proData.durationType,
            productDuration: proData.productDuration,
            startDate: proData.startDate || '',
            endDate: proData.endDate || '',
            quantity: proData.quantity,
            codeType: proData.codeType,
            code: proData.code || '',
          });
        } else {
          method.reset(DEFAULT_VALUES);
        }
      },
    }
  );

  const { mutate: createMutate, isLoading: createLoading } = useMutation(
    ['promotion-create'],
    async (params: CreatePromotionParams) => createPromotionService(params),
    {
      onSuccess: () => {
        navigate(ROUTE_PATHS.PROMOTION_MANAGEMENT);
      },
      onError: (errors: ErrorResponse[]) => {
        if (errors.length > 0) {
          errors.forEach((error: ErrorResponse) => {
            method.setError(error.field as keyof PromotionFormTypes, { message: error.message || '' });
          });
        } else {
          message.error(t('message.updateError'));
        }
      },
    }
  );

  const { mutate: updateMutate, isLoading: updateLoading } = useMutation(
    ['promotion-update'],
    async (params: UpdatePromotionParams) => updatePromotionService(params),
    {
      onSuccess: () => {
        message.success(t('message.updateSuccess'));
        queryClient.invalidateQueries(queryPromotionById);
      },
      onError: (errors: ErrorResponse[]) => {
        if (errors.length > 0) {
          if (errors[0].code === 'active') {
            message.error(errors[0].message);
          } else {
            errors.forEach((error: ErrorResponse) => {
              method.setError(error.field as keyof PromotionFormTypes, { message: error.message || '' });
            });
          }
        } else {
          message.error(t('message.updateError'));
        }
      },
    }
  );

  const { mutate: revokeMutate, isLoading: revokeLoading } = useMutation(
    ['revoke-mutate'],
    async (params: RevokePromotionParam) => revokePromotionCodeService(params),
    {
      onSuccess: () => {
        message.success(t('message.updateSuccess'));
        queryClient.invalidateQueries(queryPromotionById);
      },
      onError: () => {
        message.error(t('message.updateError'));
      }
    }
  );

  const productDurationData = useMemo(() => {
    if (method.watch('productType') === 1) {
      return productDurationsExtend;
    }
     return productDurations;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [method.watch('productType')]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleStatus = (status: number) => {
    switch (status) {
      case 0:
        return t('redeem.notStartedYet');
      case 1:
        return t('redeem.active');
      case 2:
        return t('redeem.expired');
      case 3:
        return t('redeem.redeemed');
      case 4:
        return t('redeem.revoked');
      default:
        return '';
    }
  };

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  const onSubmit = async () => {
    const isValid = await method.trigger();
    if (!isValid) {
      return;
    }

    const formData = method.getValues();
    const dataRequest: CreatePromotionParams = {
      campaign: formData.campaign || '',
      userType: formData.userType,
      productType: formData.productType,
      promotionType: formData.promotionType,
      startDate: formData.startDate,
      endDate: formData.endDate,
      quantity: formData.quantity,
      codeType: formData.codeType,
      productDuration: formData.productDuration,
      ...(formData.code && { code: formData.code }),
      ...(formData.discountType && { discountType: formData.discountType }),
      ...(formData.discountAmount && { discountAmount: formData.discountAmount }),
      ...(formData.freeDuration && { freeDuration: formData.freeDuration }),
      ...(formData.durationType && { durationType: formData.durationType }),
      ...(formData.discountVNDAmount && { discountVNDAmount: formData.discountVNDAmount }),
    };
    if (idParams) {
      updateMutate({
        id: idParams,
        ...dataRequest
      });
    } else {
      createMutate(dataRequest);
    }
  };

  const tableData: ColumnsTypes[] = useMemo(() => (
    promotionData?.promotionData.promotionCodes.map((val) => ({
      studentId: val?.id,
      code: val?.code || '',
      studentName: val?.student?.name || '',
      studentEmail: val?.student?.email || '',
      redeemedDate: val?.redeemedDate || '',
      status: val?.status,
  })) || []), [promotionData]);

  const columns: ColumnsType<ColumnsTypes> = useMemo(() => ([
    // --- ID
    {
      title: 'ID',
      key: 'id',
      width: 55,
      align: 'center',
      render: (_name: string, data: ColumnsTypes) => (
        <Typography.Text>
          {data.studentId}
        </Typography.Text>
      ),
    },
    // --- Tên
    {
      title: t('system.name'),
      dataIndex: 'name',
      key: 'name',
      render: (_name: string, data: ColumnsTypes) => (
        <Typography.Text>
          {data.studentName}
        </Typography.Text>
      ),
    },
    {
      title: t('system.email'),
      dataIndex: 'email',
      key: 'email',
      render: (_name: string, data: ColumnsTypes) => (
        <Typography.Text>
          {data.studentEmail}
        </Typography.Text>
      ),
    },
    {
      title: t('system.code'),
      dataIndex: 'code',
      key: 'code',
      render: (_name: string, data: ColumnsTypes) => (
        <Typography.Text>
          {data.code}
        </Typography.Text>
      ),
    },
    {
      title: t('system.status'),
      dataIndex: 'status',
      key: 'status',
      render: (_name: string, data: ColumnsTypes) => (
        <Typography.Text>
          {handleStatus(data.status)}
        </Typography.Text>
      )
    },
    {
      title: t('redeem.redeemedDate'),
      dataIndex: 'redeemedDate',
      key: 'redeemedDate',
      render: (_name: string, data: ColumnsTypes) => (
        <Typography.Text>
          {formatDateTime(data.redeemedDate || '')}
        </Typography.Text>
      ),
    },
    {
      title: t('system.action'),
      key: 'action',
      width: 100,
      align: 'center',
      render: (_name: string, _data: ColumnsTypes) => (
        <Space>
          <Tooltip title="Revoke">
            <Button
              disabled={!roleUpdate || revokeLoading || !idParams
              || (_data.status === 3) || (_data.status === 4)}
              loading={revokeLoading}
              icon={<ThunderboltOutlined />}
              onClick={() => {
                if (!idParams) return;
                Modal.confirm({
                  className: 't-pagetable_deleteRecordModal',
                  autoFocusButton: 'cancel',
                  okText: t('system.ok'),
                  cancelText: t('system.cancel'),
                  cancelButtonProps: {
                    type: 'primary',
                  },
                  okButtonProps: {
                    type: 'default',
                  },
                  title: t('Đồng ý thu hồi mã này ?'),
                  onOk: () => {
                    revokeMutate({
                      id: idParams,
                      redeemCodeIds: [_data.studentId]
                    });
                  },
                });
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ]), [handleStatus, idParams, revokeLoading, revokeMutate, roleUpdate, t]);

  return (
    <FormProvider<PromotionFormTypes> {...method}>
      <HeaderPage
        fixed
        title={idParams ? t('system.update') : t('system.create')}
        rightHeader={(
          <Button
            type="primary"
            loading={promotionDataLoading || createLoading || updateLoading}
            onClick={method.handleSubmit(onSubmit)}
            disabled={!roleUpdate}
          >
            <SaveOutlined />
            {t('system.save')}
          </Button>
        )}
      />
      <Spin spinning={promotionDataLoading || createLoading || updateLoading}>
        <div className="t-mainlayout_wrapper">
          <Row gutter={16}>
            <Col xxl={18} xl={16} lg={16}>
              <Card>
                <Row gutter={16}>
                  <Col span={12} className="u-mb-12">
                    <Typography.Text strong>
                      {t('redeem.campaign')}
                      {' '}
                    </Typography.Text>
                    <Typography.Text strong type="danger">
                      *
                    </Typography.Text>
                    <Controller
                      name="campaign"
                      render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <Input
                            className="u-mt-8"
                            name="campaign"
                            placeholder={`${t('system.input')} ${t('redeem.campaign')}`}
                            value={value}
                            onChange={onChange}
                            error={error?.message}
                            size="large"
                          />
                        )}
                    />
                  </Col>
                  <Col span={12} className="u-mb-12">
                    <Typography.Text strong>
                      {t('promotion.userType')}
                      {' '}
                    </Typography.Text>
                    <Typography.Text strong type="danger">
                      *
                    </Typography.Text>
                    <Controller
                      name="userType"
                      render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <>
                            <DropdownElement
                              options={userTypeOptions}
                              placeholder={`${t('system.select')} ${t('promotion.userType')}`}
                              locale={currentLang}
                              value={value}
                              size="large"
                              onChange={onChange}
                            />
                            {error && (
                              <span
                                className="a-input_errorMessage"
                              >
                                {error.message}
                              </span>
                            )}
                          </>
                        )}
                    />
                  </Col>
                  <Col span={12} className="u-mb-12">
                    <Typography.Text strong>
                      {t('product.productType')}
                      {' '}
                    </Typography.Text>
                    <Typography.Text strong type="danger">
                      *
                    </Typography.Text>
                    <Controller
                      name="productType"
                      render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <>
                            <DropdownElement
                              options={productTypeOptions}
                              placeholder={`${t('system.select')} ${t('product.productType')}`}
                              locale={currentLang}
                              value={value}
                              size="large"
                              onChange={(e) => {
                                if (e === 2 && method.watch('productDuration') === -1) {
                                  method.setValue('productDuration', 7);
                                }
                                onChange(e);
                              }}
                            />
                            {error && (
                              <span
                                className="a-input_errorMessage"
                              >
                                {error.message}
                              </span>
                            )}
                          </>
                        )}
                    />
                  </Col>
                  <Col span={12} className="u-mb-16">
                    <Typography.Text strong>
                      Product Duration
                      {' '}
                    </Typography.Text>
                    <Typography.Text strong type="danger">
                      *
                    </Typography.Text>
                    <Controller
                      name="productDuration"
                      defaultValue={7}
                      render={({ field: { value, onChange }, fieldState: { error } }) => (
                        <>
                          <DropdownElement
                            options={productDurationData}
                            placeholder={`${t('system.select')} ${t('product.duration')}`}
                            locale={currentLang}
                            value={value}
                            size="large"
                            onChange={onChange}
                          />
                          {error && <span className="a-input_errorMessage">{error.message}</span>}
                        </>
                        )}
                    />
                  </Col>
                  <Col span={12} className="u-mb-12">
                    <Typography.Text strong>
                      {t('promotion.promotionType')}
                      {' '}
                    </Typography.Text>
                    <Typography.Text strong type="danger">
                      *
                    </Typography.Text>
                    <Controller
                      name="promotionType"
                      render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <>
                            <DropdownElement
                              options={promotionTypeOptions}
                              placeholder={`${t('system.select')} ${t('promotion.promotionType')}`}
                              locale={currentLang}
                              value={value}
                              size="large"
                              onChange={(e) => {
                                if (e === 1) {
                                  method.setValue('freeDuration', undefined);
                                  method.setValue('durationType', undefined);
                                } else {
                                  method.setValue('discountType', undefined);
                                  method.setValue('discountAmount', undefined);
                                }
                                onChange(e);
                              }}
                            />
                            {error && (
                              <span
                                className="a-input_errorMessage"
                              >
                                {error.message}
                              </span>
                            )}
                          </>
                        )}
                    />
                  </Col>
                  <Col span={12} className="u-mb-12">
                    <Typography.Text strong>
                      {t('promotion.discountType')}
                      {' '}
                    </Typography.Text>
                    { method.watch('promotionType') === 1 && (
                      <Typography.Text strong type="danger">
                        *
                      </Typography.Text>
                    )}
                    <Controller
                      name="discountType"
                      render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <>
                            <DropdownElement
                              options={discountTypeOptions}
                              placeholder={`${t('system.select')} ${t('promotion.discountType')}`}
                              locale={currentLang}
                              value={value}
                              size="large"
                              disabled={method.watch('promotionType') === 2}
                              onChange={(e) => {
                                if (e === 1) {
                                  method.setValue('discountVNDAmount', undefined);
                                }
                                onChange(e);
                              }}
                            />
                            {error && (
                              <span
                                className="a-input_errorMessage"
                              >
                                {error.message}
                              </span>
                            )}
                          </>
                        )}
                    />
                  </Col>
                  <Col span={12} className="u-mb-12">
                    <Typography.Text strong>
                      {method.watch('discountType') === 1 ? t('promotion.valuePercent') : t('promotion.discountAmountUSD')}
                      {' '}
                    </Typography.Text>
                    { method.watch('promotionType') === 1 && (
                      <Typography.Text strong type="danger">
                        *
                      </Typography.Text>
                    )}
                    <Controller
                      name="discountAmount"
                      render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <Input
                            className="u-mt-8"
                            name="discountAmount"
                            placeholder={`${t('system.input')}`}
                            value={value}
                            type="number"
                            onChange={onChange}
                            error={error?.message}
                            size="large"
                            disabled={method.watch('promotionType') === 2}
                          />
                        )}
                    />
                  </Col>
                  <Col span={12} className="u-mb-12">
                    <Typography.Text strong>
                      {t('promotion.discountAmountVND')}
                      {' '}
                    </Typography.Text>
                    { method.watch('promotionType') === 1 && method.watch('discountType') === 2 && (
                      <Typography.Text strong type="danger">
                        *
                      </Typography.Text>
                    )}
                    <Controller
                      name="discountVNDAmount"
                      render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <Input
                            className="u-mt-8"
                            name="discountVNDAmount"
                            placeholder={`${t('system.input')} ${t('promotion.discountAmountVND')}`}
                            value={value}
                            type="number"
                            onChange={onChange}
                            error={error?.message}
                            size="large"
                            disabled={method.watch('discountType') !== 2}
                          />
                        )}
                    />
                  </Col>
                  <Col span={12} className="u-mb-12">
                    <Typography.Text strong>
                      {t('promotion.durationType')}
                      {' '}
                    </Typography.Text>
                    { method.watch('promotionType') === 2 && (
                      <Typography.Text strong type="danger">
                        *
                      </Typography.Text>
                    )}
                    <Controller
                      name="durationType"
                      render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <>
                            <DropdownElement
                              options={durationTypeOptions}
                              placeholder={`${t('system.select')} ${t('promotion.durationType')}`}
                              locale={currentLang}
                              value={value}
                              size="large"
                              onChange={(e) => {
                                method.setValue('freeDuration', undefined);
                                onChange(e);
                              }}
                              disabled={method.watch('promotionType') === 1}
                            />
                            {error && (
                              <span
                                className="a-input_errorMessage"
                              >
                                {error.message}
                              </span>
                            )}
                          </>
                        )}
                    />
                  </Col>
                  <Col span={12} className="u-mb-12">
                    <Typography.Text strong>
                      {t('promotion.freeDuration')}
                      {' '}
                    </Typography.Text>
                    { method.watch('promotionType') === 2 && (
                      <Typography.Text strong type="danger">
                        *
                      </Typography.Text>
                    )}
                    <Controller
                      name="freeDuration"
                      render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <>
                            <DropdownElement
                              options={method.watch('durationType') === 1 ? dateDataOptions : monthDataOptions}
                              placeholder={`${t('system.select')} ${t('promotion.freeDuration')}`}
                              locale={currentLang}
                              value={value}
                              size="large"
                              onChange={onChange}
                              disabled={method.watch('promotionType') === 1}
                            />
                            {error && (
                              <span
                                className="a-input_errorMessage"
                              >
                                {error.message}
                              </span>
                            )}
                          </>
                        )}
                    />
                  </Col>
                  <Col span={12} className="u-mb-12">
                    <Typography.Text strong>
                      {t('redeem.startDate')}
                      {' '}
                    </Typography.Text>
                    <Typography.Text strong type="danger">
                      *
                    </Typography.Text>
                    <Controller
                      name="startDate"
                      render={({ field: { value, onChange }, fieldState: { error } }) => (
                        <>
                          <DatePicker
                            value={value ? dayjs(value) : null}
                            onChange={onChange}
                            style={{ width: '100%' }}
                            size="large"
                            className="u-mt-8"
                            disabledDate={(current) => current && current < dayjs().endOf('day')}
                          />
                          {error && <span className="a-input_errorMessage">{error.message}</span>}
                        </>
                        )}
                    />
                  </Col>
                  <Col span={12} className="u-mb-12">
                    <Typography.Text strong>
                      {t('redeem.endDate')}
                      {' '}
                    </Typography.Text>
                    <Typography.Text strong type="danger">
                      *
                    </Typography.Text>
                    <Controller
                      name="endDate"
                      render={({ field: { value, onChange }, fieldState: { error } }) => (
                        <>
                          <DatePicker
                            value={value ? dayjs(value) : null}
                            onChange={onChange}
                            style={{ width: '100%' }}
                            className="u-mt-8"
                            size="large"
                            disabledDate={(current) => current && current < dayjs().endOf('day')}
                          />
                          {error && <span className="a-input_errorMessage">{error.message}</span>}
                        </>
                        )}
                    />
                  </Col>
                  <Col span={12} className="u-mb-12">
                    <Typography.Text strong>
                      {t('promotion.codeType')}
                      {' '}
                    </Typography.Text>
                    <Typography.Text strong type="danger">
                      *
                    </Typography.Text>
                    <Controller
                      name="codeType"
                      render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <>
                            <DropdownElement
                              options={codeTypeOptions}
                              placeholder={`${t('system.select')} ${t('promotion.codeType')}`}
                              locale={currentLang}
                              value={value}
                              size="large"
                              onChange={onChange}
                            />
                            {error && (
                              <span
                                className="a-input_errorMessage"
                              >
                                {error.message}
                              </span>
                            )}
                          </>
                        )}
                    />
                  </Col>
                  <Col span={12} className="u-mb-12">
                    <Typography.Text strong>
                      {t('redeem.quantityCode')}
                      {' '}
                    </Typography.Text>
                    <Typography.Text strong type="danger">
                      *
                    </Typography.Text>
                    <Controller
                      name="quantity"
                      render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <Input
                            className="u-mt-8"
                            name="quantity"
                            placeholder={`${t('system.input')} ${t('redeem.quantityCode')}`}
                            value={value}
                            type="number"
                            onChange={onChange}
                            error={error?.message}
                            size="large"
                          />
                        )}
                    />
                  </Col>
                  { (idParams && !(method.watch('codeType') === 1)) && (
                    <Col span={12} className="u-mb-12">
                      <Typography.Text strong>
                        {t('system.code')}
                        {' '}
                      </Typography.Text>
                      <Controller
                        name="code"
                        render={({
                            field: { value, onChange },
                            fieldState: { error },
                          }) => (
                            <Input
                              className="u-mt-8"
                              name="code"
                              value={value}
                              onChange={onChange}
                              error={error?.message}
                              size="large"
                              disabled
                            />
                          )}
                      />
                    </Col>
                  )}
                </Row>
              </Card>
            </Col>
            { idParams && (
              <Col xxl={6} xl={8} lg={8}>
                <ManagementInfo
                  createdDate={promotionData?.promotionData.createdAt ? dayjs(promotionData?.promotionData.createdAt).fromNow() : ''}
                  createdBy="Admin"
                  lastUpdated={promotionData?.promotionData.updatedAt ? dayjs(promotionData?.promotionData.updatedAt).fromNow() : ''}
                  lastUpdatedBy="Admin"
                />
              </Col>
            )}
            {idParams && tableData.length > 0 && (
            <Col xxl={24}>
              <Card
                className="u-mt-16"
                title={(
                  <Space direction="horizontal" style={{ justifyContent: 'space-between', width: '100%' }}>
                    <Typography.Title level={4}>Promotion Code List</Typography.Title>
                    <Button
                      type="primary"
                      onClick={() => {
                          const idArrs = promotionData?.promotionData.promotionCodes
                            .filter((ele) => ele.status !== 3 && ele.status !== 4)
                            .map((item) => item.id) || [];
                          Modal.confirm({
                            className: 't-pagetable_deleteRecordModal',
                            autoFocusButton: 'cancel',
                            okText: t('system.ok'),
                            cancelText: t('system.cancel'),
                            cancelButtonProps: {
                              type: 'primary',
                            },
                            okButtonProps: {
                              type: 'default',
                            },
                            title: t('Đồng ý thu hồi tất tất cả ?'),
                            onOk: () => {
                              if (idArrs.length > 0) {
                                revokeMutate({
                                  id: idParams,
                                  redeemCodeIds: idArrs
                                });
                              }
                            },
                          });
                        }}
                      disabled={!roleCreate || revokeLoading}
                      loading={revokeLoading || promotionDataLoading}
                    >
                      <ThunderboltOutlined />
                      Revoke All
                    </Button>
                  </Space>
                )}
              >
                <Col>
                  <PageTable
                    isLoading={promotionDataLoading}
                    noCheckbox
                    tableProps={{
                      initShowColumns: ['id', 'name', 'email', 'code', 'status', 'redeemedDate', 'action'],
                      columns,
                      pageData: tableData,
                      currentPage,
                      pageSize: currentView,
                      handleSetCurrentPage,
                      handleSetCurrentView,
                      total: (currentView && tableData.length > 0)
                      ? Math.floor(tableData.length / currentView) + 1 : 1,
                      noBaseCol: true,
                      noDeleteLanguage: true,
                      }}
                  />
                </Col>
              </Card>
            </Col>
            )}
          </Row>
        </div>
      </Spin>
    </FormProvider>
  );
};

export default PromotionDetail;
