import { InboxOutlined } from '@ant-design/icons';
import {
  Col,
  Modal, ModalProps,
  Row,
  Space,
  Spin,
  Typography
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { useAppSelector } from 'app/store';
import PageTable from 'common/components/PageTable';
import { getUsageHistoryByIdService } from 'common/services/usageHistories';
import { formatDateTime } from 'common/utils/functions';

export type ColumnsTypes = {
  functionalType: string,
  appOrMonthLimit: number,
  totalUsed: number,
  totalRemain: number,
};

const UsageHistoryDetailModal: React.FC<ModalProps & { id: number }> = ({
  id, onOk, onCancel
}) => {
  const { t } = useTranslation();
  const { defaultPageSize } = useAppSelector((state) => state.system);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentView, setCurrentView] = useState(defaultPageSize);

  const { data: resData, isLoading } = useQuery(
    ['get-usage-history-by-id', id],
    () => getUsageHistoryByIdService(id),
    {
      enabled: id > -1
    }
  );

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  const columns: ColumnsType<ColumnsTypes> = useMemo(() => ([
    // --- ID
    {
      title: 'Function Name',
      key: 'functionalType',
      width: 200,
      align: 'center',
      render: (_name: string, data: ColumnsTypes) => (
        <Typography.Text>
          {data.functionalType}
        </Typography.Text>
      ),
    },
    {
      title: 'Limit',
      dataIndex: 'appOrMonthLimit',
      key: 'appOrMonthLimit',
      render: (_name: string, data: ColumnsTypes) => (
        <Typography.Text>
          {data.appOrMonthLimit}
        </Typography.Text>
      ),
    },
    {
      title: 'Used',
      dataIndex: 'totalUsed',
      key: 'totalUsed',
      render: (_name: string, data: ColumnsTypes) => (
        <Typography.Text>
          {data.totalUsed}
        </Typography.Text>
      ),
    },
    {
      title: 'Remaining',
      dataIndex: 'totalRemain',
      key: 'totalRemain',
      render: (_name: string, data: ColumnsTypes) => (
        <Typography.Text>
          {data.totalRemain}
        </Typography.Text>
      ),
    },
  ]), []);

  const tableData: ColumnsTypes[] = useMemo(() => (
    resData?.functionals.map((val) => ({
      functionalType: val.functionalType,
      totalRemain: val.totalRemain,
      appOrMonthLimit: val.appOrMonthLimit,
      totalUsed: val.totalUsed,
  })) || []), [resData]);

  return (
    <Modal
      title={(<Typography.Title level={3} style={{ textAlign: 'center' }}>{t('usageHistories.usageHistoryDetail')}</Typography.Title>)}
      width="60vw"
      open={id > -1}
      centered
      onOk={onOk}
      onCancel={onCancel}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <Spin spinning={isLoading}>
        {resData ? (
          <>
            <Row gutter={16} className="u-mt-16">
              <Col span={24}>
                <Typography.Text strong>
                  {t('usageHistories.deviceId')}
                  :
                  {' '}
                </Typography.Text>
                <Typography.Text>
                  {resData?.deviceId || ''}
                </Typography.Text>
              </Col>
              <Col span={12}>
                <Typography.Text strong>
                  {t('usageHistories.startAt')}
                  :
                  {' '}
                </Typography.Text>
                <Typography.Text>
                  {formatDateTime(resData?.startAt || '')}
                </Typography.Text>
              </Col>
              <Col span={12}>
                <Typography.Text strong>
                  {t('usageHistories.endAt')}
                  :
                  {' '}
                </Typography.Text>
                <Typography.Text>
                  {formatDateTime(resData?.endAt)}
                </Typography.Text>
              </Col>
            </Row>
            <div className="u-mt-8">
              <PageTable
                isLoading={isLoading}
                noCheckbox
                tableProps={{
                  initShowColumns: ['functionalType', 'appOrMonthLimit', 'totalUsed', 'totalRemain'],
                  columns,
                  pageData: tableData,
                  currentPage,
                  pageSize: currentView,
                  handleSetCurrentPage,
                  handleSetCurrentView,
                  total: (currentView && tableData.length > 0)
                  ? Math.floor(tableData.length / currentView) + 1 : 1,
                  noBaseCol: true,
                  noDeleteLanguage: true,
                }}
              />
            </div>
          </>
        ) : (
          <Space direction="vertical" align="center" style={{ width: '100%' }}>
            <InboxOutlined style={{ fontSize: '32px', color: '#4a4a4a' }} />
            <Typography.Text style={{ textAlign: 'center', color: '#4a4a4a' }}>{t('usageHistories.noData')}</Typography.Text>
          </Space>
        )}
      </Spin>
    </Modal>
  );
};

export default UsageHistoryDetailModal;
