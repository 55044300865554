import { DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button, Card, Col, DatePicker, message, Modal, Row, Space, Spin, Typography
} from 'antd';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import {
  Controller, FormProvider, useFieldArray, useForm
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { QueryClient, useMutation, useQuery } from 'react-query';

import { DropdownLocation } from 'common/components/DropdownLocation';
import { DropdownElement } from 'common/components/DropdownType';
import Input from 'common/components/Input';
import { createProductPriceService, getProductCurrenciesService, updateProductPriceByIdService } from 'common/services/extends/products';
import { CreatePriceParams, ProductPriceDetailsData } from 'common/services/extends/products/types';
import {
  detectError
} from 'common/utils/functions';
import { productPriceFormSchema } from 'common/utils/schemas';

interface EditPricesModalProps {
  isEdit: boolean;
  productId: string | null;
  data?: ProductPriceDetailsData;
  open: boolean;
  onClose: () => void;
  queryClient: QueryClient;
}

type CreatePriceFormDataTypes = {
  countryData: Array<{
    country: OptionType;
    iosPrice: number;
    iosCurrency: OptionType;
    androidPrice: number;
    androidCurrency: OptionType;
  }>
  price: number;
  priceVND: number;
  appPrice: number;
  appPriceVND: number;
} & Pick<CreatePriceParams, 'startDate' | 'endDate' | 'name'>;

const EditPricesModal = ({
  isEdit,
  productId,
  data,
  open,
  onClose,
  queryClient
}: EditPricesModalProps) => {
  const { t } = useTranslation();

  /* FORMS */
  const method = useForm<CreatePriceFormDataTypes>({
    defaultValues: {
      name: '',
      startDate: '',
      endDate: '',
      countryData: [],
    },
    resolver: yupResolver(productPriceFormSchema),
    mode: 'onChange',
  });

  const { fields, append, remove } = useFieldArray({
    control: method.control,
    name: 'countryData',
  });

  /* QUERIES */

  const { data: productCurrenciesDataIos, isLoading: iosLoading } = useQuery(
    ['getProductCurrenciesIos'],
    () => getProductCurrenciesService({
      platform: 'ios',
    }),
  );

  const { data: productCurrenciesDataAndroid, isLoading: androidLoading } = useQuery(
    ['getProductCurrenciesAndroid'],
    () => getProductCurrenciesService({
      platform: 'android',
    }),
  );

  const { mutate: updatePriceMutate, isLoading: isUpdatePriceMutateLoading } = useMutation(
    ['updatePrice', productId, data?.productPriceData.id],
    async (payload: CreatePriceParams) => {
      if (productId && data?.productPriceData.id) {
        await updateProductPriceByIdService(productId, data?.productPriceData.id, payload);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getProductPriceDataById', { idParam: productId }]);
        onClose();
      },
      onError: (err) => {
        message.error(t('message.updateError'));
        if (err instanceof AxiosError) {
          if (Number(err.response?.status) === 404) {
            message.error(`${t('price.price')} ${t(detectError(Number(err.response?.status)))}`);
          } else {
            message.error(detectError(Number(err.response?.status)));
          }
        } else {
          (err as ErrorResponse[]).forEach((e) => method.setError(
            e.field as keyof CreatePriceFormDataTypes,
            { message: e.message }
          ));
        }
      }
    }
  );

  const { mutate: createPriceMutate, isLoading: isCreatePriceMutateLoading } = useMutation(
    ['createPrice', productId],
    async (payload: CreatePriceParams) => {
      if (productId) {
        await createProductPriceService(productId, payload);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getProductPriceDataById', { idParam: productId }]);
        onClose();
      },
    }
  );

  /* EFFECTS */
  // Initial values
  useEffect(() => {
    if (data?.productPriceData) {
      method.reset({
        name: data.productPriceData.name,
        startDate: data.productPriceData.startDate,
        endDate: data.productPriceData.endDate,
        price: data.productPriceData.price,
        priceVND: data.productPriceData.priceVND,
        appPrice: data.productPriceData.appPrice,
        appPriceVND: data.productPriceData.appPriceVND,
        countryData: data.countryData.map((item) => ({
          country: {
            value: item.id,
            label: item.name,
            currency: item.currency
          },
          iosPrice: item.iosPrice,
          androidPrice: item.androidPrice,
          iosCurrency: {
            value: item.iosCurrency,
            label: item.iosCurrency,
          },
          androidCurrency: {
            value: item.androidCurrency,
            label: item.androidCurrency,
          }
        })),
      });
    } else {
      method.reset({
        name: '',
        startDate: '',
        endDate: '',
        countryData: [],
      });
    }
  }, [data, method]);

  /* FUNCTIONS */
  const onSubmit = async () => {
    const isValid = await method.trigger();
    if (!isValid) return;

    const formData = method.getValues();
    const dataRequest: CreatePriceParams = {
      ...formData,
      appPrice: formData.appPrice,
      appPriceVND: formData.appPriceVND,
      price: formData.price,
      priceVND: formData.priceVND,
      countryData: formData.countryData.map((item) => ({
        countryId: Number(item.country.value),
        iosPrice: item.iosPrice,
        androidPrice: item.androidPrice,
        iosCurrency: String(item.iosCurrency.value),
        androidCurrency: String(item.androidCurrency.value),
      }))
    };
    if (isEdit) {
      updatePriceMutate(dataRequest);
    } else {
      createPriceMutate(dataRequest);
    }
  };

  return (
    <Modal
      key={isEdit ? 'editCard' : 'createCard'}
      title={<Typography.Title level={3}>{t('price.editPrice')}</Typography.Title>}
      open={open}
      onCancel={onClose}
      footer={null}
      width={1200}
    >
      <Spin
        spinning={
          isUpdatePriceMutateLoading
          || isCreatePriceMutateLoading
          || iosLoading
          || androidLoading
        }
      >
        <FormProvider {...method}>
          <Row gutter={16}>
            <Col xxl={24}>
              <Row gutter={16}>
                <Col span={24}>
                  <Typography.Text strong>
                    {t('price.uniqueName')}
                    {' '}
                  </Typography.Text>
                  <Typography.Text strong type="danger">
                    *
                  </Typography.Text>
                  <Controller
                    name="name"
                    defaultValue=""
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                      <Input
                        className="u-mt-8"
                        name="name"
                        placeholder={`${t('system.input')} ${t('price.uniqueName')}`}
                        value={value}
                        onChange={onChange}
                        error={error?.message}
                        size="large"
                      />
                  )}
                  />
                </Col>
              </Row>
              <Row gutter={16} className="u-mt-16">
                <Col span={12}>
                  <Typography.Text strong>
                    {t('price.startDate')}
                    {' '}
                  </Typography.Text>
                  <Typography.Text strong type="danger">
                    *
                  </Typography.Text>
                  <Controller
                    name="startDate"
                    defaultValue=""
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                      <Space style={{ display: 'block' }} className="u-mt-8">
                        <DatePicker
                          value={value ? dayjs(value) : null}
                          onChange={onChange}
                          style={{ width: '100%' }}
                          size="large"
                        />
                        {error && <span className="a-input_errorMessage">{error.message}</span>}
                      </Space>
                  )}
                  />
                </Col>
                <Col span={12}>
                  <Typography.Text strong>
                    {t('price.endDate')}
                    {' '}
                  </Typography.Text>
                  <Controller
                    name="endDate"
                    defaultValue=""
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                      <Space style={{ display: 'block' }} className="u-mt-8">
                        <DatePicker
                          value={value ? dayjs(value) : null}
                          onChange={onChange}
                          style={{ width: '100%' }}
                          size="large"
                        />
                        {error && <span className="a-input_errorMessage">{error.message}</span>}
                      </Space>
                  )}
                  />
                </Col>
              </Row>
              <Row gutter={16} className="u-mt-16">
                <Col span={12}>
                  <Typography.Text strong>
                    Web - Global($)
                    {' '}
                  </Typography.Text>
                  <Typography.Text strong type="danger">
                    *
                  </Typography.Text>
                  <Controller
                    name="price"
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                      <Input
                        className="u-mt-8"
                        name="price"
                        type="number"
                        placeholder={`${t('system.input')} ${t('price.globalPrice')}`}
                        value={value}
                        onChange={onChange}
                        error={error?.message}
                        size="large"
                      />
                  )}
                  />
                </Col>
                <Col span={12}>
                  <Typography.Text strong>
                    Web - VN(VND)
                    {' '}
                  </Typography.Text>
                  <Typography.Text strong type="danger">
                    *
                  </Typography.Text>
                  <Controller
                    name="priceVND"
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                      <Input
                        className="u-mt-8"
                        name="priceVND"
                        type="number"
                        placeholder={`${t('system.input')} ${t('price.vietnamesePrice')}`}
                        value={value}
                        onChange={onChange}
                        error={error?.message}
                        size="large"
                      />
                  )}
                  />
                </Col>
                <Col span={12} className="u-mt-16">
                  <Typography.Text strong>
                    App - Global($)
                    {' '}
                  </Typography.Text>
                  <Typography.Text strong type="danger">
                    *
                  </Typography.Text>
                  <Controller
                    name="appPrice"
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                      <Input
                        className="u-mt-8"
                        name="appPrice"
                        type="number"
                        placeholder={`${t('system.input')} ${t('price.appPrice')}`}
                        value={value}
                        onChange={onChange}
                        error={error?.message}
                        size="large"
                      />
                  )}
                  />
                </Col>
                <Col span={12} className="u-mt-16">
                  <Typography.Text strong>
                    App - VN(VND)
                    {' '}
                  </Typography.Text>
                  <Typography.Text strong type="danger">
                    *
                  </Typography.Text>
                  <Controller
                    name="appPriceVND"
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                      <Input
                        className="u-mt-8"
                        name="appPriceVND"
                        type="number"
                        placeholder={`${t('system.input')} ${t('price.appPriceVND')}`}
                        value={value}
                        onChange={onChange}
                        error={error?.message}
                        size="large"
                      />
                  )}
                  />
                </Col>
              </Row>
              <Row gutter={16} className="u-mt-16">
                <Col span={24}>
                  <Card
                    title={(
                      <Space direction="horizontal" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography.Title level={5}>
                          {t('price.pricePerCountry')}
                        </Typography.Title>
                        <Button type="primary" onClick={() => append({})}>
                          {t('price.customize')}
                        </Button>
                      </Space>
                  )}
                  >
                    <div className="u-mt-16">
                      {fields.map((item, index) => (
                        <Row gutter={16} className="u-mt-16" key={item.id}>
                          <Col span={7}>
                            <Typography.Text strong>
                              {t('price.country')}
                              {' '}
                            </Typography.Text>
                            <Typography.Text strong type="danger">
                              *
                            </Typography.Text>
                            <Controller
                              name={`countryData[${index}].country`}
                              render={({ field: { value, onChange }, fieldState: { error } }) => (
                                <>
                                  <DropdownLocation
                                    name={`countryData[${index}].country`}
                                    value={value}
                                    size="large"
                                    isGetOption
                                    onChange={onChange}
                                  />
                                  {error && <span className="a-input_errorMessage">{error.message}</span>}
                                </>
                            )}
                            />
                          </Col>
                          <Col span={4}>
                            <Typography.Text strong>
                              Currency IOS
                              {' '}
                            </Typography.Text>
                            <Typography.Text strong type="danger">
                              *
                            </Typography.Text>
                            <Controller
                              name={`countryData[${index}].iosCurrency`}
                              render={({ field: { value, onChange }, fieldState: { error } }) => (
                                <>
                                  <DropdownElement
                                    options={productCurrenciesDataIos}
                                    locale="vi"
                                    value={value}
                                    size="large"
                                    onChange={onChange}
                                    isGetOption
                                  />
                                  {error && <span className="a-input_errorMessage">{(error as any)?.value?.message}</span>}
                                </>
                              )}
                            />
                          </Col>
                          <Col span={4}>
                            <Typography.Text strong>
                              Amount IOS
                              {' '}
                            </Typography.Text>
                            <Typography.Text strong type="danger">
                              *
                            </Typography.Text>
                            <Controller
                              name={`countryData[${index}].iosPrice`}
                              defaultValue=""
                              render={({ field: { value, onChange }, fieldState: { error } }) => (
                                <Input
                                  type="number"
                                  className="u-mt-8"
                                  name={`countryData[${index}].iosPrice`}
                                  value={value}
                                  onChange={onChange}
                                  error={error?.message}
                                  size="large"
                                />
                            )}
                            />
                          </Col>
                          <Col span={4}>
                            <Typography.Text strong>
                              Currency Android
                              {' '}
                            </Typography.Text>
                            <Typography.Text strong type="danger">
                              *
                            </Typography.Text>
                            <Controller
                              name={`countryData[${index}].androidCurrency`}
                              render={({ field: { value, onChange }, fieldState: { error } }) => (
                                <>
                                  <DropdownElement
                                    options={productCurrenciesDataAndroid}
                                    locale="vi"
                                    value={value}
                                    size="large"
                                    onChange={onChange}
                                    isGetOption
                                  />
                                  {error && <span className="a-input_errorMessage">{(error as any)?.value?.message}</span>}
                                </>
                              )}
                            />
                          </Col>
                          <Col span={4}>
                            <Typography.Text strong>
                              Amount Android
                              {' '}
                            </Typography.Text>
                            <Typography.Text strong type="danger">
                              *
                            </Typography.Text>
                            <Controller
                              name={`countryData[${index}].androidPrice`}
                              defaultValue=""
                              render={({ field: { value, onChange }, fieldState: { error } }) => (
                                <Input
                                  type="number"
                                  className="u-mt-8"
                                  name={`countryData[${index}].androidPrice`}
                                  value={value}
                                  onChange={onChange}
                                  error={error?.message}
                                  size="large"
                                />
                            )}
                            />
                          </Col>
                          <Col span={1}>
                            <Button className="u-mt-28" type="text" icon={<DeleteOutlined />} onClick={() => remove(index)} />
                          </Col>
                        </Row>
                    ))}
                    </div>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          <div style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
          >
            <Button
              className="u-mt-16"
              type="primary"
              loading={isUpdatePriceMutateLoading || isCreatePriceMutateLoading}
              onClick={method.handleSubmit(onSubmit)}
            >
              <SaveOutlined />
              {t('system.save')}
            </Button>
          </div>
        </FormProvider>
      </Spin>
    </Modal>
  );
};

export default EditPricesModal;
