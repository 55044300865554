import {
  Card, Col, Row,
  Spin, Typography
} from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import HeaderPage from 'common/components/HeaderPage';
import { StatusOrderLabel } from 'common/components/StatusLabel';
import { getOrderByIdService } from 'common/services/extends/orders';
import { formatDateTime } from 'common/utils/functions';

const OrderDetail: React.FC<ActiveRoles> = ({
  roleIndex
}) => {
  /* Hooks */
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const idParams = Number(searchParams.get('id'));

  /* Queries */
  const { data: orderDataDetail, isLoading } = useQuery(
    ['getOrderByIdService', idParams],
    () => {
      if (idParams) {
        return getOrderByIdService(idParams);
      }
      return null;
    },
    {
      enabled: !!idParams && roleIndex,
    }
  );

  const handlePaymentMethod = (status?: number) => {
    switch (status) {
      case 1:
        return 'Paypal';
      case 2:
        return 'Apple';
      case 3:
        return 'Google';
      case 4:
        return 'Momo';
      default:
        return '';
    }
  };

  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.students')}
      />
      <Spin size="large" spinning={isLoading}>
        <div className="t-mainlayout_wrapper">
          <Row gutter={16}>
            <Col xxl={24}>
              <Card type="inner">
                <Row gutter={16}>
                  <Col xxl={12} xl={12} lg={12} sm={24} className="u-mb-12">
                    <Typography.Text strong>
                      ID
                      {': '}
                    </Typography.Text>
                    <Typography.Text>
                      {orderDataDetail?.orderData?.id}
                    </Typography.Text>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} sm={24} className="u-mb-12">
                    <Typography.Text strong>
                      {t('system.name')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>
                      {orderDataDetail?.orderData?.student.name || ''}
                    </Typography.Text>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} sm={24} className="u-mb-12">
                    <Typography.Text strong>
                      {t('system.email')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>
                      {orderDataDetail?.orderData?.student.email || ''}
                    </Typography.Text>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} sm={24} className="u-mb-12">
                    <Typography.Text strong>
                      {t('system.code')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>
                      {orderDataDetail?.orderData?.code || ''}
                    </Typography.Text>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} sm={24} className="u-mb-12" style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography.Text strong>
                      {t('system.status')}
                      {': '}
                       &nbsp;
                    </Typography.Text>
                    <StatusOrderLabel status={orderDataDetail?.orderData.status || 1} />
                  </Col>
                  <Col xxl={12} xl={12} lg={12} sm={24} className="u-mb-12" style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography.Text strong>
                      {t('order.paymentMethod')}
                      {': '}
                      &nbsp;
                    </Typography.Text>
                    <Typography.Text>
                      {handlePaymentMethod(orderDataDetail?.orderData.paymentMethod)}
                    </Typography.Text>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} sm={24} className="u-mb-12">
                    <Typography.Text strong>
                      {t('order.exchangeRate')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>
                      {orderDataDetail?.orderData?.exchangeRate}
                    </Typography.Text>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} sm={24} className="u-mb-12">
                    <Typography.Text strong>
                      {t('order.momoTransactionId')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>
                      {orderDataDetail?.orderData?.momoTransactionId}
                    </Typography.Text>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} sm={24} className="u-mb-12">
                    <Typography.Text strong>
                      {t('order.paypalOrderId')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>
                      {orderDataDetail?.orderData?.paypalOrderId}
                    </Typography.Text>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} sm={24} className="u-mb-12">
                    <Typography.Text strong>
                      {t('order.tax')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>
                      {orderDataDetail?.orderData?.tax}
                    </Typography.Text>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} sm={24} className="u-mb-12">
                    <Typography.Text strong>
                      {t('order.unitPrice')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>
                      {orderDataDetail?.orderData?.unitPrice}
                    </Typography.Text>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} sm={24} className="u-mb-12">
                    <Typography.Text strong>
                      {t('order.totalPrice')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>
                      {orderDataDetail?.orderData?.totalPrice}
                    </Typography.Text>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} sm={24} className="u-mb-12">
                    <Typography.Text strong>
                      {t('order.unitPriceDuration')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>
                      {orderDataDetail?.orderData?.unitPriceDuration}
                    </Typography.Text>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} sm={24} className="u-mb-12">
                    <Typography.Text strong>
                      {t('order.usageTime')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>
                      {orderDataDetail?.orderData?.usageTime}
                    </Typography.Text>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} sm={24} className="u-mb-12">
                    <Typography.Text strong>
                      {t('order.dateBilling')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>
                      {formatDateTime(orderDataDetail?.orderData?.dateBilling) || ''}
                    </Typography.Text>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} sm={24} className="u-mb-12">
                    <Typography.Text strong>
                      {t('system.createdAt')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>
                      {formatDateTime(orderDataDetail?.orderData?.createdAt) || ''}
                    </Typography.Text>
                  </Col>
                  <Col xxl={12} xl={12} lg={12} sm={24} className="u-mb-12">
                    <Typography.Text strong>
                      {t('system.updatedAt')}
                      {': '}
                    </Typography.Text>
                    <Typography.Text>
                      {formatDateTime(orderDataDetail?.orderData?.updatedAt) || ''}
                    </Typography.Text>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      </Spin>
    </>
  );
};

export default OrderDetail;
