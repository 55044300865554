import {
  DeleteOutlined,
  EditOutlined, EyeOutlined, PlusOutlined,
} from '@ant-design/icons';
import {
  Button, message, Modal, Space, Switch, Tooltip, Typography
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import DetailReedemModal from './DetailModal';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import {
 FilterValueProps, mappingFilterToQuery, mappingParamsFilter, mappingQueryParamsFilter
} from 'common/components/PageTable/filter';
import useDidMount from 'common/hooks/useDidMount';
import useNavigateParams from 'common/hooks/useNavigateParams';
import { changeRedeemStatusService, deleteRedeemService, getAllRedeemService } from 'common/services/extends/redeem';
import { RedeemDataTypes } from 'common/services/extends/redeem/types';
import { ROUTE_PATHS } from 'common/utils/constant';
import { formatDateTime } from 'common/utils/functions';

const RedeemManagement: React.FC<ActiveRoles> = ({
  roleCreate, roleUpdate, roleDelete, roleIndex
}) => {
  /* Hook */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navigateParams = useNavigateParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const params = useMemo(() => {
    const paramsObj = { ...Object.fromEntries(searchParams.entries()) };
    if (searchParams.has('page')) delete paramsObj.page;
    return paramsObj;
  }, [searchParams]);

  const pageParam = searchParams.get('page');

  /* Selectors */
  const { defaultPageSize } = useAppSelector((state) => state.system);

  /* States */
  const [currentPage, setCurrentPage] = useState(Number(pageParam));
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [keyword, setKeyword] = useState('');
  const [activeIdx, setActiveIdx] = useState<number>(-1);
  const [selectedFilterList, setSelectedFilterList] = useState<
    FilterValueProps[]>(mappingQueryParamsFilter(params));

  const queryKey = ['redeemManagement-list', currentPage, keyword, currentView];

  const returnParamsFilter = useMemo(
    () => mappingParamsFilter(selectedFilterList),
    [selectedFilterList]
  );

  /* React-query */
  const {
    isFetching: listLoading,
    data: listData,
  } = useQuery(
    queryKey,
    () => getAllRedeemService({
      page: currentPage,
      keyword,
      limit: currentView,
      ...returnParamsFilter
    }),
    {
      enabled: !!currentPage,
    }
  );

  const { mutate: deleteMutate, isLoading: deleteLoading } = useMutation(
    ['deleteReedemMutate'],
    async (ids: number[]) => deleteRedeemService(ids),
    {
      onSuccess: () => {
        message.success(t('message.deleteSuccess'));
        queryClient.invalidateQueries(queryKey);
      },
      onError: (errors: ErrorResponse[]) => {
        if (errors.length > 0) {
          errors.forEach((error: ErrorResponse) => {
            message.error(error?.message || '');
          });
        } else {
          message.error(t('message.deleteError'));
        }
      },
    }
  );

  const { mutate: changeStatusRedeemMutate, isLoading: changeStatusLoading } = useMutation(
    ['changeStatusRedeemMutate'],
    async (data:
      {
        id: number,
        status: number
      }) => changeRedeemStatusService(data.id, data.status),
    {
      onSuccess: () => {
        message.success(t('message.updateSuccess'));
        queryClient.invalidateQueries(queryKey);
      },
      onError: (errors: ErrorResponse[]) => {
        if (errors.length > 0) {
          errors.forEach((error: ErrorResponse) => {
            message.error(error?.message || '');
          });
        } else {
          message.error(t('message.updateError'));
        }
      }
    }
  );

  /* Functions */
  const handleSearch = (val: string) => {
    setKeyword(val);
  };

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ page: page.toString() }, { replace: true });
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  const handleDeleteFilter = (key: string, index?: number) => {
    const tempList = selectedFilterList.slice();
    setSelectedFilterList(tempList.filter((item) => !(item.key === key && item.index === index)));
  };

  const handleFilter = (data: FilterValueProps) => {
    const typeFilter = String(data.filter).split('.')[1];
    if ((typeFilter === 'isNull' || typeFilter === 'isNotNull') && selectedFilterList.find((item) => item.key === data.key)) {
      return;
    }
    const counter = selectedFilterList.filter(
      (item) => item.field === data.field && item.filter === data.filter
    ).length;
    setSelectedFilterList([...selectedFilterList, { ...data, index: counter }]);
  };

  /* Datas */
  const columns: ColumnsType<RedeemDataTypes> = useMemo(() => ([
    // --- ID
    {
      title: 'ID',
      key: 'id',
      width: 55,
      align: 'center',
      render: (_name: string, data: RedeemDataTypes) => (
        <Typography.Text>
          {data.redeemData.id}
        </Typography.Text>
      ),
    },
    // --- Tên
    {
      title: t('redeem.campaign'),
      dataIndex: 'name',
      key: 'name',
      width: 300,
      render: (_name: string, data: RedeemDataTypes) => (
        <Typography.Text>
          {data.redeemData.name}
        </Typography.Text>
      ),
    },
    // --- Email
    {
      title: t('redeem.type'),
      dataIndex: 'type',
      key: 'type',
      width: 240,
      render: (_name: string, data: RedeemDataTypes) => (
        <Typography.Text>
          {data.redeemData.type === 1 ? t('redeem.singleUse') : t('redeem.multiUses')}
        </Typography.Text>
      )
    },
    {
      title: t('redeem.active'),
      key: 'status',
      width: 240,
      align: 'center',
      render: (_name: string, data: RedeemDataTypes) => (
        <Switch
          disabled={changeStatusLoading}
          checked={data.redeemData.status === 1}
          onChange={() => changeStatusRedeemMutate({
            id: data.redeemData.id,
            status: data.redeemData.status === 1 ? 0 : 1
          })}
        />
      ),
    },
    {
      title: t('redeem.startDate'),
      dataIndex: 'startDate',
      key: 'startDate',
      width: 150,
      render: (_name: string, data: RedeemDataTypes) => (
        <Typography.Text>
          {formatDateTime(data.redeemData?.startDate)}
        </Typography.Text>
      )
    },
    {
      title: t('redeem.endDate'),
      dataIndex: 'endDate',
      key: 'endDate',
      width: 150,
      render: (_name: string, data: RedeemDataTypes) => (
        <Typography.Text>
          {formatDateTime(data.redeemData?.endDate || '')}
        </Typography.Text>
      ),
    },
    {
      title: t('redeem.quantityCode'),
      dataIndex: 'quantity',
      key: 'quantity',
      width: 100,
      render: (_name: string, data: RedeemDataTypes) => (
        <Typography.Text>
          {data.redeemData?.quantity}
        </Typography.Text>
      ),
    },
    {
      title: t('usageHistories.quantityUsed'),
      dataIndex: 'quantityUsed',
      key: 'quantityUsed',
      width: 100,
      render: (_name: string, data: RedeemDataTypes) => (
        <Typography.Text>
          {data.redeemData?.quantityUsed}
        </Typography.Text>
      ),
    },
    {
      title: t('redeem.product'),
      dataIndex: 'product',
      key: 'product',
      width: 150,
      render: (_name: string, data: RedeemDataTypes) => (
        <Typography.Text>
          {data.redeemData?.product?.name}
        </Typography.Text>
      ),
    },
    // --- Tạo lúc
    {
      title: t('system.createdAt'),
      dataIndex: 'createdAt',
      width: 250,
      key: 'createdAt',
      render: (_name: string, data: RedeemDataTypes) => (
        <Typography.Text>
          {formatDateTime(data.redeemData?.createdAt || '')}
        </Typography.Text>
      ),
    },
    // --- Cập nhật
    {
      title: t('system.updatedAt'),
      dataIndex: 'updatedAt',
      width: 250,
      key: 'updatedAt',
      render: (_name: string, data: RedeemDataTypes) => (
        <Typography.Text>
          {formatDateTime(data.redeemData?.updatedAt || '')}
        </Typography.Text>
      ),
    },
    // --- Thao tác
    {
      title: t('system.action'),
      key: 'action',
      width: 100,
      align: 'center',
      render: (_name: string, _data: RedeemDataTypes) => (
        <Space>
          <Tooltip title="Edit">
            <Button
              disabled={!roleUpdate}
              icon={<EditOutlined />}
              onClick={() => navigateParams(ROUTE_PATHS.REDEEM_DETAIL, `id=${_data.redeemData.id}`)}
            />
          </Tooltip>
          <Tooltip title="View">
            <Button
              disabled={!roleIndex}
              icon={<EyeOutlined />}
              onClick={() => setActiveIdx(_data.redeemData.id)}
            />
          </Tooltip>
          <Tooltip title="Delete">
            <Button
              disabled={!roleDelete || deleteLoading}
              loading={deleteLoading}
              icon={<DeleteOutlined />}
              onClick={() => {
                Modal.confirm({
                  className: 't-pagetable_deleteRecordModal',
                  autoFocusButton: 'cancel',
                  okText: t('system.ok'),
                  cancelText: t('system.cancel'),
                  cancelButtonProps: {
                    type: 'primary',
                  },
                  okButtonProps: {
                    type: 'default',
                  },
                  title: t('message.confirmDeleteRecord'),
                  onOk: () => {
                    deleteMutate([_data.redeemData.id]);
                  },
                });
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ]), [t, roleUpdate, roleDelete]);

  const tableData: RedeemDataTypes[] = useMemo(() => listData?.data || [], [listData]);

  /* Effects */
  useEffect(() => {
    setCurrentPage(pageParam ? Number(pageParam) : 1);
    setSearchParams({
      ...mappingFilterToQuery(selectedFilterList),
      page: pageParam || '1'
    }, { replace: true });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword, setSearchParams, selectedFilterList]);

  useDidMount(() => {
    if (pageParam) return setCurrentPage(Number(pageParam));
    return setCurrentPage(Number(pageParam) || 1);
  });

  return (
    <>
      <HeaderPage
        fixed
        title="Activation Code"
        rightHeader={(
          <Space>
            <Button
              disabled={!roleCreate}
              type="primary"
              onClick={() => navigate(ROUTE_PATHS.REDEEM_DETAIL)}
            >
              <PlusOutlined />
              {t('system.create')}
            </Button>
          </Space>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          isLoading={listLoading || deleteLoading || changeStatusLoading}
          handleSearch={handleSearch}
          noCheckbox
          tableProps={{
            initShowColumns: ['id', 'name', 'type', 'status', 'startDate', 'endDate', 'quantity', 'quantityUsed', 'product', 'action'],
            columns,
            pageData: tableData,
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: listData?.meta.total || 1,
            noBaseCol: true,
            noDeleteLanguage: true,
          }}
          filtersDataTable={{
            handleFilter,
            selectedFilterList,
            handleDeleteFilter
          }}
        />
      </div>
      <DetailReedemModal
        id={activeIdx}
        handleClose={() => setActiveIdx(-1)}
      />
    </>
  );
};

export default RedeemManagement;
