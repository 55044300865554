import { CheckSquareFilled, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  message,
  Space,
  Typography
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import {
  FilterValueProps,
  mappingFilterToQuery, mappingParamsFilter, mappingQueryParamsFilter
} from 'common/components/PageTable/filter';
import useDidMount from 'common/hooks/useDidMount';
import useNavigateParams from 'common/hooks/useNavigateParams';
import { deleteStudentRoleService, deleteStudentRoleTranslationService, getAllStudentRoleService } from 'common/services/extends/studentRole';
import { StudentRoleTypes } from 'common/services/extends/studentRole/types';
import { ROUTE_PATHS } from 'common/utils/constant';
import { formatDateTime } from 'common/utils/functions';

export interface StudentRoleData {
  id: number,
  name: string,
  displayOrder: number,
  active: boolean,
  createdAt: string,
  updatedAt: string,
  locale: StudentRoleTypes['translations'];
}

const StudentRoleManagement: React.FC<ActiveRoles> = ({ roleCreate, roleUpdate, roleDelete }) => {
  /* Hooks */
  const { t } = useTranslation();
  const navigation = useNavigate();
  const queryClient = useQueryClient();
  const navigateParams = useNavigateParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => {
    const paramsObj = { ...Object.fromEntries(searchParams.entries()) };
    if (searchParams.has('page')) delete paramsObj.page;
    return paramsObj;
  }, [searchParams]);

  const pageParam = searchParams.get('page');

  /* Selectors */
  const { system: { defaultPageSize } } = useAppSelector((state) => state);

  /* State */
  const [currentPage, setCurrentPage] = useState(Number(pageParam));
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [keyword, setKeyword] = useState('');
  const [selectedFilterList] = useState<
    FilterValueProps[]>(mappingQueryParamsFilter(params));

  /* Variables */
  const returnParamsFilter = useMemo(
    () => mappingParamsFilter(selectedFilterList),
    [selectedFilterList]
  );

  const queryKey = ['getStudentRoleService', keyword, currentPage, currentView, selectedFilterList];

  /* Queries */
  const { data: studentRoleData, isLoading } = useQuery(
    queryKey,
    () => getAllStudentRoleService({
      keyword,
      page: currentPage,
      limit: currentView,
      ...returnParamsFilter,
    }),
    {
      enabled: !!currentPage,
    }
  );

  const { mutate: deleteMutate, isLoading: deleteLoading } = useMutation(
    ['deleteStudentRoleMutate'],
    async (ids: number[]) => deleteStudentRoleService(ids),
    {
      onSuccess: () => {
        message.success(t('message.deleteSuccess'));
        queryClient.invalidateQueries(queryKey);
      },
      onError: () => {
        message.error(t('message.deleteError'));
      }
    }
  );

  const {
    mutate: deleteStudentRoleTranslationMutate,
    isLoading: deleteStudentRoleTranslationLoading
  } = useMutation(
    ['deleteStudentRoleTranslationMutate'],
    async (ids: number[]) => deleteStudentRoleTranslationService({ ids }),
    {
      onSuccess: () => {
        message.success(t('message.deleteSuccess'));
        queryClient.invalidateQueries(queryKey);
      },
      onError: () => {
        message.error(t('message.deleteError'));
      }
    }
  );

  const tableData: StudentRoleData[] = useMemo(() => (
    studentRoleData?.data.map((val) => ({
      id: val.studentRoleData?.id,
      name: val.translations.vi ? val.translations.vi.name : val.translations.en.name,
      displayOrder: val.studentRoleData?.displayOrder,
      active: val.studentRoleData?.active,
      createdAt: val.studentRoleData?.createdAt || '',
      updatedAt: val.studentRoleData?.updatedAt || '',
      locale: Object.fromEntries(
        Object.entries(val.translations)
          .map(([k, o]) => [k, { ...o, id: val.translations[k].id },
          ])
      ),
  })) || []), [studentRoleData]);

  const columns: ColumnsType<StudentRoleData> = [
    {
      title: 'ID',
      key: 'id',
      width: 55,
      align: 'center',
      fixed: 'left',
      render: (_name: string, data: StudentRoleData) => (
        <Typography.Text>
          {data.id || ''}
        </Typography.Text>
      ),
    },
    {
      title: t('system.name'),
      key: 'name',
      render: (_name: string, data: StudentRoleData) => (
        <Typography.Text>
          {data.name || ''}
        </Typography.Text>
      ),
    },
    {
      title: t('product.displayOrder'),
      key: 'displayOrder',
      render: (_name: string, data: StudentRoleData) => (
        <Typography.Text>
          {data.displayOrder}
        </Typography.Text>
      ),
    },
    {
      title: t('onboarding.active'),
      key: 'active',
      render: (_name: string, data: StudentRoleData) => (
        <Typography.Text>
          {data.active ? <CheckSquareFilled style={{ fontSize: '16px', color: '#52c41a' }} /> : null}
        </Typography.Text>
      ),
    },
    {
      title: t('system.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_name: string, data: StudentRoleData) => (
        <Typography.Text>
          {formatDateTime(data.createdAt || '')}
        </Typography.Text>
      ),
    },
    {
      title: t('system.updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (_name: string, data: StudentRoleData) => (
        <Typography.Text>
          {formatDateTime(data.updatedAt || '')}
        </Typography.Text>
      ),
    }
  ];

  // Change page view
  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ page: page.toString() }, { replace: true });
  };

  // Change page size
  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  const onDelete = async (data: StudentRoleData[], code?: string) => {
    switch (code) {
      case 'allRow':
      case 'all': {
        deleteMutate(data.map((item) => item.id));
        break;
      }
      default: {
        if (code) {
          const localeId = data?.[0].locale[code]?.id;
          if (localeId) {
            deleteStudentRoleTranslationMutate([localeId]);
          }
        }
        break;
      }
    }
  };

  useEffect(() => {
    setCurrentPage(pageParam ? Number(pageParam) : 1);
    setSearchParams({
      ...mappingFilterToQuery(selectedFilterList),
      page: pageParam || '1'
    }, { replace: true });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword, setSearchParams, selectedFilterList]);

  useDidMount(() => {
    if (pageParam) return setCurrentPage(Number(pageParam));
    return setCurrentPage(Number(pageParam) || 1);
  });

  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.studentRole')}
        rightHeader={(
          <Space>
            <Button
              disabled={!roleCreate}
              type="primary"
              onClick={() => {
                navigation(ROUTE_PATHS.STUDENTS_ROLE_DETAIL);
              }}
            >
              <PlusOutlined />
              {t('system.create')}
            </Button>
          </Space>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          handleDelete={onDelete}
          isLoading={isLoading || deleteLoading || deleteStudentRoleTranslationLoading}
          handleEditPage={(id, _, language) => navigateParams(ROUTE_PATHS.STUDENTS_ROLE_DETAIL, `id=${id}&locale=${language}`)}
          handleCreatePage={(id, _, language) => navigateParams(ROUTE_PATHS.STUDENTS_ROLE_DETAIL, `id=${id}&locale=${language}`)}
          handleSearch={setKeyword}
          tableProps={{
            initShowColumns: ['id', 'name', 'displayOrder', 'active', 'createdAt', 'updatedAt'],
            columns,
            pageData: tableData || [],
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: studentRoleData?.meta.total || 1,
          }}
          roles={{ roleCreate, roleUpdate, roleDelete }}
          filtersDataTable={{
            selectedFilterList,
          }}
        />
      </div>
    </>
  );
};

export default StudentRoleManagement;
