import {
  EditOutlined
} from '@ant-design/icons';
import {
  Button, Space, Typography
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import useNavigateParams from 'common/hooks/useNavigateParams';
import { getAllFunctionalsService } from 'common/services/extends/functional';
import { FunctionalsDataTypes } from 'common/services/extends/functional/types';
import { ROUTE_PATHS } from 'common/utils/constant';
import { formatDateTime } from 'common/utils/functions';

const FunctionalManagement: React.FC<ActiveRoles> = ({
  roleUpdate
}) => {
  /* Hook */
  const { t } = useTranslation();
  const navigateParams = useNavigateParams();

  /* Selectors */
  const { defaultPageSize, defaultWebsiteLanguage } = useAppSelector((state) => state.system);

  /* States */
  const [currentPage, setCurrentPage] = useState(1);
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [keyword, setKeyword] = useState('');

  /* React-query */
  const {
    isFetching: listLoading,
    data: listData,
  } = useQuery(
    ['functionalManagement-list', currentPage, keyword, currentView],
    () => getAllFunctionalsService({ page: currentPage, keyword, limit: currentView }),
  );

  /* Functions */
  const handleSearch = (val: string) => {
    setKeyword(val);
  };

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  /* Datas */
  const columns: ColumnsType<FunctionalsDataTypes> = useMemo(() => ([
    // --- ID
    {
      title: 'ID',
      key: 'id',
      width: 55,
      align: 'center',
      render: (_name: string, data: FunctionalsDataTypes) => (
        <Typography.Text>
          {data.functionalData.id}
        </Typography.Text>
      ),
    },
    // --- Tên
    {
      title: t('functional.name'),
      dataIndex: 'name',
      key: 'name',
      width: 200,
      render: (_name: string, data: FunctionalsDataTypes) => (
        <Typography.Text>
          {defaultWebsiteLanguage ? data.translations[defaultWebsiteLanguage]?.name : ''}
        </Typography.Text>
      ),
    },
    {
      title: t('functional.title1'),
      dataIndex: 'title1',
      key: 'title1',
      width: 200,
      render: (_name: string, data: FunctionalsDataTypes) => (
        <Typography.Text>
          {defaultWebsiteLanguage ? data.translations[defaultWebsiteLanguage]?.title1 : ''}
        </Typography.Text>
      ),
    },
    {
      title: t('functional.title2'),
      dataIndex: 'title2',
      key: 'title2',
      width: 200,
      render: (_name: string, data: FunctionalsDataTypes) => (
        <Typography.Text>
          {defaultWebsiteLanguage ? data.translations[defaultWebsiteLanguage]?.title2 : ''}
        </Typography.Text>
      ),
    },
    {
      title: t('functional.description1'),
      dataIndex: 'description1',
      key: 'description1',
      width: 200,
      render: (_name: string, data: FunctionalsDataTypes) => (
        <Typography.Text>
          {defaultWebsiteLanguage ? data.translations[defaultWebsiteLanguage]?.description1 : ''}
        </Typography.Text>
      ),
    },
    {
      title: t('functional.description2'),
      dataIndex: 'description2',
      key: 'description2',
      width: 200,
      render: (_name: string, data: FunctionalsDataTypes) => (
        <Typography.Text>
          {defaultWebsiteLanguage ? data.translations[defaultWebsiteLanguage]?.description2 : ''}
        </Typography.Text>
      ),
    },
    {
      title: t('functional.code'),
      dataIndex: 'code',
      key: 'code',
      width: 200,
      render: (_name: string, data: FunctionalsDataTypes) => (
        <Typography.Text>
          {data.functionalData.code}
        </Typography.Text>
      )
    },
    {
      title: t('functional.statusFunctional'),
      dataIndex: 'status',
      key: 'status',
      width: 200,
      render: (_name: string, data: FunctionalsDataTypes) => (
        <Typography.Text>
          {data.functionalData.active ? t('functional.active') : t('functional.inactive')}
        </Typography.Text>
      )
    },
    // --- Tạo lúc
    {
      title: t('system.createdAt'),
      dataIndex: 'createdAt',
      width: 250,
      key: 'createdAt',
      render: (_name: string, data: FunctionalsDataTypes) => (
        <Typography.Text>
          {formatDateTime(data.functionalData?.createdAt || '')}
        </Typography.Text>
      ),
    },
    // --- Cập nhật
    {
      title: t('system.updatedAt'),
      dataIndex: 'updatedAt',
      width: 250,
      key: 'updatedAt',
      render: (_name: string, data: FunctionalsDataTypes) => (
        <Typography.Text>
          {formatDateTime(data.functionalData?.updatedAt || '')}
        </Typography.Text>
      ),
    },
    // --- Thao tác
    {
      title: t('system.action'),
      key: 'action',
      width: 100,
      align: 'center',
      render: (_name: string, _data: FunctionalsDataTypes) => (
        <Space>
          <Button
            disabled={!roleUpdate}
            icon={<EditOutlined />}
            onClick={() => navigateParams(ROUTE_PATHS.FUNCTIONAL_DETAIL, `id=${_data.functionalData.id}`)}
          />
        </Space>
      ),
    },
  ]), [t, roleUpdate, navigateParams]);

  const tableData: FunctionalsDataTypes[] = useMemo(() => listData?.data || [], [listData]);

  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.functional')}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          isLoading={listLoading}
          handleSearch={handleSearch}
          noCheckbox
          tableProps={{
            initShowColumns: ['id', 'name', 'title1', 'title2', 'description1', 'description2', 'code', 'status', 'action'],
            columns,
            pageData: tableData,
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: listData?.meta.total || 1,
            noBaseCol: true,
            noDeleteLanguage: true,
          }}
        />
      </div>
    </>
  );
};

export default FunctionalManagement;
