import { EyeOutlined } from '@ant-design/icons';
import {
  Button,
  Space,
  Typography
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import useDidMount from 'common/hooks/useDidMount';
import { getAllAiExerciesService } from 'common/services/extends/aiExercises';
import { ROUTE_PATHS } from 'common/utils/constant';

export interface AiExerciesData {
  id: number,
  source: string,
  aiSubjectId: number,
  aiSubjectName: string,
}

const AiExerciesManagement: React.FC<ActiveRoles> = () => {
  /* Hooks */
  const { t } = useTranslation();
  const navigation = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const pageParam = searchParams.get('page');

  /* Selectors */
  const { system: { defaultPageSize } } = useAppSelector((state) => state);

  /* State */
  const [currentPage, setCurrentPage] = useState(Number(pageParam));
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [keyword, setKeyword] = useState('');

  /* Queries */
  const { data: aiExerciesData, isLoading } = useQuery(
    ['getAiExerciesService', keyword, currentPage, currentView],
    () => getAllAiExerciesService({
      keyword,
      page: currentPage,
      limit: currentView,
    }),
    {
      enabled: !!currentPage,
    }
  );

  const tableData: AiExerciesData[] = useMemo(() => (
    aiExerciesData?.data.map((val) => ({
      id: val.sourceData.id,
      source: val.sourceData?.source || '',
      aiSubjectId: val.sourceData?.aiSubject.id,
      aiSubjectName: val.sourceData?.aiSubject.name || '',
    })) || []), [aiExerciesData]);

  const columns: ColumnsType<AiExerciesData> = [
    {
      title: 'ID',
      key: 'id',
      width: 85,
      align: 'center',
      fixed: 'left',
      render: (_name: string, data: AiExerciesData) => (
        <Typography.Text>
          {data.id || ''}
        </Typography.Text>
      ),
    },
    {
      title: t('aiExercises.source'),
      key: 'source',
      render: (_name: string, data: AiExerciesData) => (
        <Typography.Text>
          {data.source || ''}
        </Typography.Text>
      ),
    },
    {
      title: t('aiExercises.aiSubjectId'),
      key: 'aiSubjectId',
      render: (_name: string, data: AiExerciesData) => (
        <Typography.Text>
          {data.aiSubjectId}
        </Typography.Text>
      ),
    },
    {
      title: t('aiExercises.aiSubjectName'),
      key: 'aiSubjectName',
      render: (_name: string, data: AiExerciesData) => (
        <Typography.Text>
          {data.aiSubjectName}
        </Typography.Text>
      ),
    },
    {
      title: t('system.action'),
      key: 'action',
      width: 100,
      align: 'center',
      render: (_name: string, data: AiExerciesData) => (
        <Space>
          <Button
            icon={<EyeOutlined />}
            onClick={() => {
              navigation(`${ROUTE_PATHS.AI_EXERCIES_DETAIL}?id=${data.id}&source=${data.source}&page=1`);
            }}
          />
        </Space>
      ),
    },
  ];

  // Change page view
  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ page: page.toString() }, { replace: true });
  };

  // Change page size
  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  /* Effects */
  useEffect(() => {
    setCurrentPage(pageParam ? Number(pageParam) : 1);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword, setSearchParams]);

  useDidMount(() => {
    if (pageParam) return setCurrentPage(Number(pageParam));
    return setCurrentPage(Number(pageParam) || 1);
  });

  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title="AI Exercises"
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          isLoading={isLoading}
          handleSearch={setKeyword}
          noCheckbox
          tableProps={{
            initShowColumns: ['id', 'source', 'aiSubjectId', 'aiSubjectName', 'action'],
            columns,
            pageData: tableData || [],
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: aiExerciesData?.meta.total ?? 1,
            noBaseCol: true,
            noDeleteLanguage: true,
          }}
        />
      </div>
    </>
  );
};

export default AiExerciesManagement;
