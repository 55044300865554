import {
  Image
} from 'antd';
import Sider from 'antd/lib/layout/Sider';
import Layout from 'antd/lib/layout/layout';
import React, { useContext } from 'react';
import { Outlet, Link } from 'react-router-dom';

import { LayoutContext } from './context';

import IconLogo from 'common/assets/images/logo_3fc.svg';
import Sidebar, { MenuItem } from 'common/components/Sidebar';

type Props = {
  menus: MenuItem[];
};

const Mainlayout: React.FC<Props> = ({ menus }) => {
  const { collapsed, setCollapsed } = useContext(LayoutContext);

  return (
    <Layout>
      <Sider width={collapsed ? 80 : 250}>
        <Link to="/" className="t-mainlayout_sidebar_header">
          <Image width={collapsed ? 46 : 120} src={IconLogo} preview={false} />
        </Link>
        <div className="t-mainlayout_sidebar_menu">
          <Sidebar
            menuItems={menus}
            collapsed={collapsed}
            handleCollapsed={setCollapsed}
          />
        </div>
      </Sider>
      <Layout className="t-mainlayout_content">
        <Outlet />
      </Layout>
    </Layout>
  );
};

export default Mainlayout;
