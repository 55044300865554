import {
  Col,
  Modal,
  Row,
  Spin,
  Typography
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { ColumnsTypes } from './detail';

import { useAppSelector } from 'app/store';
import PageTable from 'common/components/PageTable';
import { getRedeemService } from 'common/services/extends/redeem';
import { formatDateTime } from 'common/utils/functions';

export interface DetailReedemForm {
  id: number;
  handleClose: () => void;
}

const DetailReedemModal: React.FC<DetailReedemForm> = ({
  id, handleClose
}) => {
  /* Hooks */
  const { t } = useTranslation();
  const { defaultPageSize } = useAppSelector((state) => state.system);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentView, setCurrentView] = useState(defaultPageSize);

  /* React-query */
  const { data: redeemData, isLoading: redeemDataLoading } = useQuery(
    ['getRedeemDataId', id],
    () => {
      if (id) {
        return getRedeemService(String(id));
      }
      return undefined;
    },
    {
      enabled: id > -1
    }
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleStatus = (status: number) => {
    switch (status) {
      case 0:
        return t('redeem.notStartedYet');
      case 1:
        return t('redeem.active');
      case 2:
        return t('redeem.expired');
      case 3:
        return t('redeem.redeemed');
      case 4:
        return t('redeem.revoked');
      default:
        return '';
    }
  };

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  const columns: ColumnsType<ColumnsTypes> = useMemo(() => ([
    // --- ID
    {
      title: 'ID',
      key: 'id',
      width: 55,
      align: 'center',
      render: (_name: string, data: ColumnsTypes) => (
        <Typography.Text>
          {data.studentId}
        </Typography.Text>
      ),
    },
    // --- Tên
    {
      title: t('system.name'),
      dataIndex: 'name',
      key: 'name',
      width: 100,
      render: (_name: string, data: ColumnsTypes) => (
        <Typography.Text>
          {data.studentName}
        </Typography.Text>
      ),
    },
    {
      title: t('system.email'),
      dataIndex: 'email',
      key: 'email',
      width: 160,
      render: (_name: string, data: ColumnsTypes) => (
        <Typography.Text>
          {data.studentEmail}
        </Typography.Text>
      ),
    },
    {
      title: t('system.code'),
      dataIndex: 'code',
      key: 'code',
      width: 100,
      render: (_name: string, data: ColumnsTypes) => (
        <Typography.Text>
          {data.code}
        </Typography.Text>
      ),
    },
    {
      title: t('system.status'),
      dataIndex: 'status',
      key: 'status',
      width: 60,
      render: (_name: string, data: ColumnsTypes) => (
        <Typography.Text>
          {handleStatus(data.status)}
        </Typography.Text>
      )
    },
    {
      title: t('redeem.redeemedDate'),
      dataIndex: 'redeemedDate',
      width: 160,
      key: 'redeemedDate',
      render: (_name: string, data: ColumnsTypes) => (
        <Typography.Text>
          {formatDateTime(data.redeemedDate || '')}
        </Typography.Text>
      ),
    },
  ]), [handleStatus, t]);

  const tableData: ColumnsTypes[] = useMemo(() => (
    redeemData?.redeemData.redeemCodes.map((val) => ({
      studentId: val?.id,
      code: val?.code || '',
      studentName: val?.student?.name || '',
      studentEmail: val?.student?.email || '',
      redeemedDate: val?.redeemedDate || '',
      status: val?.status,
  })) || []), [redeemData]);

  return (
    <Modal
      title={<Typography.Title level={3}>Activation Code Detail</Typography.Title>}
      open={id > -1}
      centered
      onCancel={handleClose}
      width={1200}
      footer={null}
    >
      <Spin spinning={redeemDataLoading}>
        <div>
          <Row gutter={16}>
            <Col xxl={24}>
              <Row gutter={16}>
                <Col span={12}>
                  <div className="u-mt-8">
                    <Typography.Text strong>
                      ID:
                      {' '}
                    </Typography.Text>
                    <Typography.Text>
                      {redeemData?.redeemData.id}
                    </Typography.Text>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="u-mt-8">
                    <Typography.Text strong>
                      {t('system.name')}
                      :
                      {' '}
                    </Typography.Text>
                    <Typography.Text>
                      {redeemData?.redeemData.name || ''}
                    </Typography.Text>
                  </div>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <div className="u-mt-8">
                    <Typography.Text strong>
                      {t('redeem.product')}
                      :
                      {' '}
                    </Typography.Text>
                    <Typography.Text>
                      {redeemData?.redeemData.product?.name || ''}
                    </Typography.Text>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="u-mt-8">
                    <Typography.Text strong>
                      {t('redeem.type')}
                      :
                      {' '}
                    </Typography.Text>
                    <Typography.Text>
                      {redeemData?.redeemData.type === 1 ? t('redeem.singleUse') : t('redeem.multiUses')}
                    </Typography.Text>
                  </div>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <div className="u-mt-8">
                    <Typography.Text strong>
                      {t('redeem.quantityCode')}
                      :
                      {' '}
                    </Typography.Text>
                    <Typography.Text>
                      {redeemData?.redeemData.quantity}
                    </Typography.Text>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="u-mt-8">
                    <Typography.Text strong>
                      {t('system.status')}
                      :
                      {' '}
                    </Typography.Text>
                    {redeemData?.redeemData.status ? t('redeem.active') : t('redeem.inactive')}
                  </div>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <div className="u-mt-8">
                    <Typography.Text strong>
                      {t('redeem.startDate')}
                      :
                      {' '}
                    </Typography.Text>
                    <Typography.Text>
                      {formatDateTime(redeemData?.redeemData.startDate || '')}
                    </Typography.Text>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="u-mt-8">
                    <Typography.Text strong>
                      {t('redeem.endDate')}
                      :
                      {' '}
                    </Typography.Text>
                    <Typography.Text>
                      {formatDateTime(redeemData?.redeemData.endDate || '')}
                    </Typography.Text>
                  </div>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <div className="u-mt-8">
                    <Typography.Text strong>
                      {t('system.createdAt')}
                      :
                      {' '}
                    </Typography.Text>
                    <Typography.Text>
                      {formatDateTime(redeemData?.redeemData.createdAt || '')}
                    </Typography.Text>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="u-mt-8">
                    <Typography.Text strong>
                      {t('system.updatedAt')}
                      :
                      {' '}
                    </Typography.Text>
                    <Typography.Text>
                      {formatDateTime(redeemData?.redeemData.updatedAt || '')}
                    </Typography.Text>
                  </div>
                </Col>
              </Row>
              {redeemData?.redeemData.code && (
                <Row gutter={16}>
                  <Col span={12}>
                    <div className="u-mt-8">
                      <Typography.Text strong>
                        {t('system.code')}
                        :
                        {' '}
                      </Typography.Text>
                      <Typography.Text>
                        {redeemData?.redeemData.code}
                      </Typography.Text>
                    </div>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </div>
        <div className="u-mt-8">
          <PageTable
            isLoading={redeemDataLoading}
            noCheckbox
            tableProps={{
            initShowColumns: ['id', 'name', 'email', 'code', 'status', 'redeemedDate'],
            columns,
            pageData: tableData,
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: (currentView && tableData.length > 0)
            ? Math.floor(tableData.length / currentView) + 1 : 1,
            noBaseCol: true,
            noDeleteLanguage: true,
            }}
          />
        </div>
      </Spin>
    </Modal>
  );
};

export default DetailReedemModal;
