import { EyeOutlined } from '@ant-design/icons';
import { Button, Space, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

// eslint-disable-next-line import/no-cycle
import DetailAIExerciseModal from './detailModal';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import useDidMount from 'common/hooks/useDidMount';
import { getAiExerciesService } from 'common/services/extends/aiExercises';

export interface AiExerciesDetailData {
  id: number,
  source: string,
  aiSubjectId: number,
  aiSubjectName: string,
  question: string,
  aiSolutions: {
      id: number,
      stepByStep: string,
  }[]
}

const defaultDataList = {
  id: -1,
  source: '',
  aiSubjectId: 0,
  aiSubjectName: '',
  question: '',
  aiSolutions: []
};

const AiExerciesDetail: React.FC<ActiveRoles> = ({
  roleIndex
}) => {
  /* Hooks */
  const [searchParams, setSearchParams] = useSearchParams();
  const idParam = Number(searchParams.get('id'));
  const source = searchParams.get('source');
  const { t } = useTranslation();

  const pageParam = searchParams.get('page');

  /* Selectors */
  const { system: { defaultPageSize } } = useAppSelector((state) => state);

  /* State */
  const [currentPage, setCurrentPage] = useState(Number(pageParam));
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [dataList, setDataList] = useState<AiExerciesDetailData>(defaultDataList);

  const { data: aiExercies, isLoading } = useQuery(
    ['getAiExerciesService', currentPage, currentView, idParam, source],
    () => getAiExerciesService({
      id: idParam,
      source: source || '',
      page: currentPage,
      limit: currentView,
    }),
    {
      enabled: roleIndex && !!currentPage,
    }
  );

  const tableData: AiExerciesDetailData[] = useMemo(() => (
    aiExercies?.data.map((val) => ({
      id: val.sourceData.id,
      source: val.sourceData?.source || '',
      aiSubjectId: val.sourceData?.aiSubject.id,
      question: val.sourceData?.question || '',
      aiSubjectName: val.sourceData?.aiSubject.name || '',
      aiSolutions: val.sourceData?.aiSolutions || [],
    })) || []), [aiExercies]);

  const columns: ColumnsType<AiExerciesDetailData> = [
    {
      title: 'ID',
      key: 'id',
      width: 85,
      align: 'center',
      fixed: 'left',
      render: (_name: string, data: AiExerciesDetailData) => (
        <Typography.Text>
          {data.id || ''}
        </Typography.Text>
      ),
    },
    {
      title: t('aiExercises.source'),
      key: 'source',
      render: (_name: string, data: AiExerciesDetailData) => (
        <Typography.Text>
          {data.source || ''}
        </Typography.Text>
      ),
    },
    {
      title: t('aiExercises.aiSubjectId'),
      key: 'aiSubjectId',
      render: (_name: string, data: AiExerciesDetailData) => (
        <Typography.Text>
          {data.aiSubjectId}
        </Typography.Text>
      ),
    },
    {
      title: t('aiExercises.aiSubjectName'),
      key: 'aiSubjectName',
      render: (_name: string, data: AiExerciesDetailData) => (
        <Typography.Text>
          {data.aiSubjectName}
        </Typography.Text>
      ),
    },
    {
      title: t('system.action'),
      key: 'action',
      width: 100,
      align: 'center',
      render: (_name: string, data: AiExerciesDetailData) => (
        <Space>
          <Button
            icon={<EyeOutlined />}
            onClick={() => setDataList(data)}
          />
        </Space>
      ),
    },
  ];

  // Change page view
  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ page: page.toString(), id: idParam.toString(), source: source || '' }, { replace: true });
    };

  // Change page size
  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  useEffect(() => {
    setCurrentPage(pageParam ? Number(pageParam) : 1);
  }, [pageParam, setSearchParams]);

  useDidMount(() => {
    if (pageParam) return setCurrentPage(Number(pageParam));
    return setCurrentPage(Number(pageParam) || 1);
  });

  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title="AI Exercise Detail"
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          isLoading={isLoading}
          noCheckbox
          tableProps={{
            initShowColumns: ['id', 'source', 'aiSubjectId', 'aiSubjectName', 'action'],
            columns,
            pageData: tableData || [],
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: aiExercies?.meta.total ?? 1,
            noBaseCol: true,
            noDeleteLanguage: true,
          }}
        />
      </div>
      <DetailAIExerciseModal
        data={dataList}
        handleClose={() => setDataList(defaultDataList)}
      />
    </>
  );
};

export default AiExerciesDetail;
