import {
  ExportResponse, OrderData, SubscriptionDataTypes,
  TransactionHistoryDataTypes, TransactionHistoryParams
} from './types';

import axiosInstance from 'common/services/common/instance';

export const getAllOrdersService = async (
  params?: BaseFilterParams
): Promise<APIPaginationResponse<OrderData[]>> => {
  const res = await axiosInstance.get('orders', { params });
  return res.data;
};

export const getOrderByIdService = async (id: number): Promise<OrderData> => {
  const res = await axiosInstance.get(`orders/${id}`);
  return res.data.data;
};

export const exportOrdersService = async (params?: BaseFilterParams): Promise<ExportResponse> => {
  const res = await axiosInstance.get('orders/exports', { params });
  return res.data.data;
};

export const subscriptionsService = async (params?: BaseFilterParams)
  : Promise<APIPaginationResponse<SubscriptionDataTypes[]>> => {
  const res = await axiosInstance.get('subscriptions', { params });
  return res.data;
};

export const subscriptionsByIdService = async (
  id: string,
  params?: TransactionHistoryParams
)
  : Promise<SubscriptionDataTypes> => {
  const res = await axiosInstance.get(`subscriptions/${id}`, { params });
  return res.data.data;
};

export const subscriptionTransactionHistoriesService = async (
  id: string,
  params?: TransactionHistoryParams
)
  : Promise<APIPaginationResponse<TransactionHistoryDataTypes[]>> => {
  const res = await axiosInstance.get(`subscriptions/transaction-histories/${id}`, { params });
  return res.data;
};
