/* eslint-disable prefer-regex-literals */
import * as yup from 'yup';

// export const phoneRegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/;

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email('Địa chỉ email không hợp lệ')
    .max(255, 'Nhập tối đa 255 ký tự')
    .required('Địa chỉ email là bắt buộc'),
  password: yup.string().required('Mật khẩu là bắt buộc'),
  otpCode: yup
    .string()
    .matches(new RegExp('^[0-9]+$'), { message: 'OTP phải là số' })
    .length(6, 'OTP phải có 6 chữ số'),
});

export const forgotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .email('Địa chỉ email không hợp lệ')
    .max(255, 'Nhập tối đa 255 ký tự')
    .required('Địa chỉ email là bắt buộc'),
});

export const detailPageSchema = yup.object().shape({
  title: yup.string().required('Trường này là bắt buộc'),
  slug: yup.string().required('Trường này là bắt buộc'),
  seoTitle: yup.string(),
});

export const menuGenerationForm = yup.object().shape({
  title: yup.number().required('Trường này là bắt buộc'),
  target: yup.number().required('Trường này là bắt buộc'),
});

export const menuGenerationForm2 = yup.object().shape({
  title: yup.string().required('Trường này là bắt buộc'),
  target: yup.number().required('Trường này là bắt buộc'),
});

export const detailPageHeaderSchema = yup.object().shape({
  sample: yup.string().required('Hãy chọn một mẫu'),
  parent: yup.number(),
  state: yup.number(),
  isHome: yup.boolean(),
});

const blockSectionObj = {
  name: yup.string(),
  description: yup.string(),
  content: yup.string(),
  ogImage: yup.object().nullable(),
  link: yup.string(),
  url: yup.string(),
  target: yup.object().nullable(),
};

const mediaSocialObj = {
  ogTitle: yup.string(),
  ogDescription: yup.string(),
  ogType: yup.number().required('Chọn 1 mạng xã hội'),
  ogImage: yup.object().nullable(),
};

export const seoSectionSchema = yup.object().shape({
  seoTitle: yup.string(),
  seoIntro: yup.string(),
  seoKeyword: yup.string(),
  metaViewPort: yup.string(),
  metaRobot: yup.string(),
  canonicalURL: yup.string(),
  structuredData: yup.string(),
  ogImage: yup.object().nullable(),
  mediaSocial: yup.array().of(yup.object().shape(mediaSocialObj)),
});

export const openGraphSectionSchema = yup.object().shape({
  ogTitle: yup.string(),
  ogType: yup.string(),
  ogImage: yup.object().nullable(),
});

export const blockSectionSchema = yup.object().shape({
  sectionTitle: yup.string(),
  blockSection: yup.array().of(yup.object().shape(blockSectionObj)),
});

export const commentSectionSchema = yup.object().shape({
  comment: yup.string(),
});

export const menuCreateSchema = yup.object().shape({
  title: yup.string().required('Điền tiêu đề menu'),
  code: yup.string().required('Điền mã code cho menu'),
});

export const templateEditSchema = yup.object().shape({
  title: yup.string().required('Điền tiêu đề trang mẫu'),
});

export const bannerCreateSchema = yup.object().shape({
  name: yup.string().required('Điền tên banner'),
  items: yup.array().of(
    yup.object().shape({
      type: yup.string(),
      data: yup.object().when('type', {
        is: 'video',
        then: (schema) => schema.shape({
          videoType: yup.string().required('Vui lòng chọn loại'),
          videoUrl: yup.string().required('Thông tin bắt buộc'),
          videoThumbnail: yup.string().required('Thông tin bắt buộc'),
        }),
      }),
    })
  ),
});

export const pageDetailMainData = yup.object().shape({
  title: yup.string().required('Điền tiêu đề trang'),
  slug: yup.string().required('Điền đường dẫn trang'),
});

export const redirectFormSchema = yup.object().shape({
  from: yup.string().required('Thông tin bắt buộc'),
  to: yup.string().required('Thông tin bắt buộc'),
});

export const roleFormSchema = yup.object().shape({
  displayName: yup.string().required('Thông tin bắt buộc'),
});

export const userFormSchema = yup.object().shape({
  name: yup.string().required('Thông tin bắt buộc'),
  email: yup
    .string()
    .email('Địa chỉ email không hợp lệ')
    .max(255, 'Nhập tối đa 255 ký tự')
    .required('Địa chỉ email là bắt buộc'),
  password: yup
    .string()
    .notRequired()
    .test('password', 'Mật khẩu ít nhất 6 kí tự', (value) => {
      if (value) {
        const schema = yup.string().min(6);
        return schema.isValidSync(value);
      }
      return true;
    }),
  confirmPassword: yup.string().oneOf([yup.ref('password')], 'Mật khẩu xác nhận không khớp'),
  roles: yup.array().min(1, 'Chọn ít nhất 1 quyền'),
});

export const updateNewsByIdSchema = yup.object().shape({
  displayOrder: yup.number().required('Nhập thứ tự hiển thị'),
  title: yup.string().required('Nhập tiêu đề'),
  slug: yup.string().required('Nhập đường dẫn'),
  description: yup.string().required('Nhập mô tả'),
  content: yup.string().required('Nhập nội dung'),
  thumbnail: yup.string().required('Cập nhật hình ảnh'),
});

export const updateNewsCategoriesByIdSchema = yup.object().shape({
  displayOrder: yup.number().required('Nhập thứ tự hiển thị'),
  name: yup.string().required('Nhập tiêu đề'),
  slug: yup.string().required('Nhập đường dẫn'),
  description: yup.string().required('Nhập mô tả'),
});

export const updateCategoryFaqByIdSchema = yup.object().shape({
  displayOrder: yup.number().required('Nhập thứ tự hiển thị'),
  name: yup.string().required('Nhập tiêu đề'),
  slug: yup.string().required('Nhập đường dẫn'),
  description: yup.string().required('Nhập mô tả'),
});

export const updateFaqByIdSchema = yup.object().shape({
  answer: yup.string().required('Nhập câu hỏi'),
  question: yup.string().required('Nhập câu trả lời'),
  faqCategoryId: yup.array().required('Chọn danh mục'),
});

export const updateContactProblemSchema = yup.object().shape({
  name: yup.string().required('Nhập tên'),
});

export const updateProfileSchema = yup.object().shape({
  name: yup.string().required('Trường này là bắt buộc'),
  email: yup
    .string()
    .email('Địa chỉ email không hợp lệ')
    .max(255, 'Nhập tối đa 255 ký tự')
    .required('Trường này là bắt buộc'),
  password: yup.string().required('Trường này là bắt buộc'),
});

export const changePasswordSchema = yup.object().shape({
  oldPassword: yup.string().required('Trường này là bắt buộc'),
  newPassword: yup
    .string()
    .min(6)
    .required('Trường này là bắt buộc')
    .notOneOf([yup.ref('oldPassword')], 'Mật khẩu mới không được trùng mật khẩu cũ')
    .min(6, 'Mật khẩu phải ít nhất 6 kí tự'),
  newPasswordConfirm: yup
    .string()
    .required('Trường này là bắt buộc')
    .oneOf([yup.ref('newPassword')], 'Mật khẩu xác nhận không khớp'),
});

export const updateEmailTemplateSchema = yup.object().shape({
  name: yup.string().required('Nhập tên'),
  subject: yup.string().required('Nhập tiêu đề'),
  content: yup.string().required('Nhập nội dung'),
});

export const activeTOtpSecretSchema = yup.object().shape({
  currentPassword: yup.string().required('Trường này là bắt buộc'),
  otpCode: yup
    .string()
    .matches(new RegExp('^[0-9]+$'), { message: 'OTP phải là số' })
    .length(6, 'OTP phải có 6 chữ số')
    .required('Trường này là bắt buộc'),
});

export const requiredPasswordSchema = yup.object().shape({
  currentPassword: yup.string().required('Trường này là bắt buộc'),
});

export const staticBlocksMainData = yup.object().shape({
  name: yup.string().required('Trường này là bắt buộc'),
  templateCode: yup.string().required('Trường này là bắt buộc'),
});

const configSchema = yup.object().shape({
  type: yup.string().required('Trường này là bắt buộc'),
  webOrWeekLimit: yup.number()
    .typeError('Trường này là bắt buộc')
    .required('Trường này là bắt buộc')
    .test('integer', 'Phải là số nguyên', (value) => {
      if (value && Number(value) < 0) return false;
      if (!Number.isInteger(value) || String(value).includes('.')) {
        return false;
      }
      return true;
    }),
  appOrMonthLimit: yup.number()
    .typeError('Trường này là bắt buộc')
    .required('Trường này là bắt buộc')
    .test('integer', 'Phải là số nguyên', (value) => {
      if (value && Number(value) < 0) return false;
      if (!Number.isInteger(value) || String(value).includes('.')) {
        return false;
      }
      return true;
    }),
});

export const userLevelFormSchema = yup.object().shape({
  name: yup.string().required('Thông tin bắt buộc'),
  requiredLogin: yup.boolean().required('Thông tin bắt buộc'),
  level: yup.number().required('Thông tin bắt buộc'),
  config: yup.array()
    .of(configSchema)
    .min(1, 'Chọn ít nhất 1 quyền'),
});

export const locationSchema = yup.object().shape({
  province: yup.number().required('Trường này là bắt buộc'),
  district: yup.number().required('Trường này là bắt buộc'),
});

export const productFormSchema = yup.object().shape({
  nameUnique: yup.string().required('Trường này là bắt buộc'),
  productAppleId: yup.string(),
  productGoogleId: yup.string(),
  duration: yup.number().required('Trường này là bắt buộc'),
  userLevel: yup.object().required('Trường này là bắt buộc')
    .test('userLevel', 'Trường này là bắt buộc', (scheme) => {
      const isObject = typeof scheme;
      if (isObject && scheme && !scheme.value) {
        return false;
      }
      return true;
    }),
  displayOrder: yup.number(),
  status: yup.boolean(),
  contentColor: yup.string(),
  translations: yup.lazy((obj) => yup.object(
    Object.keys(obj).reduce(
      (acc, lang) => ({
        ...acc,
        [lang]: yup.object({
          productData: yup.object({
            name: yup.string().required('Trường này là bắt buộc'),
            description: yup.array().of(
              yup.object({
                value: yup.string().required('Trường này là bắt buộc'),
              })
            )
          })
        }),
      }),
      {}
    )
  ))
});

export const onboardingFormSchema = yup.object().shape({
  active: yup.boolean(),
  displayOrder: yup.number(),
  translations: yup.lazy((obj) => yup.object(
    Object.keys(obj).reduce(
      (acc, lang) => ({
        ...acc,
        [lang]: yup.object({
          functionalData: yup.object({
            title: yup.string().required('Trường này là bắt buộc'),
            description: yup.array().of(
              yup.object({
                value: yup.string().required('Trường này là bắt buộc'),
              })
            ),
          })
        }),
      }),
      {}
    )
  ))
});

export const suggestedCommandFormSchema = yup.object().shape({
  active: yup.boolean(),
  displayOrder: yup
    .number()
    .nullable()
    .min(0, 'Thứ tự hiển thị phải lớn hơn 0')
    .integer('Thứ tự hiển thị phải là số nguyên')
    .transform((value, oriVal) => (oriVal === '' ? null : value)),
  name: yup.string().required('Thông tin bắt buộc'),
  translations: yup.lazy((obj) => yup.object(
    Object.keys(obj).reduce(
      (acc, lang) => ({
        ...acc,
        [lang]: yup.object({
          suggestedCommandData: yup.object({
            title: yup.string().required('Thông tin bắt buộc'),
            description: yup.string()
              .required('Thông tin bắt buộc')
              .max(200, 'Mô tả không thể dài quá 200 ký tự'),
          })
        }),
      }),
      {}
    )
  ))
});

export const studentRoleFormSchema = yup.object().shape({
  active: yup.boolean(),
  displayOrder: yup.number(),
  translations: yup.lazy((obj) => yup.object(
    Object.keys(obj).reduce(
      (acc, lang) => ({
        ...acc,
        [lang]: yup.object({
          studentRoleData: yup.object({
            name: yup.string().required('Trường này là bắt buộc'),
          })
        }),
      }),
      {}
    )
  ))
});

export const productPriceFormSchema = yup.object().shape({
  name: yup.string().required('Trường này là bắt buộc'),
  startDate: yup.string().required('Trường này là bắt buộc'),
  endDate: yup
    .string()
    .nullable()
    .test('is-after-start-date', 'Ngày kết thúc phải sau ngày bắt đầu', function (endDate) {
      const { startDate } = this.parent;
      if (endDate && startDate) {
        return new Date(endDate) > new Date(startDate);
      }
      return true;
    }),
  price: yup.string().required('Trường này là bắt buộc'),
  priceVND: yup.string().required('Trường này là bắt buộc'),
  appPrice: yup.string().required('Trường này là bắt buộc'),
  appPriceVND: yup.string().required('Trường này là bắt buộc'),
  countryData: yup
    .array()
    .of(
      yup.object().shape({
        country: yup.object().required('Trường này là bắt buộc')
          .test('validate', 'Trường này là bắt buộc', (scheme) => {
            const isObject = typeof scheme;
            if (isObject && scheme && !scheme.value) {
              return false;
            }
            return true;
          }),
        iosPrice: yup
          .number()
          .nullable()
          .required('Trường này là bắt buộc')
          .transform((value, oriVal) => (oriVal === '' ? null : value))
          .min(0, 'Giá phải lớn hơn 0'),
        androidPrice: yup
          .number()
          .nullable()
          .required('Trường này là bắt buộc')
          .transform((value, oriVal) => (oriVal === '' ? null : value))
          .min(0, 'Giá phải lớn hơn 0'),
        iosCurrency: yup.object().shape({
          value: yup.string().required('Trường này là bắt buộc'),
        }),
        androidCurrency: yup.object().shape({
          value: yup.string().required('Trường này là bắt buộc'),
        })
      })
    )
    .nullable(),
});

// Custom test to detect duplicate codes and their positions
// const uniqueCodePositionTest = (codes: any) => {
//   const duplicates = codes
//     .map((item: any, index: number) => ({ code: item.code, index }))
//     .filter((item: any, _: any, array: any) => array.filter(({
//       code
//     }: any) => code === item.code).length > 1);

//   return duplicates.length === 0 || duplicates;
// };

// Custom function to detect duplicate codes and return their positions

export const redeemFormSchema = yup.object().shape({
  name: yup.string().required('Thông tin bắt buộc').max(100, 'Tên không được vượt quá 100 ký tự'),
  type: yup.string().required('Thông tin bắt buộc'),
  product: yup.object().shape({
    label: yup.string(),
    value: yup.number().required('Trường này là bắt buộc')
  }),
  startDate: yup.string().nullable().required('Trường này là bắt buộc'),
  endDate: yup
    .string()
    .nullable()
    .test('is-after-start-date', 'Ngày kết thúc phải sau ngày bắt đầu', function (endDate) {
      const { startDate } = this.parent;
      if (endDate && startDate) {
        return new Date(endDate) > new Date(startDate);
      }
      return true;
    }).required('Trường này là bắt buộc'),
  quantity: yup.number()
    .typeError('Trường này là bắt buộc')
    .required('Trường này là bắt buộc')
    .min(1, 'Số lượng ít nhất là 1')
    .test('integer', 'Phải là số nguyên', (value) => {
      if (value && Number(value) < 0) return false;
      if (!Number.isInteger(value) || String(value).includes('.')) {
        return false;
      }
      return true;
    }),
    studentIds: yup.array().of(
      yup.object().shape({
        label: yup.string(),
        value: yup.number(),
      })
    ).test(
      'max-student-ids',
      'Số lượng học sinh phải nhỏ hơn hoặc bằng số lượng mã',
      (studentIds, context) => (studentIds ? studentIds.length <= context.parent.quantity : true)
    )
});

export const gradeFormSchema = yup.object().shape({
  name: yup.string().required('Thông tin bắt buộc'),
  studentRoleId: yup.number().required('Thông tin bắt buộc'),
  displayOrder: yup.number()
    .typeError('Trường này là bắt buộc')
    .required('Trường này là bắt buộc')
    .test('integer', 'Phải là số nguyên', (value) => {
      if (value && Number(value) < 0) return false;
      if (!Number.isInteger(value) || String(value).includes('.')) {
        return false;
      }
      return true;
    }),
});

export const functionalFormSchema = yup.object().shape({
  name: yup.string().required('Thông tin bắt buộc'),
  status: yup.boolean()
});

export const promotionFormSchema = yup.object().shape({
  campaign: yup.string().required('Thông tin bắt buộc').max(100, 'Tên không được vượt quá 100 ký tự'),
  userType: yup.number().required('Thông tin bắt buộc'),
  productType: yup.number().required('Thông tin bắt buộc'),
  promotionType: yup.number().required('Thông tin bắt buộc'),
  codeType: yup.number().required('Thông tin bắt buộc'),
  startDate: yup.string().nullable().required('Trường này là bắt buộc'),
  endDate: yup
    .string()
    .nullable()
    .test('is-after-start-date', 'Ngày kết thúc phải sau ngày bắt đầu', function (endDate) {
      const { startDate } = this.parent;
      if (endDate && startDate) {
        return new Date(endDate) > new Date(startDate);
      }
      return true;
    }).required('Trường này là bắt buộc'),
  quantity: yup
    .number()
    .typeError('Trường này là bắt buộc')
    .required('Trường này là bắt buộc')
    .min(1, 'Số lượng ít nhất là 1')
    .max(10000, 'Số lượng tối đa là 10000')
    .test('integer', 'Phải là số nguyên', (value) => {
      if (value && (Number(value) < 1 || Number(value) > 10000)) return false;
      return Number.isInteger(value);
    }),
  discountType: yup
    .number()
    .when('promotionType', {
      is: 1,
      then: yup.number().nullable().required('Thông tin bắt buộc'),
      otherwise: yup.number().nullable().notRequired(),
    }),
  discountAmount: yup
    .number()
    .transform((value, oriVal) => (oriVal === '' ? null : value))
    .when(['promotionType', 'discountType'], {
      is: (promotionType: number, discountType: number) => promotionType === 1
      && discountType === 1,
      then: yup
        .number()
        .nullable()
        .required('Thông tin bắt buộc')
        .min(0, 'Giá trị phải lớn hơn hoặc bằng 0')
        .max(100, 'Giá trị phải nhỏ hơn hoặc bằng 100'),
      otherwise: yup
        .number()
        .nullable()
        .when('promotionType', {
          is: 1,
          then: yup.number().nullable().required('Thông tin bắt buộc').min(1, 'Giá trị phải lớn hơn 0'),
          otherwise: yup.number().nullable().notRequired(),
        }),
    }),
  durationType: yup
    .number()
    .when('promotionType', {
      is: 2,
      then: yup.number().nullable().required('Thông tin bắt buộc'),
      otherwise: yup.number().nullable().notRequired(),
    }),
  discountVNDAmount: yup
    .number()
    .transform((value, oriVal) => (oriVal === '' ? null : value))
    .when(['promotionType', 'discountType'], {
      is: (promotionType: number, discountType: number) => promotionType === 1
      && discountType === 2,
      then: yup.number().nullable().required('Thông tin bắt buộc').min(1, 'Giá trị phải lớn hơn 0'),
      otherwise: yup.number().nullable().notRequired(),
    }),
  freeDuration: yup
    .number()
    .when('promotionType', {
      is: 2,
      then: yup.number().nullable().required('Thông tin bắt buộc'),
      otherwise: yup.number().nullable().notRequired(),
    }),
});
