import 'katex/dist/katex.min.css';
import {
  Card,
  Col,
  Modal,
  Row,
  Typography
} from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Latex from 'react-latex-next';

// eslint-disable-next-line import/no-cycle
import { AiExerciesDetailData } from './detail';

export interface DetailAIExersiceForm {
  data: AiExerciesDetailData;
  handleClose: () => void;
}

const DetailAIExerciseModal: React.FC<DetailAIExersiceForm> = ({
  data, handleClose
}) => {
  /* Hooks */
  const { t } = useTranslation();

  return (
    <Modal
      title={<Typography.Title level={3}>AI Exercise Detail</Typography.Title>}
      open={data.id > -1}
      centered
      onCancel={handleClose}
      width={1200}
      footer={null}
    >
      <div>
        <Row gutter={16}>
          <Col xxl={24}>
            <Row gutter={16}>
              <Col span={12}>
                <div className="u-mt-8">
                  <Typography.Text strong>
                    ID:
                    {' '}
                  </Typography.Text>
                  <Typography.Text>
                    {data.id}
                  </Typography.Text>
                </div>
              </Col>
              <Col span={12}>
                <div className="u-mt-8">
                  <Typography.Text strong>
                    {t('aiExercises.source')}
                    :
                    {' '}
                  </Typography.Text>
                  <Typography.Text>
                    {data.source}
                  </Typography.Text>
                </div>
              </Col>
              <Col span={12}>
                <div className="u-mt-8">
                  <Typography.Text strong>
                    {t('aiExercises.aiSubjectId')}
                    :
                    {' '}
                  </Typography.Text>
                  <Typography.Text>
                    {data.aiSubjectId}
                  </Typography.Text>
                </div>
              </Col>
              <Col span={12}>
                <div className="u-mt-8">
                  <Typography.Text strong>
                    {t('aiExercises.aiSubjectName')}
                    :
                    {' '}
                  </Typography.Text>
                  <Typography.Text>
                    {data.aiSubjectName }
                  </Typography.Text>
                </div>
              </Col>
              <Col span={24}>
                <div className="u-mt-8">
                  <Typography.Text strong>
                    {t('faqDetail.question')}
                    :
                    {' '}
                  </Typography.Text>
                  <Latex>
                    {data.question}
                  </Latex>
                </div>
              </Col>
              {data.aiSolutions.length > 0 && data.aiSolutions.map((item) => (
                <Card
                  title={`AI Solution ${item.id}`}
                  key={item.id}
                  type="inner"
                  style={{ width: '100%', marginTop: 16 }}
                >
                  <Col span={24} key={item.id}>
                    <div className="u-mt-8">
                      <Typography.Text strong>
                        {t('aiExercises.stepByStep')}
                        :
                        {' '}
                      </Typography.Text>
                      <Latex>
                        {item.stepByStep}
                      </Latex>
                    </div>
                  </Col>
                </Card>
              ))}
            </Row>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default DetailAIExerciseModal;
