import {
  EditOutlined, PlusOutlined,
} from '@ant-design/icons';
import {
  Button, Space, Typography
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import useNavigateParams from 'common/hooks/useNavigateParams';
import { getAllUserLevelService } from 'common/services/extends/userLevels';
import { UserLevelData } from 'common/services/extends/userLevels/types';
import { ROUTE_PATHS, userLevelOptions } from 'common/utils/constant';
import { formatDateTime } from 'common/utils/functions';

const UserLevelManagement: React.FC<ActiveRoles> = ({
  roleCreate, roleUpdate
}) => {
  /* Hook */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navigateParams = useNavigateParams();

  /* Selectors */
  const { defaultPageSize } = useAppSelector((state) => state.system);

  /* States */
  const [currentPage, setCurrentPage] = useState(1);
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [keyword, setKeyword] = useState('');

  /* React-query */
  const {
    isFetching: listLoading,
    data: listData,
  } = useQuery(
    ['userLevelManagement-list', currentPage, keyword, currentView],
    () => getAllUserLevelService({ page: currentPage, keyword, limit: currentView }),
    { keepPreviousData: true }
  );

  /* Functions */
  const handleSearch = (val: string) => {
    setKeyword(val);
  };

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  /* Datas */
  const columns: ColumnsType<UserLevelData> = useMemo(() => ([
    // --- ID
    {
      title: 'ID',
      key: 'id',
      width: 55,
      align: 'center',
      render: (_name: string, data: UserLevelData) => (
        <Typography.Text>
          {data.userLevelData.id}
        </Typography.Text>
      ),
    },
    // --- Tên
    {
      title: t('system.name'),
      dataIndex: 'name',
      key: 'name',
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: UserLevelData) => (
        <Typography.Text>
          {data.userLevelData.name}
        </Typography.Text>
      ),
    },
    // --- Email
    {
      title: 'Level',
      dataIndex: 'level',
      key: 'level',
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: UserLevelData) => (
        <Typography.Text>
          {userLevelOptions.find((item) => item.value === data.userLevelData.level)?.label
            || data.userLevelData.level}
        </Typography.Text>
      )
    },
    {
      title: t('userLevels.requiredLogin'),
      dataIndex: 'requiredLogin',
      key: 'requiredLogin',
      render: (_name: string, data: UserLevelData) => (
        <Typography.Text>
          {data.userLevelData.requiredLogin ? t('system.yes') : t('system.no')}
        </Typography.Text>
      )
    },
    {
      title: t('userLevels.updatedBy'),
      dataIndex: 'updatedBy',
      key: 'updatedBy',
      render: (_name: string, data: UserLevelData) => (
        <Typography.Text>
          {data.updaterData?.name || ''}
        </Typography.Text>
      )
    },
    // --- Tạo lúc
    {
      title: t('system.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 250,
      render: (_name: string, data: UserLevelData) => (
        <Typography.Text>
          {formatDateTime(data.userLevelData?.createdAt || '')}
        </Typography.Text>
      ),
    },
    // --- Cập nhật
    {
      title: t('system.updatedAt'),
      dataIndex: 'updatedAt',
      width: 250,
      key: 'updatedAt',
      render: (_name: string, data: UserLevelData) => (
        <Typography.Text>
          {formatDateTime(data.userLevelData?.updatedAt || '')}
        </Typography.Text>
      ),
    },
    // --- Thao tác
    {
      title: t('system.action'),
      key: 'action',
      width: 100,
      align: 'center',
      render: (_name: string, _data: UserLevelData) => (
        <Space>
          <Button
            disabled={!roleUpdate}
            icon={<EditOutlined />}
            onClick={() => navigateParams(`${ROUTE_PATHS.USER_LEVEL_DETAIL}`, `id=${_data.userLevelData.id}`)}
          />
        </Space>
      ),
    },
  ]), [t, roleUpdate, navigateParams]);

  const tableData: UserLevelData[] = useMemo(() => listData?.data || [], [listData]);

  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.userLevels')}
        rightHeader={(
          <Space>
            <Button
              disabled={!roleCreate}
              type="primary"
              onClick={() => navigate(ROUTE_PATHS.USER_LEVEL_DETAIL)}
            >
              <PlusOutlined />
              {t('system.create')}
            </Button>
          </Space>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          isLoading={listLoading}
          handleSearch={handleSearch}
          noCheckbox
          tableProps={{
            initShowColumns: ['id', 'name', 'level', 'requiredLogin', 'updatedBy', 'action'],
            columns,
            pageData: tableData,
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: listData?.meta.total || 1,
            noBaseCol: true,
            noDeleteLanguage: true,
          }}
        />
      </div>
    </>
  );
};

export default UserLevelManagement;
