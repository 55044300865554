import {
  SaveOutlined
} from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button, Card, Col, message, Row, Spin, Switch, Typography
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import {
  Controller, FormProvider, useForm
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import Input from 'common/components/Input';
import ManagementInfo from 'common/components/ManagementInfo';
import {
  createSuggestedCommandService,
  getSuggestedCommandByIdService,
  updateSuggestedCommandService
} from 'common/services/extends/suggestedCommand';
import { CreateSuggestedCommandParams, UpdateSuggestedCommandParams } from 'common/services/extends/suggestedCommand/types';
import { ROUTE_PATHS } from 'common/utils/constant';
import { formatDateTime } from 'common/utils/functions';
import { suggestedCommandFormSchema } from 'common/utils/schemas';

type SuggestedCommandFormData = {
  active: boolean;
  displayOrder: number;
  name: string;
  translations: {
    [locale: string]: {
      suggestedCommandData: {
        title: string;
        description: string;
      }
    };
  };
};

const SuggestedCommandDetail: React.FC<ActiveRoles> = ({
  roleIndex,
  roleCreate,
  roleUpdate
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const { languageOptions, defaultWebsiteLanguage } = useAppSelector((state) => state.system);
  const idParam = Number(searchParams.get('id'));
  const localeParam = searchParams.get('locale') || defaultWebsiteLanguage || '';
  const [currentLang, setCurrentLang] = useState<string>(localeParam);

  const DEFAULT_VALUS = {
    active: false,
    displayOrder: 0,
    name: '',
    translations: {
      [currentLang]: {
        suggestedCommandData: {
          title: '',
          description: '',
        },
      },
    },
  };

  const method = useForm<SuggestedCommandFormData>({
    defaultValues: DEFAULT_VALUS,
    resolver: yupResolver(suggestedCommandFormSchema),
    mode: 'onChange',
  });

  const querySuggestedCommandDataById = ['getSuggestedCommandDataById', idParam];

  const { data: suggestedCommandData, isLoading: suggestedCommandDataLoading } = useQuery(
    querySuggestedCommandDataById,
    () => getSuggestedCommandByIdService(idParam),
    {
      enabled: roleIndex && !!idParam
    }
  );

  const { mutate: createMutate, isLoading: createLoading } = useMutation(
    ['createSuggestedCommandMutate'],
    async (params: CreateSuggestedCommandParams) => createSuggestedCommandService(params),
    {
      onSuccess: () => {
        navigate(ROUTE_PATHS.SUGGESTED_COMMAND_MANAGEMENT);
      },
      onError: (errors: ErrorResponse[]) => {
        if (errors.length > 0) {
          errors.forEach((error: ErrorResponse) => {
            method.setError(error.field as keyof SuggestedCommandFormData, { message: error.message || '' });
          });
        } else {
          message.error(t('message.createError'));
        }
      },
    }
  );

  const { mutate: updateMutate, isLoading: updateLoading } = useMutation(
    ['updateSuggestedCommandMutate'],
    async (params: UpdateSuggestedCommandParams) => updateSuggestedCommandService(params),
    {
      onSuccess: () => {
        message.success(t('message.updateSuccess'));
        queryClient.invalidateQueries(querySuggestedCommandDataById);
      },
      onError: (errors: ErrorResponse[]) => {
        if (errors.length > 0) {
          errors.forEach((error: ErrorResponse) => {
            method.setError(error.field as keyof SuggestedCommandFormData, { message: error.message || '' });
          });
        } else {
          message.error(t('message.updateError'));
        }
      }
    }
  );

  const onSubmit = async () => {
    const isValid = await method.trigger();
    if (!isValid) {
      return;
    }

    const formData = method.getValues();

    const { translations } = formData;
    const formattedTranslations = Object.entries(translations).reduce(
      (acc, [lang, data]) => ({
        ...acc,
        [lang]: {
          suggestedCommandData: {
            title: data.suggestedCommandData.title,
            description: data.suggestedCommandData.description,
          }
        },
      }),
      {}
    );

    if (idParam) {
      updateMutate({ id: idParam, ...formData, translations: formattedTranslations });
    } else {
      createMutate({ ...formData, translations: formattedTranslations });
    }
  };

  const handleChangeLang = (lang: LanguageCodeTypes) => {
    setCurrentLang(lang);

    if (idParam) {
      setSearchParams(
        {
          id: String(idParam),
          locale: lang,
        },
        { replace: true }
      );
    } else {
      setSearchParams(
        {
          locale: lang,
        },
        { replace: true }
      );
    }
  };

  useEffect(() => {
    if (suggestedCommandData) {
      const transformedTranslations = Object.entries(suggestedCommandData.translations).reduce(
        (acc, [lang, data]) => ({
          ...acc,
          [lang]: {
            suggestedCommandData: {
              title: data.title || '',
              description: data.description || ''
            }
          },
        }),
        {}
      );

      method.reset({
        active: suggestedCommandData.suggestedCommandData.active,
        displayOrder: suggestedCommandData.suggestedCommandData.displayOrder,
        name: suggestedCommandData.suggestedCommandData.name,
        translations: transformedTranslations,
      });
    } else {
      method.reset(DEFAULT_VALUS);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLang, method, suggestedCommandData]);

  return (
    <FormProvider<SuggestedCommandFormData> {...method}>
      <HeaderPage
        fixed
        title={idParam ? t('onboarding.edit') : t('system.create')}
        rightHeader={(
          <Button
            type="primary"
            loading={suggestedCommandDataLoading || createLoading || updateLoading}
            onClick={method.handleSubmit(onSubmit)}
            disabled={(idParam && !roleUpdate) || (!idParam && !roleCreate)}
          >
            <SaveOutlined />
            {t('system.save')}
          </Button>
        )}
      />
      <Spin spinning={suggestedCommandDataLoading || createLoading || updateLoading}>
        <div className="t-mainlayout_wrapper">
          <Row gutter={16}>
            <Col xxl={18} xl={16} lg={16}>
              <Card>
                <div>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Typography.Text strong>
                        {t('system.name')}
                        {' '}
                      </Typography.Text>
                      <Typography.Text strong type="danger">
                        *
                      </Typography.Text>
                      <Controller
                        name="name"
                        defaultValue={0}
                        render={({ field: { value, onChange }, fieldState: { error } }) => (
                          <Input
                            className="u-mt-8"
                            name="name"
                            placeholder={`${t('system.input')} ${t('system.name')}`}
                            value={value}
                            onChange={onChange}
                            error={error?.message}
                            size="large"
                          />
                        )}
                      />
                    </Col>
                    <Col span={12}>
                      <Typography.Text strong>
                        {t('suggestedCommand.requestCommand')}
                        {' '}
                      </Typography.Text>
                      <Typography.Text strong type="danger">
                        *
                      </Typography.Text>
                      <Controller
                        name={`translations.${currentLang}.suggestedCommandData.title`}
                        defaultValue=""
                        render={({ field: { value, onChange }, fieldState: { error } }) => (
                          <Input
                            className="u-mt-8"
                            name={`translations.${currentLang}.suggestedCommandData.title`}
                            placeholder={`${t('system.input')} ${t('suggestedCommand.requestCommand')}`}
                            value={value}
                            onChange={onChange}
                            error={error?.message}
                            size="large"
                          />
                        )}
                      />
                    </Col>
                    <Col span={12} className="u-mt-8">
                      <Typography.Text strong>
                        {t('newsDetail.displayOrder')}
                        {' '}
                      </Typography.Text>
                      <Controller
                        name="displayOrder"
                        defaultValue={0}
                        render={({ field: { value, onChange }, fieldState: { error } }) => (
                          <Input
                            type="number"
                            className="u-mt-8"
                            name="displayOrder"
                            placeholder={`${t('system.input')} ${t('newsDetail.displayOrder')}`}
                            value={value}
                            onChange={onChange}
                            error={error?.message}
                            size="large"
                          />
                        )}
                      />
                    </Col>
                    <Col span={12} className="u-mt-8">
                      <Typography.Text strong>
                        {t('onboarding.active')}
                        {' '}
                      </Typography.Text>
                      <Controller
                        name="active"
                        defaultValue={false}
                        render={({ field: { value, onChange }, fieldState: { error } }) => (
                          <div className="u-mt-8">
                            <Switch checked={value} onChange={onChange} />
                            {error && <span className="a-input_errorMessage">{error.message}</span>}
                          </div>
                        )}
                      />
                    </Col>
                    <Col span={24} className="u-mt-8">
                      <Typography.Text strong>
                        {t('suggestedCommand.prefillContent')}
                        {' '}
                      </Typography.Text>
                      <Typography.Text strong type="danger">
                        *
                      </Typography.Text>
                      <Controller
                        name={`translations.${currentLang}.suggestedCommandData.description`}
                        defaultValue=""
                        render={({ field: { value, onChange }, fieldState: { error } }) => (
                          <>
                            <TextArea
                              className="u-mt-8"
                              name={`translations.${currentLang}.suggestedCommandData.description`}
                              placeholder={`${t('system.input')} ${t('suggestedCommand.prefillContent')}`}
                              value={value}
                              onChange={onChange}
                              size="large"
                            />
                            {error && <span className="a-input_errorMessage">{error.message}</span>}
                          </>
                        )}
                      />
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
            <Col xxl={6} xl={8} lg={8}>
              <ManagementInfo
                createdDate={formatDateTime(suggestedCommandData?.suggestedCommandData.createdAt) || ''}
                createdBy={suggestedCommandData?.creator?.name || ''}
                lastUpdated={formatDateTime(suggestedCommandData?.suggestedCommandData.updatedAt) || ''}
                lastUpdatedBy={suggestedCommandData?.updater?.name || ''}
                languageList={languageOptions}
                currentLang={currentLang}
                handleChangeLang={(value) => value && handleChangeLang(value as LanguageCodeTypes)}
              />
            </Col>
          </Row>
        </div>
      </Spin>
    </FormProvider>
  );
};

export default SuggestedCommandDetail;
