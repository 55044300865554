import { EyeOutlined } from '@ant-design/icons';
import {
  Button,
  Space,
  Typography
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import {
  FilterDataProps, FilterValueProps, mappingFilterFields,
  mappingFilterToQuery, mappingParamsFilter, mappingQueryParamsFilter
} from 'common/components/PageTable/filter';
import useDidMount from 'common/hooks/useDidMount';
import { getAllStudentsService } from 'common/services/extends/students';
import { SocialProvider } from 'common/services/extends/students/types';
import {
  ROUTE_PATHS
} from 'common/utils/constant';
import { formatDateTime } from 'common/utils/functions';

export interface StudentsData {
  id: number,
  name: string,
  email: string,
  source: SocialProvider,
  sourceId: string,
  createdAt: string,
  updatedAt: string,
}

const StudentsManagement: React.FC<ActiveRoles> = () => {
  /* Hooks */
  const { t } = useTranslation();
  const navigation = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => {
    const paramsObj = { ...Object.fromEntries(searchParams.entries()) };
    if (searchParams.has('page')) delete paramsObj.page;
    return paramsObj;
  }, [searchParams]);

  const pageParam = searchParams.get('page');

  /* Selectors */
  const { system: { defaultPageSize, advancedFilter } } = useAppSelector((state) => state);

  /* State */
  const [currentPage, setCurrentPage] = useState(Number(pageParam));
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [keyword, setKeyword] = useState('');
  const [selectedFilterList, setSelectedFilterList] = useState<
    FilterValueProps[]>(mappingQueryParamsFilter(params));

  /* Variables */
  const returnParamsFilter = useMemo(
    () => mappingParamsFilter(selectedFilterList),
    [selectedFilterList]
  );

  const filterFields: FilterDataProps[] = useMemo(
    () => mappingFilterFields('student', advancedFilter),
    [advancedFilter]
  );

  /* Queries */
  const { data: studentsData, isLoading } = useQuery(
    ['getStudentsService', keyword, currentPage, currentView, selectedFilterList],
    () => getAllStudentsService({
      keyword,
      page: currentPage,
      limit: currentView,
      ...returnParamsFilter,
    }),
    {
      enabled: !!currentPage,
    }
  );

  const tableData: StudentsData[] = useMemo(() => (
    studentsData?.data.map((val) => ({
      id: val.studentsData?.id,
      name: val.studentsData?.name || '',
      email: val.studentsData?.email || '',
      source: val.studentsData?.source,
      sourceId: val.studentsData?.sourceId || '',
      createdAt: val.studentsData?.createdAt || '',
      updatedAt: val.studentsData?.updatedAt || '',
    })) || []), [studentsData]);

  const columns: ColumnsType<StudentsData> = [
    {
      title: 'ID',
      key: 'id',
      width: 55,
      align: 'center',
      fixed: 'left',
      render: (_name: string, data: StudentsData) => (
        <Typography.Text>
          {data.id || ''}
        </Typography.Text>
      ),
    },
    {
      title: t('system.name'),
      key: 'name',
      render: (_name: string, data: StudentsData) => (
        <Typography.Text>
          {data.name || ''}
        </Typography.Text>
      ),
    },
    {
      title: t('system.email'),
      key: 'email',
      render: (_name: string, data: StudentsData) => (
        <Typography.Text>
          {data.email || ''}
        </Typography.Text>
      ),
    },
    {
      title: t('student.source'),
      key: 'source',
      render: (_name: string, data: StudentsData) => (
        <Typography.Text>
          {data.source || ''}
        </Typography.Text>
      ),
    },
    {
      title: t('student.sourceId'),
      key: 'sourceId',
      render: (_name: string, data: StudentsData) => (
        <Typography.Text>
          {data.sourceId || ''}
        </Typography.Text>
      ),
    },
    {
      title: t('system.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_name: string, data: StudentsData) => (
        <Typography.Text>
          {formatDateTime(data.createdAt || '')}
        </Typography.Text>
      ),
    },
    {
      title: t('system.updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (_name: string, data: StudentsData) => (
        <Typography.Text>
          {formatDateTime(data.updatedAt || '')}
        </Typography.Text>
      ),
    },
    {
      title: t('system.action'),
      key: 'action',
      width: 100,
      align: 'center',
      render: (_name: string, data: StudentsData) => (
        <Space>
          <Button
            icon={<EyeOutlined />}
            onClick={() => navigation(`${ROUTE_PATHS.STUDENTS_DETAIL}?id=${data.id}`)}
          />
        </Space>
      ),
    },
  ];

  // Change page view
  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ page: page.toString() }, { replace: true });
  };

  // Change page size
  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  const handleDeleteFilter = (key: string, index?: number) => {
    const tempList = selectedFilterList.slice();
    setSelectedFilterList(tempList.filter((item) => !(item.key === key && item.index === index)));
  };

  // Add filter
  const handleFilter = (data: FilterValueProps) => {
    const typeFilter = String(data.filter).split('.')[1];
    if ((typeFilter === 'isNull' || typeFilter === 'isNotNull') && selectedFilterList.find((item) => item.key === data.key)) {
      return;
    }
    const counter = selectedFilterList.filter(
      (item) => item.field === data.field && item.filter === data.filter
    ).length;
    setSelectedFilterList([...selectedFilterList, { ...data, index: counter }]);
  };

  /* Effects */
  useEffect(() => {
    setCurrentPage(pageParam ? Number(pageParam) : 1);
    setSearchParams({
      ...mappingFilterToQuery(selectedFilterList),
      page: pageParam || '1'
    }, { replace: true });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword, setSearchParams, selectedFilterList]);

  useDidMount(() => {
    if (pageParam) return setCurrentPage(Number(pageParam));
    return setCurrentPage(Number(pageParam) || 1);
  });

  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.students')}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          isLoading={isLoading}
          handleSearch={setKeyword}
          tableProps={{
            initShowColumns: ['id', 'name', 'email', 'source', 'sourceId', 'createdAt', 'updatedAt', 'action'],
            columns,
            pageData: tableData || [],
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: studentsData?.meta.total ?? 1,
            noBaseCol: true,
            noDeleteLanguage: true,
            filterFields,
          }}
          filtersDataTable={{
            handleFilter,
            selectedFilterList,
            handleDeleteFilter
          }}
        />
      </div>
    </>
  );
};

export default StudentsManagement;
