import {
  CreateSuggestedCommandParams,
  DeleteSuggestedCommandTranslationParams,
  SuggestedCommandTypes,
  UpdateSuggestedCommandParams
} from './types';

import axiosInstance from 'common/services/common/instance';

export const getAllSuggestedCommandsService = async (params?: BaseFilterParams)
  : Promise<APIPaginationResponse<SuggestedCommandTypes[]>> => {
  const res = await axiosInstance.get('suggested-commands', { params });
  return res.data;
};

export const getSuggestedCommandByIdService = async (id: number)
  : Promise<SuggestedCommandTypes> => {
  const res = await axiosInstance.get(`suggested-commands/${id}`);
  return res.data.data;
};

export const createSuggestedCommandService = async (
  params: CreateSuggestedCommandParams,
): Promise<{ id: number }> => {
  const res = await axiosInstance.post('suggested-commands', params);
  return res.data.data;
};

export const updateSuggestedCommandService = async (params: UpdateSuggestedCommandParams)
  : Promise<void> => {
  await axiosInstance.put(`suggested-commands/${params.id}`, params);
};

export const deleteSuggestedCommandService = async (
  ids: number[],
): Promise<void> => {
  await axiosInstance.delete('suggested-commands', { data: { ids } });
};

export const deleteSuggestedCommandTranslationService = async (
  params: DeleteSuggestedCommandTranslationParams,
): Promise<void> => {
  await axiosInstance.delete('suggested-commands/translations', { data: params });
};
