import { EProductShowOnDevice } from 'common/services/extends/products/types';

const LOCAL_STORAGE = {
  ACCESS_TOKEN: 'ONECMS_AccessToken',
  REFRESH_TOKEN: 'ONECMS_RefreshToken',
  ABILITIES: 'ONECMS_Abilities',
  LANGUAGE: 'ONECMS_Language',
  PREVIEW_DATA: 'ONECMS_PreviewData',
  FINDER_TREE_SELECTED: 'ONECMS_TreeSelected',
};

export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const COLORS = {
  COOL_BLACK: '#002b60'
};

export const DEFAULT_QUERY_OPTION = {
  retry: 0,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
};

export const URL_CONST = {
  MEDIA_FILE_UPLOAD_CHUNK: 'api/v1/media-files/upload-chunk',
  MEDIA_FILE_MERGE_CHUNK: 'api/v1/media-files/merge-chunk'
};

export const TARGET_LIST_OPTIONS = [
  {
    label: 'Mở liên kết trong tab hiện tại',
    value: 1
  },
  {
    label: 'Mở liên kết trong tab mới',
    value: 2
  },
];
export const TARGET_LIST_LABEL = [
  {
    label: '_self',
    value: 1
  },
  {
    label: '_blank',
    value: 2
  },
];

export const socialList: OptionType[] = [
  {
    label: 'Facebook',
    value: 1,
  },
  {
    label: 'Twitter',
    value: 2,
  },
];

export const newsListSortBy: OptionType[] = [
  {
    label: 'Thứ tự hiển thị',
    value: 'displayOrder',
  },
  {
    label: 'Ngày đăng',
    value: 'displayOrder',
  },
];

export const newsListSortType: OptionType[] = [
  {
    label: 'Ascending',
    value: 'asc',
  },
  {
    label: 'Descending',
    value: 'desc',
  },
];

export default LOCAL_STORAGE;

export const INTEGER_REGEX = /^[+-]?((\d*\.?\d+(?:[Ee][+-]?\d+)?)|(\d+\.))$/;
export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PHONE_REGEX = /^0[1-9][0-9]{8}$/;

export const ROUTE_PATHS = {
  /* BLOCK TEMPLATE */
  PAGE_TEMPLATE_MANAGEMENT: '/page-templates',
  /* PAGE */
  PAGE_MANAGEMENT: '/page-management',
  PAGE_DETAIL: '/page',
  /* FILE */
  FILE_MANAGEMENT: '/files-management',
  /* MENU */
  MENU_MANAGEMENT: '/menu-management',
  MENU_DETAIL: '/menu',
  /* NEWS */
  NEWS_MANAGEMENT: '/news-management',
  NEWS_DETAIL: '/news',
  NEWS_CATEGORY_MANAGEMENT: '/news-category-management',
  NEWS_CATEGORY_DETAIL: '/news-category',
  /* BANNER */
  BANNER_MANAGEMENT: '/banner-management',
  BANNER_DETAIL: '/banner',
  /* TRANSLATIONS */
  FE_TRANSLATION_MANAGEMENT: '/fe-translation',
  CMS_TRANSLATION_MANAGEMENT: '/cms-translation',
  /* REDIRECT */
  REDIRECT_MANAGEMENT: '/redirect',
  REDIRECT_IMPORT: '/redirect-import',
  /* ACTIVITY */
  ACTIVITY_MANAGEMENT: '/activity-logs',
  /* ROLES */
  ROLES_MANAGEMENT: '/roles',
  ROLES_DETAIL: '/role-detail',
  /* USERS */
  USERS_MANAGEMENT: '/users-management',
  USERS_DETAIL: '/user',
  /* FAQ */
  FAQ_MANAGEMENT: '/faq-management',
  FAQ_DETAIL: '/faq',
  FAQ_CATEGORY_MANAGEMENT: '/faq-category-management',
  FAQ_CATEGORY_DETAIL: '/faq-category',
  /* CONFIG */
  SYSTEM_MANAGEMENT: '/system',
  SYSTEM_CONFIG_MANAGEMENT: '/config-management',
  ERRORS_MANAGEMENT: '/errors-management',
  /* AUTH */
  LOGIN: '/login',
  /* CONTACT */
  CONTACT_MANAGEMENT: '/contact-management',
  CONTACT_PROBLEM_MANAGEMENT: '/contact-problem-management',
  CONTACT_PROBLEM_DETAIL: '/contact-problem-detail',
  /* EMAIL TEMPLATE */
  EMAIL_TEMPLATE_MANAGEMENT: '/email-template-management',
  EMAIL_TEMPLATE_DETAIL: '/email-template-detail',
  /* FORM */
  FORM_MANAGEMENT: '/form-management',
  FORM_DETAIL: '/form-detail',
  SUBMIT_FORM_MANAGEMENT: '/submit-form-management',
  SUBMIT_FORM_LIST_BY_FORM: '/submit-form-list-by-form',
  /* STATIC BLOCK */
  STATIC_BLOCK_MANAGEMENT: '/static-block-management',
  STATIC_BLOCK_DETAIL: '/static-block-detail',
  /* STUDENTS */
  STUDENTS_MANAGEMENT: '/students-management',
  STUDENTS_DETAIL: '/students-detail',
  /* SOLUTION SEARCH */
  SOLUTION_SEARCH_MANAGEMENT: '/solution-search-management',
  SOLUTION_SEARCH_DETAIL: '/solution-search-detail',
  /* USER_LEVEL */
  USER_LEVEL_MANAGEMENT: '/user-level',
  USER_LEVEL_DETAIL: '/user-level-detail',
  /* FEATURE_HISTORY */
  FEATURE_HISTORY_MANAGEMENT: '/feature-history-management',
  /* PRODUCTS */
  PRODUCTS_MANAGEMENT: '/products-management',
  PRODUCTS_DETAIL: '/products-detail',
  /* ORDERS */
  ORDERS_MANAGEMENT: '/orders-management',
  ORDERS_DETAIL: '/orders-detail',
  /* REDEEM */
  REDEEM_MANAGEMENT: '/redeem-management',
  REDEEM_DETAIL: '/redeem-detail',
  /* ON_BOARDING */
  ON_BOARDING_MANAGEMENT: '/on-boarding-management',
  ON_BOARDING_DETAIL: '/on-boarding-detail',
  /* USAGE HISTORIES */
  USAGE_HISTORIES_MANAGEMENT: '/usage-histories-management',
  USAGE_HISTORY_DETAIL: '/usage-history-detail',
  /* GRADE */
  GRADE_MANAGEMENT: '/grade-management',
  GRADE_DETAIL: '/grade-detail',
  /* FUNCTIONAL */
  FUNCTIONAL_MANAGEMENT: '/functional-management',
  FUNCTIONAL_DETAIL: '/functional-detail',
  /* STUDENTS ROLE */
  STUDENTS_ROLE_MANAGEMENT: '/students-role-management',
  STUDENTS_ROLE_DETAIL: '/students-role-detail',
  /* PROMOTION ROLE */
  PROMOTION_MANAGEMENT: '/promotion-management',
  PROMOTION_DETAIL: '/promotion-detail',
  /* Subcription */
  SUBSCRIPTION_MANAGEMENT: '/subscription-management',
  SUBSCRIPTION_TRANSACTION_HISTORY_MANAGEMENT: '/subscription-transaction-history-management',
  /* SUGGESTED COMMAND ROLE */
  SUGGESTED_COMMAND_MANAGEMENT: '/suggested-command-management',
  SUGGESTED_COMMAND_DETAIL: '/suggested-command-detail',
  /* AI EXERCIES ROLE */
  AI_EXERCIES_MANAGEMENT: '/ai-exercises-management',
  AI_EXERCIES_DETAIL: '/ai-exercises-detail',
};

export const videoTypes: OptionType[] = [
  {
    label: 'Upload',
    value: 'upload'
  },
  {
    label: 'Youtube',
    value: 'youtube'
  },
  {
    label: 'Vimeo',
    value: 'vimeo'
  },
];

export const defaultSessionsCode = {
  hanoiCode: '1028580',
  sontayCode: '9074107',
  saigonCode: '1028581',
  danangCode: '1028809',
};

export const userLevelOptions: OptionType[] = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10', value: 10 }
];

export const typeRedeemOptions: OptionType[] = [
  {
    label: 'redeem.singleUse',
    value: 1
  },
  {
    label: 'redeem.multiUses',
    value: 2
  }
];

export const userTypeOptions: OptionType[] = [
  {
    label: 'New',
    value: 1
  },
  {
    label: 'Current',
    value: 2
  },
  {
    label: 'Cancel/Expired',
    value: 3
  },
];

export const productTypeOptions: OptionType[] = [
  {
    label: 'OneTime',
    value: 1
  },
  {
    label: 'Subscription',
    value: 2
  },
];

export const promotionTypeOptions: OptionType[] = [
  {
    label: 'Discount',
    value: 1
  },
  {
    label: 'Buy X Get Y',
    value: 2
  },
];

export const discountTypeOptions: OptionType[] = [
  {
    label: 'Percent',
    value: 1
  },
  {
    label: 'Fixed Amount',
    value: 2
  },
];

export const durationTypeOptions: OptionType[] = [
  {
    label: 'Day',
    value: 1
  },
  {
    label: 'Month',
    value: 2
  },
];

export const codeTypeOptions: OptionType[] = [
  {
    label: 'One Time',
    value: 1
  },
  {
    label: 'Multi Time',
    value: 2
  },
];

export const dateDataOptions: OptionType[] = Array.from({ length: 31 }, (_, index) => ({
  label: (index + 1).toString(),
  value: index + 1,
}));

export const monthDataOptions: OptionType[] = Array.from({ length: 12 }, (_, index) => ({
  label: (index + 1).toString(),
  value: index + 1,
}));

export const funtionsOptions: OptionType[] = [
  {
    label: 'SolutionSearch',
    value: 'solutionSearch'
  },
  {
    label: 'HomeworkEvaluation',
    value: 'homeworkEvaluation'
  },
  {
    label: 'SimilarExercise',
    value: 'similarExercise'
  },
  {
    label: 'AiTutor',
    value: 'aiTutor'
  }
];

export const productDurations: OptionType[] = [
  {
    label: '7 days',
    value: 7
  },
  {
    label: '30 days',
    value: 30
  },
  {
    label: '90 days',
    value: 90
  },
  {
    label: '180 days',
    value: 180
  },
  {
    label: '365 days',
    value: 365
  }
];

export const productDurationsExtend: OptionType[] = [
  ...productDurations,
  {
    label: 'Lifetime',
    value: -1
  }
];

export const socialProviderOptions: OptionType[] = [
  {
    label: 'Facebook',
    value: 'facebook'
  },
  {
    label: 'Google',
    value: 'google'
  },
  {
    label: 'Twitter',
    value: 'twitter'
  },
  {
    label: 'Linkedin',
    value: 'linkedin'
  },
  {
    label: 'Microsoft',
    value: 'microsoft'
  },
  {
    label: 'Apple',
    value: 'apple'
  }
];

export const showOnDeviceOptions = [
  {
    label: EProductShowOnDevice[1],
    value: EProductShowOnDevice.Both
  },
  {
    label: EProductShowOnDevice[2],
    value: EProductShowOnDevice.App
  },
  {
    label: EProductShowOnDevice[3],
    value: EProductShowOnDevice.Web
  }
];

export const subscriptionStatusOptions: OptionType[] = [
  {
    label: 'Pending',
    value: 1
  },
  {
    label: 'Active',
    value: 2
  },
  {
    label: 'Expired',
    value: 3
  },
  {
    label: 'Cancelled',
    value: 4
  }
];

export const billingCycleOptions: OptionType[] = [
  {
    label: 'Weekly',
    value: 7
  },
  {
    label: 'Monthly',
    value: 30
  },
  {
    label: 'Quarterly',
    value: 90
  },
  {
    label: 'Half Year',
    value: 180
  },
  {
    label: 'Yearly',
    value: 365
  },
  {
    label: 'Lifetime',
    value: -1
  }
];

export const paymentPlatformOptions: OptionType[] = [
  {
    label: 'subscription.paypal',
    value: 1
  },
  {
    label: 'subscription.apple',
    value: 2
  },
  {
    label: 'subscription.google',
    value: 3
  },
  {
    label: 'subscription.momo',
    value: 4
  },
  {
    label: 'Activation Code',
    value: 5
  }
];

export const paymentStatusOptions: OptionType[] = [
  {
    label: 'subscription.canceled',
    value: 1
  },
  {
    label: 'subscription.failed',
    value: 2
  },
  {
    label: 'subscription.paid',
    value: 3
  },
  {
    label: 'subscription.suspended',
    value: 4
  }
];
