import { DollarOutlined } from '@ant-design/icons';
import {
  Button,
  Space,
  Tooltip,
  Typography
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import {
  FilterValueProps,
  mappingFilterToQuery, mappingParamsFilter, mappingQueryParamsFilter
} from 'common/components/PageTable/filter';
import useDidMount from 'common/hooks/useDidMount';
import { subscriptionsService } from 'common/services/extends/orders';
import { SubscriptionDataTypes } from 'common/services/extends/orders/types';
import {
  billingCycleOptions,
  paymentPlatformOptions,
  ROUTE_PATHS,
  subscriptionStatusOptions
} from 'common/utils/constant';
import { formatDateTime } from 'common/utils/functions';

export interface SubscriptionData extends SubscriptionDataTypes { }

const viewColumns = {
  id: 'id',
  studentName: 'studentName',
  studentEmail: 'studentEmail',
  productName: 'productName',
  subscriptionStatus: 'subscriptionStatus',
  startDate: 'startDate',
  endDate: 'endDate',
  recurringDate: 'recurringDate',
  billingCycle: 'billingCycle',
  paymentPlatform: 'paymentPlatform',
  action: 'action',
  paymentStatus: 'paymentStatus',
};

const SubscriptionManagement: React.FC<ActiveRoles> = () => {
  /* Hooks */
  const { t } = useTranslation();
  const navigation = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => {
    const paramsObj = { ...Object.fromEntries(searchParams.entries()) };
    if (searchParams.has('page')) delete paramsObj.page;
    return paramsObj;
  }, [searchParams]);

  const pageParam = searchParams.get('page');

  /* Selectors */
  const { system: { defaultPageSize } } = useAppSelector((state) => state);

  /* State */
  const [currentPage, setCurrentPage] = useState(Number(pageParam));
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [keyword, setKeyword] = useState('');
  const [selectedFilterList, setSelectedFilterList] = useState<
    FilterValueProps[]>(mappingQueryParamsFilter(params));

  /* Variables */
  const returnParamsFilter = useMemo(
    () => mappingParamsFilter(selectedFilterList),
    [selectedFilterList]
  );

  /* Queries */
  const { data: subscriptions, isLoading } = useQuery(
    ['getAllSubscriptions', keyword, currentPage, currentView, selectedFilterList],
    () => subscriptionsService({
      keyword,
      page: currentPage,
      limit: currentView,
      ...returnParamsFilter,
    }),
    {
      enabled: !!currentPage,
    }
  );

  const tableData: SubscriptionData[] = useMemo(() => subscriptions?.data || [], [subscriptions]);

  const handlePaymentStatus = (status?: number) => {
    switch (status) {
      case 1:
        return 'Cancelled';
      case 2:
        return 'Failed';
      case 3:
        return 'Success';
      case 4:
        return 'Pending';
      case 5:
        return 'Refunded';
      default:
        return '';
    }
  };

  const columns: ColumnsType<SubscriptionData> = [
    {
      title: 'ID',
      key: viewColumns.id,
      width: 80,
      align: 'center',
      fixed: 'left',
      render: (_name: string, data: SubscriptionData) => (
        <Typography.Text>
          {data.subscriptionData?.id || ''}
        </Typography.Text>
      ),
    },
    {
      title: t('system.name'),
      dataIndex: viewColumns.studentName,
      key: viewColumns.studentName,
      width: 300,
      render: (_name: string, data: SubscriptionData) => (
        <Typography.Text>
          {data.studentData?.name || ''}
        </Typography.Text>
      ),
    },
    {
      title: t('system.email'),
      dataIndex: viewColumns.studentEmail,
      key: viewColumns.studentEmail,
      width: 380,
      render: (_name: string, data: SubscriptionData) => (
        <Typography.Text>
          {data.studentData?.email || ''}
        </Typography.Text>
      ),
    },
    {
      title: t('subscription.productName'),
      dataIndex: viewColumns.productName,
      key: viewColumns.productName,
      width: 200,
      render: (_name: string, data: SubscriptionData) => (
        <Typography.Text>
          {data.subscriptionData?.productName || ''}
        </Typography.Text>
      ),
    },
    {
      title: t('subscription.subscriptionStatus'),
      width: 180,
      dataIndex: viewColumns.subscriptionStatus,
      key: viewColumns.subscriptionStatus,
      render: (_name: string, data: SubscriptionData) => (
        <Typography.Text>
          {t(subscriptionStatusOptions.find((item) => item.value === data.subscriptionData?.subscriptionStatus)?.label || '')}
        </Typography.Text>
      ),
    },
    {
      title: t('subscription.paymentStatus'),
      width: 180,
      dataIndex: viewColumns.paymentStatus,
      key: viewColumns.paymentStatus,
      render: (_name: string, data: SubscriptionData) => (
        <Typography.Text>
          {handlePaymentStatus(data.subscriptionData?.paymentStatus)}
        </Typography.Text>
      ),
    },
    {
      title: t('subscription.startDate'),
      dataIndex: viewColumns.startDate,
      key: viewColumns.startDate,
      render: (_name: string, data: SubscriptionData) => (
        <Typography.Text>
          {formatDateTime(data.subscriptionData?.startDate || '')}
        </Typography.Text>
      ),
    },
    {
      title: t('subscription.endDate'),
      dataIndex: viewColumns.endDate,
      key: viewColumns.endDate,
      render: (_name: string, data: SubscriptionData) => (
        <Typography.Text>
          {formatDateTime(data.subscriptionData?.endDate || '')}
        </Typography.Text>
      ),
    },
    {
      title: t('subscription.recurringDate'),
      dataIndex: viewColumns.recurringDate,
      key: viewColumns.recurringDate,
      width: 200,
      render: (_name: string, data: SubscriptionData) => (
        <Typography.Text>
          {formatDateTime(data.subscriptionData?.recurringDate || '')}
        </Typography.Text>
      ),
    },
    {
      title: t('subscription.billingCycle'),
      dataIndex: viewColumns.billingCycle,
      key: viewColumns.billingCycle,
      render: (_name: string, data: SubscriptionData) => (
        <Typography.Text>
          {t(billingCycleOptions.find((item) => item.value === data.subscriptionData?.billingCycle)?.label || '')}
        </Typography.Text>
      ),
    },
    {
      title: t('subscription.paymentPlatform'),
      dataIndex: viewColumns.paymentPlatform,
      key: viewColumns.paymentPlatform,
      render: (_name: string, data: SubscriptionData) => (
        <Typography.Text>
          {t(paymentPlatformOptions.find((item) => item.value === data.subscriptionData?.paymentPlatform)?.label || '')}
        </Typography.Text>
      ),
    },
    {
      title: t('system.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_name: string, data: SubscriptionData) => (
        <Typography.Text>
          {formatDateTime(data.subscriptionData?.createdAt || '')}
        </Typography.Text>
      ),
    },
    {
      title: t('system.updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: 200,
      render: (_name: string, data: SubscriptionData) => (
        <Typography.Text>
          {formatDateTime(data.subscriptionData?.updatedAt || '')}
        </Typography.Text>
      ),
    },
    {
      title: t('system.action'),
      key: 'action',
      width: 100,
      align: 'center',
      render: (_name: string, data: SubscriptionData) => (
        <Space>
          <Tooltip title="Transaction history">
            <Button
              icon={<DollarOutlined />}
              onClick={() => navigation(`${ROUTE_PATHS.SUBSCRIPTION_TRANSACTION_HISTORY_MANAGEMENT}?id=${data.subscriptionData?.id}&paymentMethod=${data.subscriptionData?.paymentPlatform || ''}`)}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  // Change page view
  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ page: page.toString() }, { replace: true });
  };

  // Change page size
  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  const handleDeleteFilter = (key: string, index?: number) => {
    const tempList = selectedFilterList.slice();
    setSelectedFilterList(tempList.filter((item) => !(item.key === key && item.index === index)));
  };

  // Add filter
  const handleFilter = (data: FilterValueProps) => {
    const typeFilter = String(data.filter).split('.')[1];
    if ((typeFilter === 'isNull' || typeFilter === 'isNotNull') && selectedFilterList.find((item) => item.key === data.key)) {
      return;
    }
    const counter = selectedFilterList.filter(
      (item) => item.field === data.field && item.filter === data.filter
    ).length;
    setSelectedFilterList([...selectedFilterList, { ...data, index: counter }]);
  };

  /* Effects */
  useEffect(() => {
    setCurrentPage(pageParam ? Number(pageParam) : 1);
    setSearchParams({
      ...mappingFilterToQuery(selectedFilterList),
      page: pageParam || '1'
    }, { replace: true });
  }, [keyword, setSearchParams, selectedFilterList]);

  useDidMount(() => {
    if (pageParam) return setCurrentPage(Number(pageParam));
    return setCurrentPage(Number(pageParam) || 1);
  });

  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.subscription')}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          isLoading={isLoading}
          handleSearch={setKeyword}
          tableProps={{
            initShowColumns: Object.values(viewColumns),
            columns,
            pageData: tableData || [],
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: subscriptions?.meta.total ?? 1,
            noBaseCol: true,
            noDeleteLanguage: true,
          }}
          filtersDataTable={{
            handleFilter,
            selectedFilterList,
            handleDeleteFilter
          }}
        />
      </div>
    </>
  );
};

export default SubscriptionManagement;
