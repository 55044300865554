export enum EProductShowOnDevice {
  Both = 1,
  App = 2,
  Web = 3
}
export interface ProductData {
  productData: ProductItemData;
  updaterData?: {
    email?: string,
    id: number,
    name: string,
  }
  translations: {
    [locale: string]: {
      id: string;
      name: string;
      description: {
        value: string;
      }[]
    }
  }
}

export interface ProductCurrenciesData {
  value: string;
  label: string;
}

export interface ProductCurrenciesRequest {
  platform: string;
}

export interface ProductItemData {
  id: number;
  nameUnique: string;
  productAppleId: string | null;
  productGoogleId: string | null;
  chPlayProductId: string | null;
  duration: number;
  userLevel?: {
    id: number,
    name: string,
    level: number,
  };
  displayOrder: number;
  contentColor: string;
  status: boolean;
  createdAt: string;
  updatedAt: string;
  price?: number;
  showOnDevice: EProductShowOnDevice
  productType: 1 | 2;
}

export interface ProductItemDetailData extends ProductItemData {
  translations: {
    [locale: string]: {
      name: string;
      description: string[];
    };
  };
}

export interface GetProductByIdParamsTypes {
  id: string;
}

export interface CreateProductParams {
  nameUnique: string;
  duration: number;
  userLevelId: number;
  displayOrder: number;
  contentColor: string;
  productAppleId?: string;
  productGoogleId?: string;
  chPlayProductId?: string;
  status: boolean;
  productType: number;
  showOnDevice: EProductShowOnDevice;
  translations: {
    [locale: string]: {
      productData: {
        name: string;
        description: string[];
      }
    };
  };
}

export interface UpdateProductParams extends CreateProductParams {
  id: string;
}

export interface PriceData {
  productPriceData: ProductPriceData;
}

export interface ProductPriceData {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
  price: number;
  priceVND: number;
  appPrice: number;
  appPriceVND: number;
  createdAt: string;
  updatedAt: string;
}

export interface CreatePriceParams {
  name: string;
  startDate: string;
  endDate: string;
  price: number;
  priceVND: number;
  appPrice: number;
  appPriceVND: number;
  countryData: CountryPrice[];
}

export interface CountryPrice {
  countryId: number;
  iosPrice: number;
  iosCurrency: string;
  androidPrice: number;
  androidCurrency: string;
}

export interface PriceDetailCountryData {
  id: number;
  name: string;
  currencyName: string;
  androidCurrency: string;
  androidPrice: number;
  currency: string;
  currencySymbol: string;
  iosCurrency: string;
  iosPrice: number;
}

export interface ProductPriceDetailsData {
  productPriceData: ProductPriceData;
  countryData: PriceDetailCountryData[];
}

export interface ProductStatus {
  status: boolean;
}
